import React, { useState, useMemo, useEffect } from "react";
import { Grid, makeStyles, Theme, useMediaQuery } from "@material-ui/core";

import NavigationTabs from "../NavigationTabs/NavigationTabs";
import { Role } from "../../models/static.model";
import { dataService } from "../../services/dataService";
import NavigationTabsforUser from "../NavigationTabs/NavigationTabsforUser";
import SearchTabs from "./SearchTabs";
import ObjectsPage from "../Objects/ObjectsPage";
import LegalEntityPage from "../LegalEntity/LegalEntityPage";
import PrivateEnterprisePage from "../PrivateEnterprise/PrivateEnterprisePage";
import StampsPage from "../Stamps/StampsPage";
import GlobalSearchPage from "../GlobalSearchPage/GlobalSearchPage";
import { linkToRules, linkToSearch } from "../Routes/var_PATHS";
import { userService } from "../../services/userService";

const useStyles = makeStyles((theme: Theme) => ({
  spinner: {
    paddingTop: 110,
  },
  user: {
    margin: "45px auto",
    width: "75%",
    minWidth: 375,
  },
}));

const SearchPage = () => {
  const matches890 = useMediaQuery("(min-width:890px)");

  const classes = useStyles();

  const myRole = useMemo(() => dataService.getRoleSync(), []);

  const isAdmin = myRole === Role.Admin;
  const isUser = myRole === Role.User;

  const [role, setRole] = useState({
    levelId: 0,
  });

  const handleChangeLevels = (level: any) => setRole({ levelId: level });

  useEffect(() => {
    if (isUser) {
      userService.getMe().then((dataUser: any) => {
        dataUser.termsAccepted ? linkToSearch() : linkToRules();
      });
    }
  }, []);

  return (
    <Grid
      container
      item
      xs={12}
      justify={"space-between"}
      direction={"row"}
      wrap="nowrap"
    >
      <Grid item style={{ display: matches890 ? "" : "none" }}>
        {isAdmin ? <NavigationTabs /> : <NavigationTabsforUser />}
      </Grid>

      <Grid item xs={12} className={classes.user}>
        <Grid container item xs={12} style={{ width: "100%" }}>
          <Grid container item xs={12}>
            <SearchTabs handleChangeLevels={handleChangeLevels} />
          </Grid>

          {role.levelId === 0 && <GlobalSearchPage />}
          {role.levelId === 1 && <LegalEntityPage />}
          {role.levelId === 2 && <PrivateEnterprisePage />}
          {role.levelId === 3 && <ObjectsPage />}
          {role.levelId === 4 && <StampsPage />}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SearchPage;
