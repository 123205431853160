import Logger from "../utils/logger";
import { config } from "../utils/config";
import { dataService } from "../services/dataService";
import { AuthToken, EmptyToken } from "../models/token.model";
import { resolveEvent } from "../models/event.model";
import { UnknownEvent } from "../models/error.model";
import { pubSubService } from "./pubSubService";
export class WebSocketService {
  private logger = new Logger(this.constructor.name);
  //todo: race condition can occure !!!
  private isConnected: boolean = false;

  private processEvent(message: any) {
    this.logger.debug("trying to resolve event: ", message);
    try {
      const e = resolveEvent(message);
      this.logger.debug("event resolved to: " + e.name);
      pubSubService.publishEvent(e);
    } catch (error) {
      if (error instanceof UnknownEvent) {
        this.logger.warn("unknown event:", message);
      } else {
        this.logger.error("resolve event error occured:", error);
      }
    }
  }

  private createWsConnection(sessionToken: AuthToken) {
    const baseUrl = config.beBaseUrl.replace("https", "wss");
    const wsPath = `/api/v1/push`;
    this.logger.debug("trying to connect to ws; path - " + wsPath);
    const wsUrl = baseUrl + wsPath;
    const ws = new WebSocket(wsUrl + "?token=" + sessionToken.token);

    ws.onopen = () => {
      this.logger.debug("connected to websocket ");
      this.isConnected = true;
    };

    ws.onmessage = (evt) => {
      if (evt.data == "STOP") {
        this.logger.debug("WS connection STOPPED.");
        this.isConnected = true;
        ws.close();
      } else {
        const message: any = JSON.parse(evt.data);
        this.logger.debug("ws message", message);
        this.processEvent(message);
      }
    };

    ws.onclose = (e) => {
      this.logger.error("ws closed by servrer", e);
      this.logger.debug("trying to reconnect to: " + wsUrl);
      this.isConnected = false;
      if (e.code === 1006) {
        this.connect();
      }
    };

    ws.onerror = (err) => {
      this.logger.error("Socket encountered error: ", err, "Closing socket");
      this.isConnected = false;
      ws.close();
    };
  }

  connect() {
    if (this.isConnected) {
      this.logger.debug(
        "ws connection is already created; skip conenection..."
      );
    } else {
      this.logger.debug(
        "ws connection is not created; creating new ws conenection..."
      );
      let sessionToken: AuthToken = dataService.getSessionTokenSync();
      if (sessionToken.tokenType instanceof EmptyToken) {
        this.logger.debug("token not found: skipping ws connection...");
      } else {
        this.logger.debug("token found: tyring to open ws coneection ...");
        this.createWsConnection(sessionToken);
      }
    }
  }
}

export const wsService = new WebSocketService();
