import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Button,
  makeStyles,
  TextField,
  Theme,
  Typography,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  DialogContentText,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Grow from "@material-ui/core/Grow/Grow";
import { objectService } from "../../services/objectService";
import CancelIcon from "@material-ui/icons/Cancel";
import SendIcon from "@material-ui/icons/Send";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    background: theme.palette.background.paper,
    // height: 300,
    width: "100%",
    margin: "15px auto",
    padding: 0,
  },
  backDrop: {
    backdropFilter: "blur(7px)",
    backgroundColor: `${theme.palette.grey[100]}30`,
  },
}));

const AddObject = (props: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    const a = Date.now();

    // console.log("aaaaaa", a);

    setNewObject((oldValue: any) => ({ ...oldValue, date: a }));
  }, []);

  const initialState = {
    name: "",
    subject: "",
    document: "",
    description: "",
    source: "",
    date: Date.now(),
  };

  const [newObject, setNewObject] = useState(initialState);

  const handleChangeAdress = (e: any) => {
    setNewObject((oldValue: any) => ({ ...oldValue, subject: e }));
  };
  const handleChangeFio = (e: any) => {
    setNewObject((oldValue: any) => ({ ...oldValue, name: e }));
  };
  const handleChangedoc = (e: any) => {
    setNewObject((oldValue: any) => ({ ...oldValue, document: e }));
  };
  const handleChangeDescription = (e: any) => {
    setNewObject((oldValue: any) => ({ ...oldValue, description: e }));
  };
  const handleChangeStatus = (e: any) => {
    setNewObject((oldValue: any) => ({ ...oldValue, source: e }));
  };
  const handleChangeDate = (e: any) => {
    setNewObject((oldValue: any) => ({ ...oldValue, date: e }));
  };

  const handleClickOpen = () => {
    setOpen(true);
    // console.log("open : ", open);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseWithClear = () => {
    setNewObject((oldValue: any) => ({ ...oldValue, ...initialState }));
    setOpen(false);
  };

  const sendNewObject = () => {
    // console.log("newObject", newObject);
    objectService
      .addNewAddress(newObject)
      .then(() => props.getUpdateObjects())
      .then(() => handleClose());
  };

  const dayForForm = (val: any) => {
    const date = new Date(val)
      .toLocaleString([], { year: "numeric", month: "2-digit", day: "2-digit" })
      .split("/")
      .reverse()
      .join("-");
    // console.log("today", date);
    return String(date);
  };

  return (
    <>
      <Grow in>
        <Grid>
          <IconButton
            onClick={handleClickOpen}
            color="primary"
            style={{ width: 80, height: 80 }}
          >
            <AddCircleIcon fontSize="large" style={{ width: 60, height: 60 }} />
          </IconButton>
        </Grid>
      </Grow>

      <Grid container item xs={12} justify="center">
        <Dialog
          onBackdropClick={handleClose}
          onEscapeKeyDown={handleCloseWithClear}
          onClose={handleClose}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
            timeout: 400,
          }}
          open={open}
          aria-labelledby="form-dialog-title"
        >
          {/* <DialogTitle id="form-dialog-title">{t("help.label")}</DialogTitle> */}
          <DialogTitle
            id="form-dialog-title"
            style={{ backgroundColor: theme.palette.grey[900] }}
          >
            {t("addresses.modalTitle")}
          </DialogTitle>
          <DialogContent
            dividers={true}
            style={{ backgroundColor: theme.palette.grey[900] }}
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            <DialogContentText>
              {t("addresses.addressHelper")}
            </DialogContentText>
            <Grid container item xs={12} style={{ width: "850px" }}>
              <TextField
                required
                error={newObject.subject.length < 2}
                label={t("addresses.address")}
                // helperText={t("addresses.addressHelper")}
                fullWidth
                margin="dense"
                variant="outlined"
                multiline
                rows={3}
                rowsMax="10"
                value={newObject.subject}
                onChange={(e: any) => handleChangeAdress(e.target.value)}

                // inputProps={{ disabled: true }}
              />
              <TextField
                label={t("addresses.pib")}
                fullWidth
                margin="dense"
                variant="outlined"
                multiline
                rows={1}
                rowsMax="3"
                value={newObject.name}
                onChange={(e: any) => handleChangeFio(e.target.value)}

                // inputProps={{ disabled: true }}
              />
              <TextField
                label={t("addresses.doc")}
                fullWidth
                margin="dense"
                variant="outlined"
                multiline
                rows={1}
                rowsMax="5"
                onChange={(e: any) => handleChangedoc(e.target.value)}

                // inputProps={{ disabled: true }}
              />
              <TextField
                required
                error={newObject.description.length < 2}
                label={t("addresses.description")}
                fullWidth
                margin="dense"
                variant="outlined"
                multiline
                rows={3}
                rowsMax="10"
                value={newObject.description}
                onChange={(e: any) => handleChangeDescription(e.target.value)}

                // inputProps={{ disabled: true }}
              />
              {/* <TextField
                required
                error={newObject.source.length < 2}
                label={t("addresses.subject")}
                fullWidth
                margin="dense"
                variant="outlined"
                multiline
                rows={1}
                rowsMax="3"
                value={newObject.source}
                onChange={(e: any) => handleChangeStatus(e.target.value)}

                // inputProps={{ disabled: true }}
              /> */}

              <FormControl
                style={{ width: "100%", margin: "10px 0px" }}
                variant="outlined"
                fullWidth
                id="subject-select"
                required
                error={newObject.source.length < 2}
              >
                <InputLabel
                  variant="outlined"
                  required
                  error={newObject.source.length < 2}
                  htmlFor="subject-select"
                  id="subject-select"
                >
                  {t("addresses.subject")}
                </InputLabel>
                <Select
                  labelWidth={190}
                  labelId={"subject-select"}
                  native
                  value={newObject.source}
                  onChange={(e: any) => handleChangeStatus(e.target.value)}
                  inputProps={{
                    name: "source",
                    id: "subject-select",
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={"ГТУЮ, МЮУ"}>{t("form.sourceSelect1")}</option>
                  <option value={"Заява власника"}>
                    {t("form.sourceSelect2")}
                  </option>
                  <option value={"Неофіційно"}>
                    {t("form.sourceSelect3")}
                  </option>
                  <option value={"Національна поліція"}>
                    {t("form.sourceSelect4")}
                  </option>
                  <option value={"Нотаріус"}>{t("form.sourceSelect5")}</option>
                </Select>
              </FormControl>

              {/* <FormControl
                style={{ width: "100%", marginTop: 10 }}
                fullWidth
                variant="outlined"
              >
                <InputLabel
                  variant="outlined"
                  id="demo-simple-select-outlined-label"
                >
                  {t("addresses.subject")}
                </InputLabel>
                <Select
                  variant="outlined"
                  native
                  value={newObject.source}
                  onChange={(e: any) => handleChangeStatus(e.target.value)}
                  inputProps={{
                    name: "source",
                    id: "subject-select",
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"Управління юстиції"}>
                    Управління юстиції
                  </MenuItem>
                  <MenuItem value={"Заява власника"}>
                    Заява власника
                  </MenuItem>
                  <MenuItem value={"Неофіційно"}>Неофіційно</MenuItem>
                </Select>
              </FormControl> */}

              <Grid container item xs={12} style={{ marginTop: 10 }}>
                <form
                  className={classes.container}
                  // noValidate
                  style={{ width: "100%" }}
                >
                  <TextField
                    fullWidth
                    required
                    error={newObject.date > 100 ? false : true}
                    style={{ width: "100%" }}
                    id="date"
                    type="date"
                    name="date"
                    label={t("addresses.date")}
                    variant="outlined"
                    rows="5"
                    // rowsMax="20"
                    // multiline
                    // defaultValue={today()}
                    value={dayForForm(newObject.date)}
                    onChange={(e: any) => {
                      const a = String(new Date(e.target.value));
                      const newDate = Date.parse(a);

                      handleChangeDate(newDate);
                    }}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </form>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ backgroundColor: theme.palette.grey[900] }}>
            <Grid container item xs={12} justify="space-between">
              <Grid>
                <Button
                  variant="contained"
                  onClick={handleCloseWithClear}
                  color="primary"
                  startIcon={<CancelIcon />}
                  style={{ minWidth: 180, margin: "5px 0px" }}
                >
                  <Typography>{t("btns.cancel")}</Typography>
                </Button>
              </Grid>
              <Grid>
                <Button
                  disabled={
                    newObject.subject.length < 2 ||
                    newObject.description.length < 2 ||
                    newObject.source.length < 2 ||
                    !Boolean(newObject.date)
                  }
                  variant="contained"
                  onClick={sendNewObject}
                  color="primary"
                  startIcon={<SendIcon />}
                  style={{ minWidth: 180, margin: "5px 0px" }}
                >
                  <Typography>{t("btns.send")}</Typography>
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

export default AddObject;
