import React, { useEffect, useState, useMemo } from "react";
import {
  makeStyles,
  Card,
  CardActionArea,
  Grid,
  Theme,
  Typography,
  withStyles,
  Chip,
  Collapse,
  CardContent,
  Tooltip,
} from "@material-ui/core";
import NewReleasesIcon from "@material-ui/icons/NewReleases";

import { useTranslation } from "react-i18next";

import Grow from "@material-ui/core/Grow/Grow";
import TextField from "@material-ui/core/TextField/TextField";
import { Role } from "../../models/static.model";
import { dataService } from "../../services/dataService";
import { objectService } from "../../services/objectService";
import RejectBtn from "../ButtonsWithConfirmation/RejectBtn";
import ApproveBtn from "../ButtonsWithConfirmation/ApproveBtn";
import CancelBtn from "../ButtonsWithConfirmation/CancelBtn";
import DeleteBtn from "../ButtonsWithConfirmation/DeleteBtn";
import EditBtn from "../ButtonsWithConfirmation/EditBtn";
import AcceptBtn from "../ButtonsWithConfirmation/AcceptBtn";
import LinearSpinner from "../spinners/Linear";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CircularSpinner from "../spinners/circular";

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[4],
    borderRadius: 20,
    fontSize: 15,
    padding: "15px",
  },
}))(Tooltip);

const LightTextInDisabledTextField = withStyles({
  root: {
    // marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#fefefe", // (default alpha is 0.38)
    },
    "& .MuiInputLabel-outlined.Mui-disabled": {
      color: "#fefefe90", // (default alpha is 0.38)
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    // flexGrow: 1,
    background: theme.palette.background.paper,
    // height: 300,
    width: "100%",
    marginTop: "15px",
    // padding: 10,
  },
  btnStylesWithOutline: {
    minHeight: 48,
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.paper,

    borderRadius: 25,
    boxSizing: "border-box",
    boxShadow: `0px 0px 2px ${theme.palette.primary.main}`,
    transition: "0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0px 0px 10px ${theme.palette.primary.main}`,
    },
  },
  // btnStylesWithOutlineCopiedAlert: {
  //   minHeight: 48,
  //   border: `2px solid ${theme.palette.primary.light}`,
  //   borderRadius: 25,
  //   backgroundColor: theme.palette.error.dark,

  //   boxSizing: "border-box",
  //   boxShadow: `0px 0px 2px ${theme.palette.primary.main}`,
  //   transition: "0.2s ease-in-out",
  //   "&:hover": {
  //     backgroundColor: theme.palette.error.main,
  //     boxShadow: `0px 0px 10px ${theme.palette.primary.main}`,
  //   },
  // },
  // backDrop: {
  //   backdropFilter: "blur(12px)",
  //   backgroundColor: `${theme.palette.grey[100]}30`,
  // },
}));

const StampItem = (props: any) => {
  const classes = useStyles();

  // const theme = useTheme();

  const { t } = useTranslation();

  const myRole = useMemo(() => dataService.getRoleSync(), []);

  const isAdmin = myRole === Role.Admin;

  const [stampData, setStampData] = useState({
    isDeleted: "",
    hashId: "",
    name: "",
    region: "",
    subject: "",
    description: "",
  });

  const [editedData, setEditedData] = useState({
    isDeleted: "",
    hashId: "",
    name: "",
    region: "",
    subject: "",
    description: "",
  });

  useEffect(() => {
    setStampData((oldVals: any) => ({
      ...oldVals,
      ...props.stampItem,
    }));
    setEditedData((oldVals: any) => ({
      ...oldVals,
      ...props.stampItem,
    }));
  }, []);

  const [isEdited, setIsEdited] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);

  const [isShowLinear, setIsShowLinear] = useState(false);

  const handleOpenEvent = (idItem: any) => {
    props.getOpenStamp(idItem);
    setIsLoaded(true);
  };

  const handleEditObject = () => {
    setIsEdited(!isEdited);
  };

  const confirmCanceledStamp = () => {
    setStampData(props.stampItem);
    setIsEdited(false);
  };
  const confirmEditStamp = () => {
    const editedStamp = {
      hashId: editedData.hashId,
      name: editedData.name,
      region: editedData.region,
      subject: editedData.subject,
      description: editedData.description,
    };
    objectService.editSignet(editedStamp).then(() => handleEditObject());
  };

  const handleDeleteStamp = (hash: any) => {
    objectService.deleteObjectById(hash).then((data: any) => {
      setStampData((oldVals: any) => ({
        ...oldVals,
        isDeleted: data.isDeleted,
      }));
      setEditedData((oldVals: any) => ({
        ...oldVals,
        isDeleted: data.isDeleted,
      }));
    });
  };

  const handleRejectObject = (hash: any) => {
    objectService.rejectObjectById(hash).then((data: any) => {
      setStampData((oldVals: any) => ({
        ...oldVals,
      }));
      setEditedData((oldVals: any) => ({
        ...oldVals,
      }));
    });
  };

  const handelApprove = (hash: any) => {
    objectService.approveObjectById(hash);
    // .then((data: any) => console.log("approveObjectById", data));
  };

  const handleChange = (name: any, values: any) => {
    if (name === "fio") {
      setStampData((oldVals: any) => ({
        ...oldVals,
        name: values,
      }));
      setEditedData((oldVals: any) => ({
        ...oldVals,
        name: values,
      }));
    }
    if (name === "subject") {
      setStampData((oldVals: any) => ({
        ...oldVals,
        subject: values,
      }));
      setEditedData((oldVals: any) => ({
        ...oldVals,
        subject: values,
      }));
    }
    if (name === "region") {
      setStampData((oldVals: any) => ({
        ...oldVals,
        region: values,
      }));
      setEditedData((oldVals: any) => ({
        ...oldVals,
        region: values,
      }));
    }
    if (name === "description") {
      setStampData((oldVals: any) => ({
        ...oldVals,
        description: values,
      }));
      setEditedData((oldVals: any) => ({
        ...oldVals,
        description: values,
      }));
    }
  };

  return (
    <Grow in>
      <>
        <Card className={classes.card}>
          <CardActionArea
            onClick={
              isEdited
                ? () => {}
                : () => handleOpenEvent(props.stampItem.hashId)
            }
            disableTouchRipple={isEdited}
            disableRipple={isEdited}
          >
            {isShowLinear && (
              <Grid
                container
                item
                xs={12}
                style={{ position: "absolute", bottom: 0 }}
              >
                <LinearSpinner />
              </Grid>
            )}

            <Grid
              container
              item
              xs={12}
              justify="space-between"
              style={{ padding: 10, width: "100%" }}
            >
              {props.stampItem.addressStatus.id === 0 && (
                <Grid
                  container
                  item
                  xs={12}
                  justify="center"
                  style={{ marginBottom: 10 }}
                >
                  <Chip
                    color="primary"
                    icon={<NewReleasesIcon />}
                    label={props.stampItem.addressStatus.name}
                  />
                </Grid>
              )}

              <Grid container item xs={12} style={{ width: "100%" }}>
                {!isEdited ? (
                  <Typography variant="h6">
                    {props.withType && (
                      <LightTooltip title={props.stampItem.addressType.name}>
                        <span
                          style={{
                            position: "relative",
                            top: 4,
                            paddingRight: 5,
                          }}
                        >
                          <CheckCircleIcon />
                        </span>
                      </LightTooltip>
                    )}
                    {stampData.name}
                  </Typography>
                ) : (
                  <LightTextInDisabledTextField
                    multiline
                    label={t("stamps.pib")}
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={stampData.name}
                    disabled={!isEdited}
                    name="fio"
                    onChange={
                      isEdited
                        ? (e: any) =>
                            handleChange(e.target.name, e.target.value)
                        : () => {
                            // console.log("FFFAAAALLLSSS");
                          }
                    }
                  />
                )}
              </Grid>
            </Grid>
          </CardActionArea>

          <Collapse
            in={
              props.isOpenStamp.id === props.stampItem.hashId && isLoaded
                ? props.isOpenStamp.isOpen
                : false
            }
            timeout="auto"
            unmountOnExit
            onExit={confirmCanceledStamp}
          >
            <CardContent>
              <Grid container item xs={12} justify="space-between">
                <Grid container item xs={4}>
                  <LightTextInDisabledTextField
                    multiline
                    label={t("stamps.position")}
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={stampData.subject}
                    disabled={!isEdited}
                    name="subject"
                    onChange={
                      isEdited
                        ? (e: any) =>
                            handleChange(e.target.name, e.target.value)
                        : () => {
                            // console.log("FFFAAAALLLSSS");
                          }
                    }
                  />
                </Grid>
                <Grid container item xs={3}>
                  <LightTextInDisabledTextField
                    multiline
                    label={t("stamps.region")}
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={stampData.region}
                    disabled={!isEdited}
                    name="region"
                    onChange={
                      isEdited
                        ? (e: any) =>
                            handleChange(e.target.name, e.target.value)
                        : () => {
                            // console.log("FFFAAAALLLSSS");
                          }
                    }
                  />
                </Grid>

                <Grid container item xs={4}>
                  <LightTextInDisabledTextField
                    multiline
                    label={t("stamps.description")}
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={stampData.description}
                    disabled={!isEdited}
                    name="description"
                    onChange={
                      isEdited
                        ? (e: any) =>
                            handleChange(e.target.name, e.target.value)
                        : () => {
                            // console.log("FFFAAAALLLSSS");
                          }
                    }
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} style={{ marginTop: 10 }}>
                {isAdmin ? (
                  <Grid container item xs={12} justify="space-evenly">
                    {isEdited ? (
                      <Grid container item xs={12} justify="space-evenly">
                        <Grid>
                          <CancelBtn confirmCanceled={confirmCanceledStamp} />
                        </Grid>

                        <Grid>
                          <AcceptBtn confirmEdit={confirmEditStamp} />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container item xs={12} justify="space-evenly">
                        <EditBtn handleEdit={handleEditObject} />

                        {props.stampItem.addressStatus.id === 0 && isAdmin && (
                          <ApproveBtn
                            handelApprove={handelApprove}
                            hashId={props.stampItem.hashId}
                          />
                        )}

                        {isAdmin &&
                          (props.stampItem.addressStatus.id === 0 ? (
                            <Grid>
                              <RejectBtn
                                handleReject={handleRejectObject}
                                hashId={props.stampItem.hashId}
                              />
                            </Grid>
                          ) : (
                            <DeleteBtn
                              handleDelete={handleDeleteStamp}
                              hashId={props.stampItem.hashId}
                            />
                          ))}
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  props.stampItem.addressStatus.id === 0 &&
                  (isEdited ? (
                    <Grid container item xs={12} justify="space-evenly">
                      <Grid>
                        <CancelBtn confirmCanceled={confirmCanceledStamp} />
                      </Grid>
                      <Grid>
                        <AcceptBtn confirmEdit={confirmEditStamp} />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container item xs={12} justify="space-evenly">
                      <EditBtn handleEdit={handleEditObject} />

                      <DeleteBtn
                        handleDelete={handleDeleteStamp}
                        hashId={props.stampItem.hashId}
                      />
                    </Grid>
                  ))
                )}
              </Grid>
            </CardContent>
          </Collapse>
        </Card>
        {"id" in props.stampItem && props.stampItem.id !== 0 && (
          <Grid
            container
            item
            xs={12}
            justify="flex-end"
            style={
              {
                // marginBottom: "5px ",
              }
            }
          >
            <Typography variant="caption">{props.stampItem.id}</Typography>
          </Grid>
        )}
      </>
    </Grow>
  );
};

export default StampItem;
