import Logger from "../utils/logger";
import { http } from "./httpService";
// import { Contact, UpdateContactRequest } from "../models/contact.models";
import {
  Licence,
  LicenceFilterRequest,
  NewLicenceRequest,
  UpdateLicenceRequest,
} from "../models/licence.model";
import { PagedResult } from "../models/profile.models";
import { config } from "../utils/config";

export class LicenceService {
  private logger = new Logger(this.constructor.name);

  addLicence(newLicenceRequest: NewLicenceRequest): Promise<Licence> {
    const path = `${config.beBaseUrl}/api/v1/licence/add`;
    this.logger.debug("add licence request:", newLicenceRequest);
    return http.post<Licence>(path, newLicenceRequest).then((resp: Licence) => {
      this.logger.debug("add licence response:", resp);
      return resp;
    });
  }

  searchLicence(filter: LicenceFilterRequest): Promise<PagedResult<Licence>> {
    const path = `${config.beBaseUrl}/api/v1/licence/search`;
    this.logger.debug("search licence request:", filter);
    return http
      .post<PagedResult<Licence>>(path, filter)
      .then((resp: PagedResult<Licence>) => {
        this.logger.debug("search licence response:", resp);
        return resp;
      });
  }

  editLicence(editLicenceRequest: UpdateLicenceRequest): Promise<Licence> {
    const path = `${config.beBaseUrl}/api/v1/licence`;
    this.logger.debug("edit licence request:", editLicenceRequest);
    return http.put<Licence>(path, editLicenceRequest).then((resp: Licence) => {
      this.logger.debug("edit licence response:", resp);
      return resp;
    });
  }

  getLicenceByNumber(number: number): Promise<Licence> {
    const path = `${config.beBaseUrl}/api/v1/licence/number/${number}`;
    this.logger.debug("get licence by number");
    return http.get<Licence>(path).then((resp: Licence) => {
      this.logger.debug("get licence by number response:", resp);
      return resp;
    });
  }

  getLicenceById(hashId: string): Promise<Licence> {
    const path = `${config.beBaseUrl}/api/v1/licence/${hashId}`;
    this.logger.debug("get licence by id");
    return http.get<Licence>(path).then((resp: Licence) => {
      this.logger.debug("get licence by id response:", resp);
      return resp;
    });
  }

  getLicenceByUserId(hashId: string): Promise<Licence> {
    const path = `${config.beBaseUrl}/api/v1/user/${hashId}/licence`;
    this.logger.debug("get licence by user-id");
    return http.get<Licence>(path).then((resp: Licence) => {
      this.logger.debug("get licence by user-id response:", resp);
      return resp;
    });
  }

  deleteLicenceById(hashId: string): Promise<Licence> {
    const path = `${config.beBaseUrl}/api/v1/licence/${hashId}`;
    this.logger.debug("delete licence by id");
    return http.delete<Licence>(path).then((resp: Licence) => {
      this.logger.debug("delete licence by id response:", resp);
      return resp;
    });
  }
}

export const licenceService = new LicenceService();
