import { LangBackEnd, RoleView } from "./static.model";
import { Theme } from "@material-ui/core";
import { RcmdRequest } from "./rcmd-request.model";
import { ServiceView } from "./object.model";
import { Licence } from "./licence.model";

export interface AuthProvider {
  id: number;
  name: string;
}

export interface UserStatus {
  id: number;
  name: string;
}

export interface TokensResponse {
  role: string;
  accessToken: string;
  refreshToken: string;
}

export interface User {
  id?: string;
  hashId?: string;
  authProvider?: AuthProvider;
  role: RoleView;
  firstName?: string;
  lastName?: string;
  email?: string;
  pictureUrl?: string;
  theme?: Theme;
  isDeleted?: boolean;
  status?: UserStatus;
  service?: ServiceView;
  licence?: Licence;
  lastSeenAt?: number;
  createdAt?: number;
  modifiedAt?: number;
  termsAccepted?: boolean;
}

export interface AccessLog {
  id?: string;
  hashId?: string;
  service?: ServiceView;
  authProvider?: AuthProvider;
  role: RoleView;
  firstName?: string;
  lastName?: string;
  email?: string;
  pictureUrl?: string;
  createdAt?: number;
  modifiedAt?: number;
}

export class AddUserRequest implements RcmdRequest {
  email: string;
  licenceId: string;

  constructor(email: string, licenceId: string) {
    this.email = email;
    this.licenceId = licenceId;
  }
}

export class HelpRequest implements RcmdRequest {
  message: string;

  constructor(message: string) {
    this.message = message;
  }
}

export class AccessLogFilter implements RcmdRequest {
  pageNumber?: number;
  pageSize?: number;
  searchTerm?: string;

  constructor(pageNumber?: number, pageSize?: number, searchTerm?: string) {
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.searchTerm = searchTerm;
  }
}

export class UserFilter implements RcmdRequest {
  pageNumber?: number;
  pageSize?: number;
  roleId?: number;
  authProviderId?: number;
  searchTerm?: string;
  isDeleted?: boolean;
  userStatusId?: number;

  constructor(
    pageNumber?: number,
    pageSize?: number,
    roleId?: number,
    authProviderId?: number,
    searchTerm?: string,
    isDeleted?: boolean,
    userStatusId?: number
  ) {
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.roleId = roleId;
    this.authProviderId = authProviderId;
    this.isDeleted = isDeleted;
    this.searchTerm = searchTerm;
    this.userStatusId = userStatusId;
  }
}

export class LangRequest implements RcmdRequest {
  langId: number;

  constructor(langId: number) {
    this.langId = langId;
  }
}

export class ThemeRequest implements RcmdRequest {
  themeId: number;

  constructor(themeId: number) {
    this.themeId = themeId;
  }
}
export class HelpResponse implements RcmdRequest {
  message: string;

  constructor(message: string) {
    this.message = message;
  }
}
