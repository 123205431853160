import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Grid, Avatar } from "@material-ui/core";
import defaultUserLogo from "../../img/user.jpg";

const useStyles = makeStyles({
  avatar: {
    // margin: 1,
    width: 35,
    height: 35,
  },
  avatarLarge: {
    marginBottom: 10,
    marginRight: 10,
    width: 60,
    height: 60,
  },
});

export default function ImageAvatars(props: any): any {
  const classes = useStyles();

  return (
    <Grid container justify="center" alignItems="center">
      {props.pictureUrl ? (
        <Avatar
          alt={`${props.firstName[0]} ${props.lastName[0]}`}
          src={props.pictureUrl ? props.pictureUrl : defaultUserLogo}
          className={
            props.size === "large" ? classes.avatarLarge : classes.avatar
          }
        />
      ) : (
        <Grid container justify="center">
          <CircularProgress size={35} thickness={4} color={"primary"} />
        </Grid>
      )}
    </Grid>
  );
}
