import { AxiosRequestConfig, Method, AxiosResponse } from "axios";
import Logger from "../utils/logger";
import { config } from "../utils/config";
import { dataService } from "./dataService";
import { AuthToken, EmptyToken } from "../models/token.model";
import {
  RcmdResponse,
  RcmdResponseBody,
  RcmdEmptyBody,
} from "../models/rcmd-response.model";
import { RcmdRequest } from "../models/rcmd-request.model";
import {
  ResponseValidationError,
  HttpTimeoutError,
  NpuError,
  InvalidRequestModelError,
} from "../models/error.model";
import { httpClient } from "./httpClient";
import { responseValidator } from "./responseValidator";
import { MAINTENANCE_PATH } from "../components/Routes/var_PATHS";
import history from "../utils/history";

class HttpService {
  private logger = new Logger(this.constructor.name);
  private authHeaderName = config.authHeader;
  private defaultHeaders: any = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  private performRequest<T extends RcmdResponseBody>(
    method: Method,
    path: string,
    body?: RcmdRequest
  ): Promise<T> {
    const req: AxiosRequestConfig = {
      method: method,
      url: path,
      data: body,
    } as AxiosRequestConfig;

    return dataService
      .getSessionToken()
      .then((token: AuthToken) => {
        if (token.tokenType instanceof EmptyToken) {
          return this.defaultHeaders;
        } else {
          this.defaultHeaders[this.authHeaderName] = token.token;
          return this.defaultHeaders;
        }
      })
      .then((headers: any) => {
        this.logger.debug("headers to send:", headers);
        return { ...req, headers };
      })
      .then((requst: AxiosRequestConfig) => {
        this.logger.debug("REQUEST:", requst);
        return httpClient(requst)
          .then((response: AxiosResponse<any>) => {
            this.logger.info(
              `RESPONSE (bare) for request ${requst.method}: ${requst.url} -`,
              response
            );

            if (response.status === 200) {
              const validatedResponse: RcmdResponse<T> = responseValidator.validate<T>(
                response.data
              );
              if (validatedResponse.header.responseCode === 0) {
                return validatedResponse.body!;
              } else {
                throw new ResponseValidationError(
                  "resp validation: header.responseCode must be '0' for OK(200) response"
                );
              }
            } else {
              throw new ResponseValidationError(
                "resp validation: header.responseCode must be '0' for OK(200) response"
              );
            }
          })
          .catch((error) => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              const errorBareResponse = error.response as AxiosResponse<T>;
              this.logger.warn(
                `ERROR RESPONSE (bare) for request ${requst.method}: ${requst.url} -`,
                errorBareResponse
              );

              const validatedErrorResponse: RcmdResponse<RcmdEmptyBody> = responseValidator.validate<RcmdEmptyBody>(
                errorBareResponse.data,
                true
              );

              this.logger.warn(
                "ERROR RESPONSE: http status code: ",
                errorBareResponse.status
              );
              this.logger.warn(
                "ERROR RESPONSE: rcmd error code: ",
                validatedErrorResponse.header.responseCode
              );
              this.logger.warn(
                "ERROR RESPONSE: rcmd error message: ",
                validatedErrorResponse.header.errorMessage!
              );
              this.logger.warn(
                "ERROR RESPONSE: detailed error: ",
                validatedErrorResponse.header.detailedError
              );

              const invalidRequestModelErrorCode = 4;
              if (
                validatedErrorResponse.header.responseCode ===
                invalidRequestModelErrorCode
              ) {
                throw new InvalidRequestModelError(
                  errorBareResponse.status,
                  validatedErrorResponse.header.responseCode,
                  validatedErrorResponse.header.errorMessage!
                );
              } else {
                throw new NpuError(
                  errorBareResponse.status,
                  validatedErrorResponse.header.responseCode,
                  validatedErrorResponse.header.errorMessage!
                );
              }
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              this.logger.error(`RESPONSE NOT RECEIVED:  timetout`);
              history.push(MAINTENANCE_PATH);
              throw new HttpTimeoutError("timeout occured");
              // return error;
            } else {
              // Something happened in setting up the request that triggered an Error
              this.logger.error(`something went wrong: ${error.message}`);
              throw new Error(`something went wrong: ${error.message}`);
            }
          });
      });
  }

  public post<T extends RcmdResponseBody>(
    path: string,
    body: RcmdRequest
  ): Promise<T> {
    return this.performRequest<T>("post", path, body);
  }

  public get<T extends RcmdResponseBody>(path: string): Promise<T> {
    return this.performRequest<T>("get", path);
  }

  public put<T extends RcmdResponseBody>(
    path: string,
    body: RcmdRequest
  ): Promise<T> {
    return this.performRequest<T>("put", path, body);
  }

  public delete<T extends RcmdResponseBody>(path: string): Promise<T> {
    return this.performRequest<T>("delete", path);
  }
}

export const http = new HttpService();
