import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import NavigationTabsforUser from "../NavigationTabs/NavigationTabsforUser";
import { Role } from "../../models/static.model";
import { dataService } from "../../services/dataService";
import ToTopBtn from "../ButtonsWithConfirmation/ToTopBtn";
import { userService } from "../../services/userService";
import CircularSpinner from "../spinners/circular";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: "#fff",
    "&$checked": {
      color: "#000",
    },
  },

  spinner: {
    paddingTop: 110,
  },
  // user: {
  //   margin: "30px auto",
  //   width: "70%",
  //   minWidth: 375,
  // },
  // admin: {
  //   width: "100%",
  // },
  btnStylesWithOutline: {
    minHeight: 48,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 25,
    boxSizing: "border-box",
    boxShadow: `0px 0px 2px ${theme.palette.primary.main}`,
    transition: "0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0px 0px 10px ${theme.palette.primary.main}`,
    },
  },
  subTitles: {
    paddingLeft: 30,
  },
  text: {
    fontSize: 16,
  },
  infScroll: {
    width: "100%",

    // position: "absolute",
    // height: "max-content !important",
    overflow: "visible !important",
  },

  user: {
    transitionDuration: "0.8s",
    transitionProperty: "transform",

    margin: "45px auto",
    width: "75%",
    minWidth: 375,
  },

  admin: {
    width: "75%",

    // margin: "20px auto",
  },
}));

const InstructionsForUse = () => {
  const matches890 = useMediaQuery("(min-width:890px)");
  const classes = useStyles();

  const body = (
    <Grid item xs={12} className={classes.user}>
      <Grid container item xs={12} justify="center" style={{ marginTop: 10 }}>
        <Typography variant="h4" align="center">
          Інструкція з користування
        </Typography>
      </Grid>

      <br />
      <hr />
      <br />
      <Grid container item xs={12} direction="column">
        <Grid container item xs={12} className={classes.subTitles}>
          <Typography variant="h5">Для пошуку:</Typography>
        </Grid>

        <br />

        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.text}>
            У вкладці «Пошук» обрати необхідну категорію (Юридичні особи,
            фізичні особи, адреси, печатки) та почати вводити найбільш характері
            ознаки, пошук відбудеться автоматично, щойно ви закінчите набирати
            текст.
          </Typography>
          <Typography variant="subtitle1" className={classes.text}>
            Наприклад, для пошуку адреси, варто починати пошук з назви вулиці.
            Пошуки варто робити як українською, так і російською мовою,
            перевіряти як нові назви вулиць, так і старі. База містить
            інформацію починаючи з 2016 року.
          </Typography>
        </Grid>

        <br />

        <Grid container item xs={12} className={classes.subTitles}>
          <Typography variant="h5">
            Для додавання об’єкта (суб’єкта):
          </Typography>
        </Grid>

        <br />

        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.text}>
            У разі виявлення вами підроблених документів, осіб, що намагаються
            ввести в оману, або якщо змінилась інформація щодо вказаного в
            таблиці об’єкту, просимо подавати заявку таким чином: у вкладці
            «Пошук» зайти в потрібну категорію (Юридичні особи, фізичні особи,
            адреси, печатки), натиснути «+», заповнити обов’язкові поля та
            коментар.
          </Typography>
        </Grid>

        <br />

        <Grid container item xs={12} className={classes.subTitles}>
          <Typography variant="h5">
            Для додавання нотаріуса для користування базою:
          </Typography>
        </Grid>
        <br />

        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.text}>
            Надіслати йому посилання на сайт.
          </Typography>
        </Grid>
        <br />
        <br />

        <Grid container item xs={12}>
          <Typography variant="subtitle1" style={{ fontSize: 18 }}>
            НЕ МОЖНА долучатися та надавати посилання на базу – помічникам,
            ріелторам та будь-яким іншим особам.
          </Typography>
        </Grid>

        <Grid container item xs={12}>
          <Typography
            variant="subtitle1"
            className={classes.text}
            style={{ fontSize: 18 }}
          >
            За однією адресою gmail може бути долучена лише одна особа
            (нотаріус).
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      item
      xs={12}
      justify={"space-between"}
      direction={"row"}
      wrap="nowrap"
      style={{
        transitionDuration: "0.8s",
        transitionProperty: "transform",
      }}
    >
      <Grid item style={{ display: matches890 ? "" : "none" }}>
        <NavigationTabsforUser />
      </Grid>

      <>
        <ToTopBtn />

        {body}
      </>
    </Grid>
  );
};

export default InstructionsForUse;
