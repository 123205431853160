import Logger from "../utils/logger";
import { http } from "./httpService";
import {
  NewAddressRequest,
  Object,
  NewSignetRequest,
  ObjectFilter,
  ObjectEventFilter,
  ObjectCopyPasteRequest,
  ObjectEvent,
  EditAddressRequest,
  EditSignetRequest,
  NewPrivateEntrepreneurRequest,
  NewLegalEntityRequest,
} from "../models/object.model";
import { PagedResult } from "../models/profile.models";
import { config } from "../utils/config";

export class ObjectService {
  private logger = new Logger(this.constructor.name);

  addNewAddress(request: NewAddressRequest): Promise<Object> {
    const path = `${config.beBaseUrl}/api/v1/object/address/add`;
    this.logger.debug(`add new address request: ${request}`);
    return http.post<Object>(path, request).then((resp: Object) => {
      this.logger.debug("add new address response:", resp);
      return resp;
    });
  }

  addNewSignet(request: NewSignetRequest): Promise<Object> {
    const path = `${config.beBaseUrl}/api/v1/object/signet/add`;
    this.logger.debug(`add new signet request: ${request}`);
    return http.post<Object>(path, request).then((resp: Object) => {
      this.logger.debug("add new signet response:", resp);
      return resp;
    });
  }

  addNewPrivateEntrepreneur(
    request: NewPrivateEntrepreneurRequest
  ): Promise<Object> {
    const path = `${config.beBaseUrl}/api/v1/object/pe/add`;
    this.logger.debug(`add new PrivateEntrepreneur request: ${request}`);
    return http.post<Object>(path, request).then((resp: Object) => {
      this.logger.debug("add new PrivateEntrepreneur response:", resp);
      return resp;
    });
  }

  addNewLegalEntity(request: NewLegalEntityRequest): Promise<Object> {
    const path = `${config.beBaseUrl}/api/v1/object/le/add`;
    this.logger.debug(`add new LegalEntity request: ${request}`);
    return http.post<Object>(path, request).then((resp: Object) => {
      this.logger.debug("add new LegalEntity response:", resp);
      return resp;
    });
  }

  searchObject(request: ObjectFilter): Promise<PagedResult<Object>> {
    const path = `${config.beBaseUrl}/api/v1/object/search`;
    this.logger.debug(`search object request: ${request}`);
    return http
      .post<PagedResult<Object>>(path, request)
      .then((resp: PagedResult<Object>) => {
        this.logger.debug("search object response:", resp);
        return resp;
      });
  }

  searchObjectEvent(
    request: ObjectEventFilter
  ): Promise<PagedResult<ObjectEvent>> {
    const path = `${config.beBaseUrl}/api/v1/object/event/search`;
    this.logger.debug(`search object-event request: ${request}`);
    return http
      .post<PagedResult<ObjectEvent>>(path, request)
      .then((resp: PagedResult<ObjectEvent>) => {
        this.logger.debug("search object-event response:", resp);
        return resp;
      });
  }

  addCopyPast(
    addressHashId: string,
    request: ObjectCopyPasteRequest
  ): Promise<Object> {
    const path = `${config.beBaseUrl}/api/v1/object/${addressHashId}/copy`;
    this.logger.debug(`add object-copy-past request: ${request}`);
    return http.post<Object>(path, request).then((resp: Object) => {
      this.logger.debug("add object-copy-past response:", resp);
      return resp;
    });
  }

  editAddress(request: EditAddressRequest): Promise<Object> {
    const path = `${config.beBaseUrl}/api/v1/object/address`;
    this.logger.debug(`edit address request: ${request}`);
    return http.put<Object>(path, request).then((resp: Object) => {
      this.logger.debug("edit address response:", resp);
      return resp;
    });
  }

  editSignet(request: EditSignetRequest): Promise<Object> {
    const path = `${config.beBaseUrl}/api/v1/object/signet`;
    this.logger.debug(`edit signet request: ${request}`);
    return http.put<Object>(path, request).then((resp: Object) => {
      this.logger.debug("edit signet response:", resp);
      return resp;
    });
  }

  editPrivateEntrepreneur(
    request: NewPrivateEntrepreneurRequest
  ): Promise<Object> {
    const path = `${config.beBaseUrl}/api/v1/object/pe`;
    this.logger.debug(`edit PrivateEntrepreneur request: ${request}`);
    return http.put<Object>(path, request).then((resp: Object) => {
      this.logger.debug("edit PrivateEntrepreneur response:", resp);
      return resp;
    });
  }

  editLegalEntity(request: NewLegalEntityRequest): Promise<Object> {
    const path = `${config.beBaseUrl}/api/v1/object/le`;
    this.logger.debug(`edit LegalEntity request: ${request}`);
    return http.put<Object>(path, request).then((resp: Object) => {
      this.logger.debug("edit LegalEntity response:", resp);
      return resp;
    });
  }

  getObjectById(hashId: string): Promise<Object> {
    const path = `${config.beBaseUrl}/api/v1/object/${hashId}`;
    this.logger.debug("get object by hash-id request sent");
    return http.get<Object>(path).then((resp: Object) => {
      this.logger.debug("get object be hash-id response:", resp);
      return resp;
    });
  }

  approveObjectById(hashId: string): Promise<Object> {
    const path = `${config.beBaseUrl}/api/v1/object/${hashId}/approve`;
    this.logger.debug("approve object by hash-id request sent");
    return http.get<Object>(path).then((resp: Object) => {
      this.logger.debug("approve object be hash-id response:", resp);
      return resp;
    });
  }

  rejectObjectById(hashId: string): Promise<Object> {
    const path = `${config.beBaseUrl}/api/v1/object/${hashId}/reject`;
    this.logger.debug("reject object by hash-id request sent");
    return http.get<Object>(path).then((resp: Object) => {
      this.logger.debug("reject object be hash-id response:", resp);
      return resp;
    });
  }

  deleteObjectById(hashId: string): Promise<Object> {
    const path = `${config.beBaseUrl}/api/v1/object/${hashId}`;
    this.logger.debug("delete object by hash-id request sent");
    return http.delete<Object>(path).then((resp: Object) => {
      this.logger.debug("delete object be hash-id response:", resp);
      return resp;
    });
  }
}

export const objectService = new ObjectService();
