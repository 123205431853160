import React, { useEffect, useState, useMemo } from "react";
import {
  makeStyles,
  Card,
  CardActionArea,
  Grid,
  Theme,
  Typography,
  withStyles,
  CardContent,
  Collapse,
  IconButton,
  Chip,
  useMediaQuery,
  Dialog,
  FormControl,
  InputLabel,
  Select,
  Button,
  DialogContent,
  Tooltip,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import Grow from "@material-ui/core/Grow/Grow";
import TextField from "@material-ui/core/TextField/TextField";
import { objectService } from "../../services/objectService";
import { Role } from "../../models/static.model";
import { dataService } from "../../services/dataService";
import CircularSpinner from "../spinners/circular";
import HistoryAddress from "../HistoryModal/HistoryAddress";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import DeleteBtn from "../ButtonsWithConfirmation/DeleteBtn";
import CloseIcon from "@material-ui/icons/Close";
import { withSnackbar, useSnackbar } from "notistack";
import RejectBtn from "../ButtonsWithConfirmation/RejectBtn";
import CancelBtn from "../ButtonsWithConfirmation/CancelBtn";
import AcceptBtn from "../ButtonsWithConfirmation/AcceptBtn";
import EditBtn from "../ButtonsWithConfirmation/EditBtn";
import ApproveBtn from "../ButtonsWithConfirmation/ApproveBtn";
import LinearSpinner from "../spinners/Linear";
import { linkToMain, linkToRules } from "../Routes/var_PATHS";
import HomeWorkIcon from "@material-ui/icons/HomeWork";

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[4],
    borderRadius: 20,
    fontSize: 15,
    padding: "15px",
  },
}))(Tooltip);

const LightTextInDisabledTextField = withStyles({
  root: {
    whiteSpace: "break-spaces",
    // marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#fefefe", // (default alpha is 0.38)
    },
    "& .MuiInputLabel-outlined.Mui-disabled": {
      color: "#fefefe90", // (default alpha is 0.38)
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    background: theme.palette.background.paper,
    // height: 300,
    width: "100%",
    margin: "15px 0 0 0",
    padding: 0,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  btnStylesWithOutline: {
    minHeight: 48,
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.paper,

    borderRadius: 25,
    boxSizing: "border-box",
    boxShadow: `0px 0px 2px ${theme.palette.primary.main}`,
    transition: "0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0px 0px 10px ${theme.palette.primary.main}`,
    },
  },
  btnStylesWithOutlineCopiedAlert: {
    minHeight: 48,
    border: `2px solid ${theme.palette.primary.light}`,
    borderRadius: 25,
    backgroundColor: theme.palette.error.dark,

    boxSizing: "border-box",
    boxShadow: `0px 0px 2px ${theme.palette.primary.main}`,
    transition: "0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.error.main,
      boxShadow: `0px 0px 10px ${theme.palette.primary.main}`,
    },
  },
  backDrop: {
    backdropFilter: "blur(12px)",
    backgroundColor: `${theme.palette.grey[100]}30`,
  },
}));

const ObjectItem = (props: any) => {
  // const {
  //   description,
  //   hashId,
  //   name,
  //   source,
  //   subject,
  //   modifiedAt,
  // } = props.objectItem;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const cancelAction = () => {
    return (
      <IconButton onClick={() => closeSnackbar()}>
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  };

  const [objectData, setObjectData] = useState({
    isDeleted: false,
    description: "",
    hashId: "",
    name: "",
    source: "",
    subject: "",
    modifiedAt: 0,
    document: "",
    date: Date.now(),
  });

  useEffect(() => {
    setObjectData((oldVals: any) => ({
      ...oldVals,
      ...props.objectItem,
    }));
    setEditedData((oldVals: any) => ({
      ...oldVals,
      ...props.objectItem,
    }));
  }, []);

  const classes = useStyles();

  // const theme = useTheme();

  const { t } = useTranslation();

  const matches960 = useMediaQuery("(min-width:960px)");

  const [isLoaded, setIsLoaded] = useState(false);

  const [isShowLinear, setIsShowLinear] = useState(false);

  const handleOpenObject = (idItem: any) => {
    props.getOpenObject(idItem);

    if (myRole === Role.User) {
      // if (idItem === objectData.hashId && props.isOpenObject.id !== idItem) {
      setIsShowLinear(true);
      objectService
        .getObjectById(objectData.hashId)
        .then((data: any) =>
          setObjectData((oldVals: any) => ({ ...oldVals, ...data }))
        )
        .then(() => setIsLoaded(true))
        .then(() => setIsShowLinear(false));
    }
    // } else {
    // }
    setIsLoaded(true);
  };

  const [openCopiedAlert, setOpenCopiedAlert] = React.useState(false);

  const handleOpenCopiedAlert = () => {
    setOpenCopiedAlert(true);
  };

  const handleCloseCopiedAlert = () => {
    setOpenCopiedAlert(false);
  };

  const handleApproveTermsAfterCopied = () => {
    linkToRules();
    handleCloseCopiedAlert();
  };

  const handlerCopy = (hash: string, e: any) => {
    e.preventDefault();

    objectService.getObjectById(hash);
    // .then((data: any) => console.log("getObjectById", data));

    if (myRole === Role.User) {
      const copiedData = e.target.value === undefined ? null : e.target.value;

      if (e.target.value === undefined) {
        objectService.addCopyPast(hash, { copiedData });
      } else {
        objectService.addCopyPast(hash, { copiedData });
      }

      handleOpenCopiedAlert();
    }
  };

  const myRole = useMemo(() => dataService.getRoleSync(), []);

  const isAdmin = myRole === Role.Admin;

  const [editedData, setEditedData] = useState({
    isDeleted: "",
    hashId: "",
    name: "",
    subject: "",
    document: "",
    date: Date.now(),
    description: "",
    source: "",
  });

  const [isEdited, setIsEdited] = useState(false);

  const handleEditObject = () => {
    setIsEdited(!isEdited);
    // const editedObject = {
    //   hashId: editedData.hashId,
    //   name: editedData.name,
    //   subject: editedData.subject,
    //   document: editedData.document,
    //   date: Date.now(),
    //   description: editedData.description,
    //   source: editedData.source,
    // };
    // objectService.editAddress(editedObject);
  };

  const handleChange = (name: any, values: any) => {
    if (name === "fio") {
      setObjectData((oldVals: any) => ({
        ...oldVals,
        name: values,
      }));
      setEditedData((oldVals: any) => ({
        ...oldVals,
        name: values,
      }));
    }
    if (name === "date") {
      setObjectData((oldVals: any) => ({
        ...oldVals,
        date: values,
      }));
      setEditedData((oldVals: any) => ({
        ...oldVals,
        date: values,
      }));
    }
    if (name === "doc") {
      setObjectData((oldVals: any) => ({
        ...oldVals,
        document: values,
      }));
      setEditedData((oldVals: any) => ({
        ...oldVals,
        document: values,
      }));
    }
    if (name === "subject") {
      setObjectData((oldVals: any) => ({
        ...oldVals,
        subject: values,
      }));
      setEditedData((oldVals: any) => ({
        ...oldVals,
        subject: values,
      }));
    }
    if (name === "descr") {
      setObjectData((oldVals: any) => ({
        ...oldVals,
        description: values,
      }));
      setEditedData((oldVals: any) => ({
        ...oldVals,
        description: values,
      }));
    }
    if (name === "source") {
      setObjectData((oldVals: any) => ({
        ...oldVals,
        source: values,
      }));
      setEditedData((oldVals: any) => ({
        ...oldVals,
        source: values,
      }));
    }
  };

  const handleChangeStatus = (e: any) => {
    // setNewObject((oldValue: any) => ({ ...oldValue, source: e }));

    setObjectData((oldVals: any) => ({
      ...oldVals,
      source: e,
    }));
    setEditedData((oldVals: any) => ({
      ...oldVals,
      source: e,
    }));
  };

  const confirmCanceledObject = () => {
    setObjectData((oldVals: any) => ({ ...oldVals, ...props.objectItem }));

    setIsEdited(false);
  };

  const confirmEditObject = () => {
    const editedObject = {
      hashId: editedData.hashId,
      name: editedData.name,
      subject: editedData.subject,
      document: editedData.document,
      date: editedData.date,
      description: editedData.description,
      source: editedData.source,
    };
    objectService.editAddress(editedObject).then(() => handleEditObject());
  };

  const handleDeleteObject = (hash: any) => {
    objectService
      .deleteObjectById(hash)
      .then((data: any) => {
        setObjectData((oldVals: any) => ({
          ...oldVals,
          isDeleted: data.isDeleted,
        }));
        setEditedData((oldVals: any) => ({
          ...oldVals,
          isDeleted: data.isDeleted,
        }));
      })
      .then(() => {
        if (isAdmin) {
          Promise.resolve(
            setObjectData((oldVals: any) => ({
              ...oldVals,
              ...props.objectItem,
            }))
          ).then(() => setIsLoaded(true));
        }
        if (myRole === Role.User) {
          Promise.resolve(
            setObjectData((oldVals: any) => ({
              ...oldVals,
              ...props.objectItem,
            }))
          );
          setIsLoaded(true);
        }
        setEditedData((oldVals: any) => ({ ...oldVals, ...props.objectItem }));
      })
      .catch((e: any) =>
        enqueueSnackbar(e.toString(), {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          variant: "error",
          action: cancelAction,
        })
      );
  };

  const handleRejectObject = (hash: any) => {
    objectService
      .rejectObjectById(hash)
      .then((data: any) => {
        setObjectData((oldVals: any) => ({
          ...oldVals,
          ...props.objectItem,
          ...data,
        }));
        setEditedData((oldVals: any) => ({
          ...oldVals,
          ...props.objectItem,
          ...data,
        }));
      })
      .then(() => confirmCanceledObject());
  };

  const defaultDate = () => {
    const a = objectData.date;
    const date = new Date(a)
      .toLocaleString([], { year: "numeric", month: "2-digit", day: "2-digit" })
      .split("/")
      .reverse()
      .join("-");
    // console.log("defaultDate", date);
    return String(date);
  };

  const handelApprove = (hash: any) => {
    objectService.approveObjectById(hash);
    // .then((data: any) => console.log("approveObjectById", data));
  };

  return (
    <Grow in>
      <>
        <Dialog
          disableEscapeKeyDown
          disableBackdropClick
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
            timeout: 400,
          }}
          open={openCopiedAlert}
          // fullScreen
          maxWidth={"lg"}
          onClose={handleCloseCopiedAlert}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          scroll={"paper"}
        >
          <DialogContent
            style={{
              backgroundColor: "red",
              // backdropFilter: "blur(12px)",
              // border: "1px solid black",

              borderRadius: 9,
            }}
          >
            <Grid
              container
              item
              xs={12}
              justify="center"
              style={{ cursor: "default" }}
            >
              <Grid container item xs={12} justify="center">
                <Typography variant="h4" align="center">
                  Правила користування базою проблемних суб’єктів та об’єктів
                </Typography>
              </Grid>

              <Grid
                container
                item
                direction="column"
                alignContent="center"
                alignItems="center"
                style={{ marginBottom: "20px" }}
              >
                <Typography
                  style={{ fontSize: 30, lineHeight: "10px" }}
                  align="center"
                >
                  ...
                </Typography>
                <Typography
                  style={{ fontSize: 30, lineHeight: "10px" }}
                  align="center"
                >
                  ...
                </Typography>
                <Typography
                  style={{ fontSize: 30, lineHeight: "10px" }}
                  align="center"
                >
                  ...
                </Typography>
              </Grid>

              <br />
              <Grid container item xs={12}>
                <Typography variant="h6">
                  5.3. Неодноразове копіювання даних Бази (більше 3 разів) є
                  підставою для автоматичного відключення доступу користувача.
                  Поновлення доступу лише через подання нової заяви.
                </Typography>
              </Grid>
            </Grid>

            <br />
            <Grid
              container
              item
              xs={12}
              justify="center"
              style={{ margin: "30px 0" }}
            >
              <Button
                className={classes.btnStylesWithOutlineCopiedAlert}
                style={{ minWidth: 300 }}
                onClick={handleApproveTermsAfterCopied}
              >
                <Typography style={{ fontWeight: 600 }}>
                  Прийняти умови використання
                </Typography>
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>

        <Card
          className={classes.card}
          onCopy={(e: any) => handlerCopy(props.objectItem.hashId, e)}
        >
          <CardActionArea
            onClick={() => handleOpenObject(props.objectItem.hashId)}
            disableTouchRipple={isEdited}
            disableRipple={isEdited}
          >
            {isShowLinear && (
              <Grid
                container
                item
                xs={12}
                style={{ position: "absolute", bottom: 0 }}
              >
                <LinearSpinner />
              </Grid>
            )}
            <Grid
              container
              item
              xs={12}
              justify="center"
              style={{ marginTop: 10 }}
            >
              {props.objectItem.addressStatus.id === 0 && (
                <Chip
                  color="primary"
                  icon={<NewReleasesIcon />}
                  label={props.objectItem.addressStatus.name}
                />
              )}
            </Grid>
            <Grid
              container
              item
              xs={12}
              style={{ padding: "10px" }}
              justify="space-between"
              alignItems="center"
              alignContent="center"
            >
              <Grid container item xs={12}>
                <Typography
                  variant="h6"
                  style={{
                    // whiteSpace: "nowrap" /* Отменяем перенос текста */,
                    textOverflow: "ellipsis",
                    overflow: "hidden" /* Обрезаем содержимое */,
                    // paddingRight: "10px",
                    maxHeight: matches960 ? 65 : 55,

                    whiteSpace: "pre-wrap",
                  }}
                >
                  {props.withType && (
                    <LightTooltip title={props.objectItem.addressType.name}>
                      <span
                        style={{
                          position: "relative",
                          top: 4,
                          paddingRight: 5,
                        }}
                      >
                        <HomeWorkIcon />
                      </span>
                    </LightTooltip>
                  )}

                  {objectData.subject}
                </Typography>
              </Grid>

              <Grid container item xs={12}>
                <Typography>{objectData.document}</Typography>
              </Grid>
            </Grid>
          </CardActionArea>

          <Collapse
            in={
              props.isOpenObject.id === objectData.hashId &&
              isLoaded &&
              !isShowLinear
                ? props.isOpenObject.isOpen
                : false
            }
            timeout="auto"
            // timeout={{
            //   appear: 500,
            //   enter: 500,
            //   exit: 500,
            // }}
            unmountOnExit
            onExit={confirmCanceledObject}
          >
            <CardContent>
              {isLoaded ? (
                <Grid item xs={12}>
                  <>
                    <Grid container item xs={12}>
                      <LightTextInDisabledTextField
                        fullWidth
                        disabled={!isEdited}
                        name="subject"
                        onChange={
                          isEdited
                            ? (e: any) =>
                                handleChange(e.target.name, e.target.value)
                            : () => {
                                // console.log("FFFAAAALLLSSS");
                              }
                        }
                        label={t("addresses.address")}
                        variant="outlined"
                        rows="1"
                        rowsMax="20"
                        multiline
                        style={{
                          width: "100%",
                          marginBottom: 15,
                          whiteSpace: "break-spaces",
                        }}
                        value={objectData.subject}
                      />
                    </Grid>

                    <Grid container item xs={12}>
                      <LightTextInDisabledTextField
                        fullWidth
                        disabled={!isEdited}
                        name="doc"
                        onChange={
                          isEdited
                            ? (e: any) =>
                                handleChange(e.target.name, e.target.value)
                            : () => {
                                // console.log("FFFAAAALLLSSS");
                              }
                        }
                        label={t("addresses.doc")}
                        variant="outlined"
                        rows="1"
                        rowsMax="10"
                        multiline
                        style={{ width: "100%", marginBottom: 15 }}
                        value={objectData.document}
                      />
                    </Grid>

                    <Grid container item xs={12}>
                      <LightTextInDisabledTextField
                        disabled={!isEdited}
                        name="descr"
                        onChange={
                          isEdited
                            ? (e: any) =>
                                handleChange(e.target.name, e.target.value)
                            : () => {
                                // console.log("FFFAAAALLLSSS");
                              }
                        }
                        label={t("addresses.description")}
                        variant="outlined"
                        rows="1"
                        rowsMax="20"
                        multiline
                        style={{ width: "100%", marginBottom: 15 }}
                        value={objectData.description}
                      />
                    </Grid>

                    <Grid
                      container
                      item
                      // onCopy={(e: any) => handlerCopy(props.objectItem.hashId, e)}
                      xs={12}
                      justify="space-between"
                      style={{ marginBottom: 20 }}
                    >
                      <Grid container item xs={12}>
                        <LightTextInDisabledTextField
                          disabled={!isEdited}
                          name="fio"
                          onChange={
                            isEdited
                              ? (e: any) =>
                                  handleChange(e.target.name, e.target.value)
                              : () => {
                                  // console.log("FFFAAAALLLSSS");
                                }
                          }
                          rows="1"
                          rowsMax="20"
                          multiline
                          label={t("addresses.pib")}
                          variant="outlined"
                          style={{ width: "100%", marginBottom: 15 }}
                          value={objectData.name}
                        />
                      </Grid>

                      <Grid container item xs={12}>
                        {isEdited ? (
                          <FormControl
                            style={{ width: "100%", marginBottom: 15 }}
                            variant="outlined"
                            fullWidth
                            id="subject-select"
                            required
                            error={objectData.source.length < 2}
                          >
                            <InputLabel
                              variant="outlined"
                              required
                              error={objectData.source.length < 2}
                              htmlFor="subject-select"
                              id="subject-select"
                            >
                              {t("addresses.subject")}
                            </InputLabel>
                            <Select
                              labelWidth={190}
                              labelId={"subject-select"}
                              native
                              value={objectData.source}
                              onChange={(e: any) =>
                                handleChangeStatus(e.target.value)
                              }
                              inputProps={{
                                name: "source",
                                id: "subject-select",
                              }}
                            >
                              <option aria-label="None" value="" />
                              <option value={"ГТУЮ, МЮУ"}>
                                {t("form.sourceSelect1")}
                              </option>
                              <option value={"Заява власника"}>
                                {t("form.sourceSelect2")}
                              </option>
                              <option value={"Неофіційно"}>
                                {t("form.sourceSelect3")}
                              </option>
                              <option value={"Національна поліція"}>
                                {t("form.sourceSelect4")}
                              </option>
                              <option value={"Нотаріус"}>
                                {t("form.sourceSelect5")}
                              </option>
                            </Select>
                          </FormControl>
                        ) : (
                          <LightTextInDisabledTextField
                            disabled={!isEdited}
                            name="source"
                            onChange={
                              isEdited
                                ? (e: any) =>
                                    handleChange(e.target.name, e.target.value)
                                : () => {
                                    // console.log("FFFAAAALLLSSS");
                                  }
                            }
                            rows="1"
                            rowsMax="20"
                            multiline
                            label={t("addresses.subject")}
                            variant="outlined"
                            style={{ width: "100%", marginBottom: 15 }}
                            value={objectData.source}
                          />
                        )}
                      </Grid>

                      <Grid container item xs={12}>
                        {isEdited ? (
                          <Grid container item xs={12}>
                            <form
                              className={classes.container}
                              noValidate
                              style={{ width: "100%" }}
                            >
                              <LightTextInDisabledTextField
                                fullWidth
                                style={{ width: "100%" }}
                                id="date"
                                type="date"
                                name="date"
                                label={t("addresses.date")}
                                variant="outlined"
                                rows="1"
                                rowsMax="20" // multiline
                                defaultValue={defaultDate()}
                                onChange={(e: any) => {
                                  const a = String(new Date(e.target.value));
                                  const newDate = Date.parse(a);

                                  handleChange(e.target.name, newDate);
                                }}
                                className={classes.textField}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </form>
                          </Grid>
                        ) : (
                          <LightTextInDisabledTextField
                            disabled={!isEdited}
                            id="date"
                            name="date"
                            label={t("addresses.date")}
                            // type="date"
                            onChange={
                              isEdited
                                ? (e: any) =>
                                    handleChange(e.target.name, e.target.value)
                                : () => {
                                    // console.log("FFFAAAALLLSSS");
                                  }
                            }
                            rows="1"
                            rowsMax="20"
                            multiline
                            variant="outlined"
                            style={{ width: "100%" }}
                            value={new Date(objectData.date).toLocaleString(
                              [],
                              {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                // hour: "2-digit",
                                // minute: "2-digit",
                              }
                            )}
                          />
                        )}
                      </Grid>
                    </Grid>
                    {isAdmin ? (
                      <Grid container item xs={12} justify="center">
                        {isEdited ? (
                          <Grid container item xs={12} justify="space-evenly">
                            <Grid>
                              <CancelBtn
                                confirmCanceled={confirmCanceledObject}
                              />
                            </Grid>

                            <Grid>
                              <AcceptBtn confirmEdit={confirmEditObject} />
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container item xs={12} justify="space-evenly">
                            <Grid>
                              <EditBtn handleEdit={handleEditObject} />
                            </Grid>

                            <Grid>
                              {props.objectItem.addressStatus.id === 0 &&
                              isAdmin ? (
                                <ApproveBtn
                                  handelApprove={handelApprove}
                                  hashId={props.objectItem.hashId}
                                />
                              ) : (
                                isAdmin && (
                                  <HistoryAddress
                                    name={"address"}
                                    id={props.objectItem.hashId}
                                  />
                                )
                              )}
                            </Grid>

                            <Grid>
                              {isAdmin &&
                                (props.objectItem.addressStatus.id === 0 ? (
                                  <RejectBtn
                                    handleReject={handleRejectObject}
                                    hashId={objectData.hashId}
                                  />
                                ) : (
                                  <DeleteBtn
                                    handleDelete={handleDeleteObject}
                                    hashId={objectData.hashId}
                                  />
                                ))}
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    ) : (
                      props.objectItem.addressStatus.id === 0 &&
                      (isEdited ? (
                        <Grid container item xs={12} justify="space-evenly">
                          <Grid>
                            <CancelBtn
                              confirmCanceled={confirmCanceledObject}
                            />
                          </Grid>

                          <Grid>
                            <AcceptBtn confirmEdit={confirmEditObject} />
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container item xs={12} justify="space-evenly">
                          <Grid>
                            <EditBtn handleEdit={handleEditObject} />
                          </Grid>

                          <DeleteBtn
                            handleDelete={handleDeleteObject}
                            hashId={objectData.hashId}
                          />
                        </Grid>
                      ))
                    )}
                  </>
                </Grid>
              ) : (
                <Grid
                  container
                  item
                  xs={12}
                  justify="center"
                  className={classes.spinner}
                >
                  <CircularSpinner />
                </Grid>
              )}
            </CardContent>
          </Collapse>
        </Card>
        {"id" in props.objectItem && (
          <Grid
            container
            item
            xs={12}
            justify="flex-end"
            style={
              {
                // marginBottom: "5px ",
              }
            }
          >
            <Typography variant="caption">{props.objectItem.id}</Typography>
          </Grid>
        )}
      </>
    </Grow>
  );
};

export default withSnackbar(ObjectItem);
