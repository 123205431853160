import React, { useState, useMemo } from "react";
import {
  makeStyles,
  Card,
  CardActionArea,
  Grid,
  Theme,
  Typography,
  // useTheme,
  // withStyles,
  Avatar,
  CardContent,
  Collapse,
} from "@material-ui/core";

// import { useTranslation } from "react-i18next";

import Grow from "@material-ui/core/Grow/Grow";
// import TextField from "@material-ui/core/TextField/TextField";
import InviteUser from "./InviteUser";
// import { Role } from "../../models/static.model";
import { dataService } from "../../services/dataService";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    background: theme.palette.background.paper,
    // height: 300,
    width: "100%",
    marginTop: "15px",
  },
}));

const UnauthorizedUserItem = (props: any) => {
  const classes = useStyles();

  // const theme = useTheme();

  // const { t } = useTranslation();

  const [openObject, setOpenObject] = useState({ hashId: "", isOpen: false });

  // const myRole = useMemo(() => dataService.getRoleSync(), []);

  // const isAdmin = myRole === Role.Admin;

  const handleOpenObject = (idItem: any) => {
    setTimeout(() => {
      setOpenObject({ hashId: idItem, isOpen: !openObject.isOpen });
    }, 150);
  };

  return (
    <Grow in>
      <>
        <Card className={classes.card}>
          <CardActionArea
            onClick={() => handleOpenObject(openObject.hashId)}
            // onCopy={(e: any) => handlerCopy(openObject.hashId, e)}
            // onBlur={() => {
            //   setOpenObject({ hashId: openObject.hashId, isOpen: false });
            // }}
          >
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              style={{ padding: 10, width: "100%" }}
            >
              <Grid item>
                <Grid
                  container
                  item
                  xs={12}
                  justify="flex-start"
                  alignContent="center"
                  alignItems="center"
                >
                  <Avatar
                    src={props.unauthorizedUser.pictureUrl}
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                style={{
                  marginLeft: 30,
                  // whiteSpace: "nowrap" /* Отменяем перенос текста */,
                  overflow: "hidden" /* Обрезаем содержимое */,
                  textOverflow: "ellipsis",
                  // paddingRight: "10px",
                  whiteSpace: "pre-wrap",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "pre-line",
                  }}
                >
                  {`${props.unauthorizedUser.firstName} ${props.unauthorizedUser.lastName}`}
                </Typography>
                <Typography
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "pre-line",
                  }}
                >
                  {props.unauthorizedUser.email}
                </Typography>
              </Grid>
            </Grid>
          </CardActionArea>
          <Collapse in={openObject.isOpen} timeout="auto" unmountOnExit>
            <CardContent>
              <Grid container item xs={12} justify="center">
                <InviteUser email={props.unauthorizedUser.email} />
              </Grid>
            </CardContent>
          </Collapse>
        </Card>
        <Grid container item xs={12} justify="flex-end">
          {"createdAt" in props.unauthorizedUser && (
            <Typography align="right" variant="caption">
              {`${new Date(props.unauthorizedUser.createdAt).toLocaleTimeString(
                [],
                {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour12: false,
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )}`}
            </Typography>
          )}
        </Grid>
      </>
    </Grow>
  );
};

export default UnauthorizedUserItem;
