import React, { useEffect, useMemo } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withSnackbar, useSnackbar } from "notistack";

import { cyan, amber } from "@material-ui/core/colors";
import CloseIcon from "@material-ui/icons/Close";
import {
  ThemeProvider,
  responsiveFontSizes,
  createMuiTheme,
} from "@material-ui/core/styles";
import {
  makeStyles,
  Theme,
  CssBaseline,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";

import {
  ERROR_PATH,
  AUTH_PATH,
  LOGIN_PATH,
  linkToLogin,
  MAINTENANCE_PATH,
  MAIN_PATH,
  LICENSES_PATH,
  USERS_PATH,
  OBJECTS_PATH,
  SETTINGS_PATH,
  STAMPS_PATH,
  UNAUTHORIZED_PATH,
  ENTITYS_PATH,
  REQUESTS_PATH,
  PRIVATE_ENTERPRISE_PATH,
  INSTRUCTION_PATH,
  SEARCH_PATH,
  RULES_PATH,
} from "./components/Routes/var_PATHS";

import { pubSubService } from "./services/pubSubService";
import { authService } from "./services/authService";
import { dataService } from "./services/dataService";
import { wsService } from "./services/wsService";

import { Role } from "./models/static.model";

import AuthComponent from "./components/AuthComponent/AuthComponent";
import PageNotFound from "./components/PageNotFound/PageNotFound";

import PrivateRoute from "./components/Routes/PrivateRoute";
import PublicRoute from "./components/Routes/PublicRoute";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import Header from "./components/Header/Header";
import Login from "./components/Login/Login";

import Maintenance from "./components/Maintenance/Maintenance";

import MainPage from "./components/Main/MainPage";
import LicensesPage from "./components/Licenses/LicensesPage";
import UsersPage from "./components/UsersPage/UsersPage";
import ObjectsPage from "./components/Objects/ObjectsPage";
import AccountPage from "./components/Account/AccountPage";
import StampsPage from "./components/Stamps/StampsPage";
import UnauthorizedPage from "./components/Unauthorized/UnauthorizedPage";
import { LogOutUserEvent } from "./models/event.model";
import LegalEntityPage from "./components/LegalEntity/LegalEntityPage";
import TermsAndConditions from "./components/TermsAndConditions/TermsAndConditions";
import RequestsPage from "./components/Requests/RequestsPage";
import PrivateEnterprisePage from "./components/PrivateEnterprise/PrivateEnterprisePage";
import SearchPage from "./components/SearchPage/SearchPage";
import InstructionsForUse from "./components/InstructionsForUse/InstructionsForUse";

export const LIGHT = "light";
export const DARK = "dark";
export const AUTO = "auto";
export const THEME_TYPE = "mod";

const useStyles = makeStyles((theme: Theme) => ({
  mainApp: {
    minWidth: 375,
    margin: "0 auto ",
  },
}));

const App: React.FC = () => {
  const classes = useStyles();
  const matches890 = useMediaQuery("(min-width:890px)");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const cancelAction = () => {
    return (
      <IconButton
        disableRipple
        disableFocusRipple
        onClick={ () => closeSnackbar() }
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  };

  const myRole = dataService.getRoleSync();

  const isLoggedIn = useMemo(() => authService.isLoggedInSync(), []);
  // const isLoggedIn = authService.isLoggedInSync();

  // const [themeName, setThemeName] = useState(DARK);

  let theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: DARK,
          primary: cyan,
          secondary: amber,
          // success: green,
          // info: blue,

          contrastThreshold: 3,

          text: {
            primary: "#fefefe",
            secondary: "#cfd8dc",
            disabled: "red",
            hint: "yellow",
          },
          background: {
            paper: "#424242",
            default: "#212121",
          },
          action: {
            hoverOpacity: 0.08,
            hover: "#6ec6ff50",
            selected: "#1AD0D035",
            disabledBackground: "#212121",
            disabled: "#616161",
            active: "#cfd8dc",
          },
        },
        typography: {
          fontFamily: ["Montserrat", "sans-serif"].join(","),
        },
      }),
    []
  );
  theme = responsiveFontSizes(theme);

  const handleLogout = () => {
    try {
      authService.logout().then(() => linkToLogin());
      // .then(() => pubSubService.unsubsribe(eventHandler, "auth-handler"));
    } catch (err) {
      enqueueSnackbar(err.toString(), {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        variant: "error",
        action: cancelAction,
      });
    }
  };

  const eventHandler = function (msg: any, data: any) {
    if (data instanceof LogOutUserEvent) {
      handleLogout();
    }
  };

  useEffect(() => {
    try {
      pubSubService.authSubscribe(eventHandler);
      // pubSubService.userSubscribe(eventHandlerTheme);
      wsService.connect();
    } catch (error) {
      enqueueSnackbar(error.toString(), {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        variant: "error",
        action: cancelAction,
      });
    }

    return () => {
      pubSubService.unsubsribe(eventHandler, "auth-handler");
      // pubSubService.unsubsribe(eventHandlerTheme, "auth-handlerTheme");
    };
    // eslint-disable-next-line
  }, [myRole]);

  return (
    <div>
      <ThemeProvider theme={ theme }>
        <CssBaseline />

        <Switch>
          <PublicRoute
            path={ AUTH_PATH }
            exact
            restricted={ true }
            render={ (hisroty) => (
              <AuthComponent location={ hisroty.location.search } />
            ) }
          />

          <PublicRoute
            path={ ERROR_PATH }
            exact
            restricted={ false }
            component={ ErrorPage }
          />

          <PublicRoute
            restricted={ true }
            path={ LOGIN_PATH }
            exact
            component={ Login }
          />

          <PublicRoute
            restricted={ false }
            path={ MAINTENANCE_PATH }
            exact
            component={ Maintenance }
          />

          { isLoggedIn ? (
            <>
              <div style={ { marginBottom: matches890 ? 30 : 40 } }>
                <Route component={ Header }></Route>
              </div>
              <main
                className={ classes.mainApp }
                style={ {
                  width: "95%",
                } }
              >
                { myRole === Role.Admin ? (
                  <Switch>
                    <PrivateRoute path={ MAIN_PATH } exact component={ MainPage } />

                    <PrivateRoute
                      path={ LICENSES_PATH }
                      exact
                      component={ LicensesPage }
                    />
                    {/* 
                    <PrivateRoute
                      path={`${LICENSES_PATH}/:id`}
                      exact
                      component={ProfilePage}
                    />
                    <PrivateRoute
                      path={`${USERS_PATH}/:id`}
                      exact
                      component={ProfilePage}
                    /> */}

                    <PrivateRoute
                      path={ USERS_PATH }
                      exact
                      component={ UsersPage }
                    />

                    <PrivateRoute
                      path={ SEARCH_PATH }
                      exact
                      component={ SearchPage }
                    />

                    {/* <PrivateRoute
                      path={ENTITYS_PATH}
                      exact
                      component={LegalEntityPage}
                    />

                    <PrivateRoute
                      path={PRIVATE_ENTERPRISE_PATH}
                      exact
                      component={PrivateEnterprisePage}
                    />

                    <PrivateRoute
                      path={OBJECTS_PATH}
                      exact
                      component={ObjectsPage}
                    />

                    <PrivateRoute
                      path={STAMPS_PATH}
                      exact
                      component={StampsPage}
                    /> */}

                    <PrivateRoute
                      path={ REQUESTS_PATH }
                      exact
                      component={ RequestsPage }
                    />

                    <PrivateRoute
                      path={ UNAUTHORIZED_PATH }
                      exact
                      component={ UnauthorizedPage }
                    />

                    <PrivateRoute
                      path={ SETTINGS_PATH }
                      exact
                      component={ AccountPage }
                    />

                    <PublicRoute
                      path="*"
                      restricted={ false }
                      component={ PageNotFound }
                    />
                  </Switch>
                ) : (
                  <Switch>
                    <PrivateRoute
                      path={ SEARCH_PATH }
                      exact
                      component={ SearchPage }
                    />

                    <PrivateRoute
                      path={ RULES_PATH }
                      exact
                      component={ TermsAndConditions }
                    />

                    <PrivateRoute
                      path={ INSTRUCTION_PATH }
                      exact
                      component={ InstructionsForUse }
                    />

                    {/* <PrivateRoute
                      path={ENTITYS_PATH}
                      exact
                      component={LegalEntityPage}
                    />

                    <PrivateRoute
                      path={PRIVATE_ENTERPRISE_PATH}
                      exact
                      component={PrivateEnterprisePage}
                    />

                    <PrivateRoute
                      path={OBJECTS_PATH}
                      exact
                      component={ObjectsPage}
                    />

                    <PrivateRoute
                      path={STAMPS_PATH}
                      exact
                      component={StampsPage}
                    /> */}

                    <PrivateRoute
                      path={ SETTINGS_PATH }
                      exact
                      component={ AccountPage }
                    />

                    <PublicRoute
                      path="*"
                      restricted={ false }
                      component={ PageNotFound }
                    />
                  </Switch>
                ) }
              </main>
            </>
          ) : (
            // <></>
            <Redirect to={ LOGIN_PATH } />
          ) }
        </Switch>
      </ThemeProvider>
    </div>
  );
};

export default withSnackbar(App);
