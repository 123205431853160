import { http } from "./httpService";
import Logger from "../utils/logger";
import { dataService } from "./dataService";
import {
  CaptchaRequest,
  LoginRequest,
  LoginResponse,
  RejectSignUpRequest,
  SignUp,
  SignUpRequest,
  SignUpRequestFilter,
} from "../models/login.model";
import { Role } from "../models/static.model";
import { RcmdResponseHeader } from "../models/rcmd-response.model";
import { AxiosResponse } from "axios";
import { RcmdEmptyBody } from "../models/rcmd-response.model";
import { config } from "../utils/config";
import { AuthToken, EmptyToken, JwtToken } from "../models/token.model";
import history from "../utils/history";
import { PagedResult } from "../models/profile.models";

class AuthService {
  private logger = new Logger(this.constructor.name);

  getLoginUrl(): Promise<LoginResponse> {
    const path = `${config.beBaseUrl}/api/v1/login`;
    const loginRequest: LoginRequest = new LoginRequest(
      config.feBaseUrl + config.loginSuccessPath,
      config.feBaseUrl + config.loginCancelPath,
      config.feBaseUrl + config.loginErrorPath,
      0,
      0
    );

    this.logger.debug(`get login url request ${path}`, loginRequest);
    return http
      .post<LoginResponse>(path, loginRequest)
      .then((resp: LoginResponse) => {
        this.logger.debug("get login url response:", resp);
        return resp;
      });
  }

  reCaptchaCheck(request: CaptchaRequest): Promise<string> {
    const path = `${config.beBaseUrl}/api/v1/captcha`;

    this.logger.debug(`Captcha request ${path}`, request);
    return http.post<string>(path, request).then((resp: string) => {
      this.logger.debug("Captcha response:", resp);
      return resp;
    });
  }

  signUp(request: SignUpRequest): Promise<SignUp> {
    const path = `${config.beBaseUrl}/api/v1/signup`;

    this.logger.debug(`signup request ${path}`, request);
    return http.post<SignUp>(path, request).then((resp: SignUp) => {
      this.logger.debug("signup response:", resp);
      return resp;
    });
  }

  searchSignUps(request: SignUpRequestFilter): Promise<PagedResult<SignUp>> {
    const path = `${config.beBaseUrl}/api/v1/user/signup/search`;

    this.logger.debug(`signup search request ${path}`, request);
    return http
      .post<PagedResult<SignUp>>(path, request)
      .then((resp: PagedResult<SignUp>) => {
        this.logger.debug("signup search response:", resp);
        return resp;
      });
  }

  acceptSignUp(hashId: string): Promise<SignUp> {
    const path = `${config.beBaseUrl}/api/v1/user/signup/${hashId}/accept`;
    this.logger.debug("accept signup request sent");
    return http.get<SignUp>(path).then((resp: SignUp) => {
      this.logger.debug("accept signup response:", resp);
      return resp;
    });
  }

  rejectSignUp(request: RejectSignUpRequest): Promise<SignUp> {
    const path = `${config.beBaseUrl}/api/v1/user/signup/reject`;
    this.logger.debug("reject signup request sent");
    return http.put<SignUp>(path, request).then((resp: SignUp) => {
      this.logger.debug("reject signup response:", resp);
      return resp;
    });
  }

  //local login
  login(
    role: string,
    sessionToken: JwtToken,
    refreshToken: JwtToken
  ): Promise<void> {
    return dataService.setSessionToken(sessionToken).then((_) => {
      return dataService.setRefreshToken(refreshToken).then((_) => {
        return dataService.setRole(role).then((_) => {
          this.logger.debug("logged in with role:", role);
          this.logger.debug("logged in with session-token:", sessionToken);
          this.logger.debug("logged in with refresh-token:", refreshToken);
        });
      });
    });
  }

  //local logout
  logout(): Promise<void> {
    return dataService.clear().then((_) => {
      this.logoutOnBackEnd();
      this.logger.debug("logged out");
    });
  }

  logoutOnBackEnd(): Promise<string> {
    const path = `${config.beBaseUrl}/api/v1/logout`;
    this.logger.debug("log out on back end side");
    return http.get<string>(path).then((resp: any) => {
      this.logger.debug("log out response:", resp);
      return "sign out";
    });
  }

  getRole(): Promise<Role> {
    return dataService.getRole().then((role) => {
      this.logger.debug("fetched role:", role);
      return role;
    });
  }

  hanldeExpiredToken(
    axiosResponse: AxiosResponse<RcmdEmptyBody>,
    rcmdHeader: RcmdResponseHeader,
    error: any
  ): Promise<any> {
    this.logger.debug("======");
    this.logger.debug("AuthService.hanldeExpiredToken()...");
    const responseStatus = axiosResponse.status;
    const npuErrorCode = rcmdHeader.responseCode;
    const previousRequestMathod = axiosResponse.config.method;
    const previousRequestUrl = axiosResponse.config.url;
    const previousReqeustBody = axiosResponse.config.data;
    // <HANDLE EXPIRED TOKEN HERE>
    this.logger.debug("npuErrorCode", npuErrorCode);
    this.logger.debug("responseStatus", responseStatus);
    this.logger.debug("previousRequestMathod", previousRequestMathod);
    this.logger.debug("previousRequestUrl", previousRequestUrl);
    this.logger.debug("previousReqeustBody", previousReqeustBody);
    const refreshUrl = `${config.beBaseUrl}/api/v1/login/refresh`;
    this.logger.debug("refreshUrl", refreshUrl);
    this.logger.debug("======");
    if (
      responseStatus === 401 &&
      npuErrorCode === 233 &&
      previousRequestUrl !== refreshUrl
    ) {
      this.logger.debug("access token has been expired - refresh tokens");
      return this.logout().then((_) => {
        // history.push("/login");
        window.location.replace(config.feBaseUrl + "/login");
        return Promise.reject(error);
      });
    } else if (
      responseStatus === 401 &&
      npuErrorCode === 233 &&
      previousRequestUrl === refreshUrl
    ) {
      this.logger.debug(
        "refresh token has been expired - redirect to login page"
      );
      return this.logout().then((_) => {
        window.location.replace(config.feBaseUrl + "/login");
        return Promise.reject(error);
      });
    } else if (responseStatus === 401 && npuErrorCode === 206) {
      this.logger.debug("access token is not valid - redirect to login page");
      return this.logout().then((_) => {
        // history.push("/login");
        window.location.replace(config.feBaseUrl + "/login");
        return Promise.reject(error);
      });
    } else {
      return Promise.reject(error);
    }
  }

  isLoggedInSync(): boolean {
    const token: AuthToken = dataService.getSessionTokenSync();
    const role: Role = dataService.getRoleSync();
    if (
      token.tokenType instanceof EmptyToken ||
      role === Role.Empty ||
      role === Role.Unknown
    ) {
      this.logger.debug("(sync) user is not logged in");
      return false;
    } else {
      this.logger.debug("(sync) user logged in with role:", role);
      return true;
    }
  }

  isLoggedIn(): Promise<boolean> {
    return dataService.getSessionToken().then((token: AuthToken) => {
      return dataService.getRole().then((role: Role) => {
        if (
          token.tokenType instanceof EmptyToken ||
          role === Role.Empty ||
          role === Role.Unknown
        ) {
          this.logger.debug("user is not logged in");
          return false;
        } else {
          this.logger.debug("user logged in with role:", role);
          return true;
        }
      });
    });
  }
}

export const authService = new AuthService();
