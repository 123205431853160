import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";

import SearchField from "../Search/SearchField";
// import AddLegalEntity from "./AddLegalEntity";

import { objectService } from "../../services/objectService";
import CircularSpinner from "../spinners/circular";
import { dataService } from "../../services/dataService";
import SearchBtn from "../Search/SearchBtn";
import { pubSubService } from "../../services/pubSubService";
import { ObjectUpdatedEvent } from "../../models/event.model";
import InfiniteScroll from "react-infinite-scroll-component";
import ToTopBtn from "../ButtonsWithConfirmation/ToTopBtn";
import SearchInfo from "../Search/SearchInfo";
import LegalEntityItem from "../LegalEntity/LegalEntityItem";
import StampItem from "../Stamps/StampItem";
import Object from "../Objects/ObjectItem";
import PrivateEnterpriseItem from "../PrivateEnterprise/PrivateEnterpriseItem";
import { debounce } from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  spinner: {
    paddingTop: 110,
  },
  // user: {
  //   margin: "30px auto",
  //   width: "70%",
  //   minWidth: 375,
  // },
  // admin: {},
  infScroll: {
    width: "100%",
    // position: "absolute",
    // height: "max-content !important",
    overflow: "visible !important",
  },

  user: {
    margin: "15px auto 45px",
    width: "75%",
    minWidth: 375,
  },
}));

const GlobalSearchPage = () => {
  const classes = useStyles();

  const [searchVal, setSearch] = useState({ field: "" });

  const [paginationData, setPaginationData] = useState({
    pageNumber: Number(),
    pageSize: Number(),
    totalCount: Number(),
  });

  const getSearch = (e: any) => {
    const searchText = e.trim();

    setSearch((searchVal: any) => ({
      ...searchVal,
      field: searchText,
    }));
    delayedQuery(searchText);
  };

  const delayedQuery = useCallback(
    debounce((text: any) => {
      onSearch(text);
    }, 300),
    []
  );

  const onSearch = (searchText: any) => {
    setIsLoaded(true);
    const filter = {
      pageNumber: 1,
      // pageSize: paginationData.pageSize || 30,
      // licence: 0,

      // searchTerm: "ба",
    };
    // console.log("searchTerm", searchTerm);

    objectService
      .searchObject({
        pageNumber: filter.pageNumber,
        // pageSize: filter.pageSize,

        statusId: 0,
        searchTerm: searchText,
      })
      .then((data: any) => {
        setObjectsNew(data.data);
        // console.log("objectService.searchObject", data);
      })
      .then(() => setIsLoadedNew(true))
      .then(() =>
        objectService
          .searchObject({
            pageNumber: filter.pageNumber,
            // pageSize: filter.pageSize,
            // typeId: 3,
            statusId: 1,
            searchTerm: searchText,
          })
          .then((data: any) => {
            setObjects(data.data);
            return data;
            // console.log("objectService.searchObject", data);
          })
          .then((data: any) =>
            setPaginationData((oldState: any) => ({
              ...oldState,
              pageNumber: filter.pageNumber + 1,
              totalCount: data.totalCount,
              pageSize: data.pageSize,
            }))
          )
          .then(() => setIsLoaded(false))
      );
  };

  const [objects, setObjects] = useState([
    {
      addressStatus: { id: 0, name: "" },
      addressType: { id: 0, name: "" },
      approvedBy: {
        authProvider: { id: 0, name: "" },
        createdAt: 0,
        email: "",
        firstName: "",
        hashId: "",
        id: 0,
        isDeleted: false,
        lastName: "",
        licence: {
          contacts: "",
          createdAt: 0,
          fio: "",
          hashId: "",
          licence: 0,
          modifiedAt: 0,
          name: "",
          region: { id: 0, name: "" },
          service: { id: 0, name: "" },
        },
        modifiedAt: 0,
        pictureUrl: "",
        role: { id: 0, name: "" },
        service: { id: 0, name: "" },
        status: { id: 1, name: "" },
        theme: { id: 1, name: "dark" },
      },
      createdAt: 0,
      createdBy: {
        authProvider: { id: 0, name: "google" },
        createdAt: 0,
        email: "",
        firstName: "",
        hashId: "",
        id: 0,
        isDeleted: false,
        lastName: "",
        licence: {
          contacts: "",
          createdAt: 0,
          fio: "",
          hashId: "",
          licence: 0,
          modifiedAt: 0,
          name: "f",
          region: { id: 0, name: "" },
          service: { id: 0, name: "" },
        },
        modifiedAt: 0,
        pictureUrl: "",
        role: { id: 1, name: "" },
        service: { id: 0, name: "Address" },
        status: { id: 1, name: "Registered" },
        theme: { id: 1, name: "dark" },
      },
      description: " ",
      hashId: "",
      isDeleted: false,
      modifiedAt: 0,
      name: "",
      source: "",
      subject: "",
    },
  ]);
  const [isLoaded, setIsLoaded] = useState(false);

  const [objectsNew, setObjectsNew] = useState([
    {
      addressStatus: { id: 0, name: "" },
      approvedBy: {
        authProvider: { id: 0, name: "" },
        createdAt: 0,
        email: "",
        firstName: "",
        hashId: "",
        id: 0,
        isDeleted: false,
        lastName: "",
        licence: {
          contacts: "",
          createdAt: 0,
          fio: "",
          hashId: "",
          licence: 0,
          modifiedAt: 0,
          name: "",
          region: { id: 0, name: "" },
          service: { id: 0, name: "" },
        },
        modifiedAt: 0,
        pictureUrl: "",
        role: { id: 0, name: "" },
        service: { id: 0, name: "" },
        status: { id: 1, name: "" },
        theme: { id: 1, name: "dark" },
      },
      createdAt: 0,
      createdBy: {
        authProvider: { id: 0, name: "google" },
        createdAt: 0,
        email: "",
        firstName: "",
        hashId: "",
        id: 0,
        isDeleted: false,
        lastName: "",
        licence: {
          contacts: "",
          createdAt: 0,
          fio: "",
          hashId: "",
          licence: 0,
          modifiedAt: 0,
          name: "f",
          region: { id: 0, name: "" },
          service: { id: 0, name: "" },
        },
        modifiedAt: 0,
        pictureUrl: "",
        role: { id: 1, name: "" },
        service: { id: 0, name: "Address" },
        status: { id: 1, name: "Registered" },
        theme: { id: 1, name: "dark" },
      },
      description: " ",
      hashId: "",
      isDeleted: false,
      modifiedAt: 0,
      name: "",
      source: "",
      subject: "",
    },
  ]);
  const [isLoadedNew, setIsLoadedNew] = useState(false);
  // const [visible, setVisible] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
    //   if ( paginationData.pageNumber > 3) {
    //     setVisible(true)
    //   }

    const filter = {
      pageNumber: paginationData.pageNumber || 1,
      pageSize: paginationData.pageSize || 30,
      // licence: 0,

      // searchTerm: "ба",
    };

    objectService
      .searchObject({
        pageNumber: filter.pageNumber,
        pageSize: filter.pageSize,
        // typeId: 3,
        statusId: 1,
        searchTerm: searchVal.field,
      })
      .then((data: any) => {
        setObjects(data.data);
        return data;
        // console.log("objectService.searchObject", data);
      })
      .then((data: any) =>
        setPaginationData((oldState: any) => ({
          ...oldState,
          pageNumber: filter.pageNumber + 1,
          totalCount: data.totalCount,
          pageSize: data.pageSize,
        }))
      )
      .then(() => setIsLoaded(false));
  }, []);

  useEffect(() => {
    pubSubService.objectSubscribe(eventHandler);

    objectService
      .searchObject({
        // typeId: 3,
        statusId: 0,
        searchTerm: searchVal.field,
      })
      .then((data: any) => {
        setObjectsNew(data.data);
        return data;
        // console.log("objectService.searchObject", data);
      })
      // .then((data: any) =>
      //   setPaginationData((oldState: any) => ({
      //     ...oldState,
      //     pageNumber: filter.pageNumber + 1,
      //     totalCount: data.totalCount,
      //     pageSize: data.pageSize,
      //   }))
      // )
      .then(() => setIsLoadedNew(true));

    return () => {
      pubSubService.unsubsribe(eventHandler, "Event-handler");
    };
  }, []);

  const myRole = useMemo(() => dataService.getRoleSync(), []);

  const [isOpenObject, setIsOpenObject] = useState({ id: "", isOpen: false });

  const getOpenObject = (hashId: string) => {
    if (hashId === isOpenObject.id) {
      setIsOpenObject({ id: hashId, isOpen: !isOpenObject.isOpen });
    } else {
      setIsOpenObject({ id: hashId, isOpen: true });
    }
  };

  const fetchMoreData = () => {
    if (paginationData.totalCount > objects.length) {
      const filter = {
        pageNumber: paginationData.pageNumber || 1,
        pageSize: paginationData.pageSize || 30,
        // licence: 0,

        searchTerm: searchVal.field,
      };

      objectService
        .searchObject({
          pageNumber: filter.pageNumber,
          pageSize: filter.pageSize,
          // typeId: 3,
          statusId: 1,
          searchTerm: searchVal.field,
        })
        .then((data: any) => {
          setObjects((oldObjects: any) => [...oldObjects].concat(data.data));

          return data;
          // console.log("objectService.searchObject", data);
        })
        .then((data: any) =>
          setPaginationData((oldState: any) => ({
            ...oldState,
            pageNumber: filter.pageNumber + 1,
            totalCount: data.totalCount,
            pageSize: data.pageSize,
          }))
        )
        .then(() => setIsLoaded(false));
    }
  };

  const eventHandler = function (msg: any, data: any) {
    if (data instanceof ObjectUpdatedEvent) {
      setIsLoaded(true);
      const filter = {
        pageNumber: paginationData.pageNumber || 1,
        pageSize: paginationData.pageSize || 30,
        // licence: 0,

        // searchTerm: "ба",
      };

      objectService
        .searchObject({
          // pageNumber: filter.pageNumber,
          // pageSize: filter.pageSize,
          // typeId: 3,
          statusId: 0,
          // searchTerm: searchVal.field,
        })
        .then((data: any) => setObjectsNew(data.data));

      objectService
        .searchObject({
          pageNumber: filter.pageNumber,
          pageSize: filter.pageSize,
          // typeId: 3,
          statusId: 1,
          searchTerm: searchVal.field,
        })
        .then((data: any) => {
          setObjects(data.data);
          return data;
          // console.log("objectService.searchObject", data);
        })
        .then((data: any) =>
          setPaginationData((oldState: any) => ({
            ...oldState,
            pageNumber: filter.pageNumber + 1,
            totalCount: data.totalCount,
            pageSize: data.pageSize,
          }))
        )
        .then(() => setIsLoaded(false));
    }
  };

  // const isAdmin = myRole === Role.Admin;

  return (
    <Grid
      container
      item
      xs={12}
      justify={"space-between"}
      // justify={isAdmin ? "space-between" : "flex-start"}
      direction={"row"}
      wrap="nowrap"
    >
      {/* <Grid item style={{ display: matches890 ? "" : "none" }}>
        {isAdmin ? <NavigationTabs /> : <NavigationTabsforUser />}
      </Grid> */}

      <Grid item xs={12} className={classes.user}>
        <Grid container item xs={12} wrap="nowrap">
          <Grid item style={{ width: "100%" }}>
            <SearchField
              name={"global"}
              getSearch={getSearch}
              // onSearch={() => onSearch(searchVal.field)}
            />
          </Grid>
          {/* <Grid item>
              <SearchBtn onSearch={() => onSearch(searchVal.field)} />
            </Grid> */}

          {/* <Grid item>
              <AddLegalEntity getUpdateObjects={getUpdateObjects} />
            </Grid> */}
        </Grid>
        <Grid container item xs={12}>
          <SearchInfo paginationData={paginationData} isLoading={!isLoaded} />
        </Grid>

        {isLoadedNew && (
          <Grid container item xs={12}>
            {objectsNew
              // .sort((a: any, b: any) => a.addressType.id - b.addressType.id)
              .map((item: any) => {
                item = { ...item, isOpen: false };
                return (
                  <Grid key={item.hashId} container item xs={12}>
                    {item.addressType.id === 3 && (
                      <LegalEntityItem
                        withType={true}
                        objectItem={item}
                        isOpenObject={isOpenObject}
                        getOpenObject={getOpenObject}
                      />
                    )}

                    {item.addressType.id === 1 && (
                      <StampItem
                        withType={true}
                        stampItem={item}
                        isOpenStamp={isOpenObject}
                        getOpenStamp={getOpenObject}
                      />
                    )}

                    {item.addressType.id === 0 && (
                      <Object
                        withType={true}
                        objectItem={item}
                        isOpenObject={isOpenObject}
                        getOpenObject={getOpenObject}
                      />
                    )}

                    {item.addressType.id === 2 && (
                      <PrivateEnterpriseItem
                        withType={true}
                        objectItem={item}
                        isOpenObject={isOpenObject}
                        getOpenObject={getOpenObject}
                      />
                    )}
                  </Grid>
                );
              })}
          </Grid>
        )}

        <Grid container item xs={12}>
          <div style={{ width: "100%" }}>
            <InfiniteScroll
              dataLength={objects.length}
              next={fetchMoreData}
              hasMore={
                objects.length < paginationData.totalCount ? true : false
              }
              loader={
                <Grid
                  container
                  item
                  xs={12}
                  justify="center"
                  style={{ marginTop: 50 }}
                >
                  <CircularSpinner forFetch={true} />
                </Grid>
              }
              scrollThreshold="300px"
              className={classes.infScroll}
              endMessage={
                objects.length === paginationData.totalCount && (
                  <p style={{ textAlign: "center" }}>
                    <span style={{ fontSize: 40 }}> 🤷‍♂️ </span>
                  </p>
                )
              }
            >
              {objects
                // .sort((a: any, b: any) => a.addressType.id - b.addressType.id)
                .map((item: any) => {
                  item = { ...item, isOpen: false };
                  return (
                    <Grid key={item.hashId} container item xs={12}>
                      {item.hashId ? (
                        <>
                          {item.addressType.id === 3 && (
                            <>
                              {/* <Grid
                              container
                              item
                              xs={12}
                              direction="row"
                              justify="center"
                              alignContent="center"
                              alignItems="center"
                              style={{ marginTop: 10 }}
                            >
                              <BusinessIcon />

                              <span style={{ margin: "0px 10px " }}>
                                {" - "}
                              </span>

                              <Typography>{item.addressType.name}</Typography>
                            </Grid> */}

                              <LegalEntityItem
                                withType={true}
                                objectItem={item}
                                isOpenObject={isOpenObject}
                                getOpenObject={getOpenObject}
                              />
                            </>
                          )}

                          {item.addressType.id === 1 && (
                            <>
                              {/* <Grid
                              container
                              item
                              xs={12}
                              direction="row"
                              justify="center"
                              alignContent="center"
                              alignItems="center"
                              style={{ marginTop: 10 }}
                            >
                              <CheckCircleIcon />

                              <span style={{ margin: "0px 10px " }}>
                                {" - "}
                              </span>

                              <Typography>{item.addressType.name}</Typography>
                            </Grid> */}

                              <StampItem
                                withType={true}
                                stampItem={item}
                                isOpenStamp={isOpenObject}
                                getOpenStamp={getOpenObject}
                              />
                            </>
                          )}

                          {item.addressType.id === 0 && (
                            <>
                              {/* <Grid
                              container
                              item
                              xs={12}
                              direction="row"
                              justify="center"
                              alignContent="center"
                              alignItems="center"
                              style={{ marginTop: 10 }}
                            >
                              <HomeWorkIcon />

                              <span style={{ margin: "0px 10px " }}>
                                {" - "}
                              </span>

                              <Typography>{item.addressType.name}</Typography>
                            </Grid> */}

                              <Object
                                withType={true}
                                objectItem={item}
                                isOpenObject={isOpenObject}
                                getOpenObject={getOpenObject}
                              />
                            </>
                          )}

                          {item.addressType.id === 2 && (
                            <>
                              {/* <Grid
                              container
                              item
                              xs={12}
                              direction="row"
                              justify="center"
                              alignContent="center"
                              alignItems="center"
                              style={{ marginTop: 10 }}
                            >
                              <PersonIcon />
                              <span style={{ margin: "0px 10px " }}>
                                {" - "}
                              </span>

                              <Typography>{item.addressType.name}</Typography>
                            </Grid> */}

                              <PrivateEnterpriseItem
                                withType={true}
                                objectItem={item}
                                isOpenObject={isOpenObject}
                                getOpenObject={getOpenObject}
                              />
                            </>
                          )}
                        </>
                      ) : (
                        <Grid
                          container
                          item
                          xs={12}
                          justify="center"
                          className={classes.spinner}
                        >
                          <CircularSpinner />
                        </Grid>
                      )}
                    </Grid>
                  );
                })}
            </InfiniteScroll>
          </div>
        </Grid>

        <ToTopBtn paginationData={paginationData} arrLength={objects.length} />
      </Grid>
    </Grid>
  );
};

export default GlobalSearchPage;
