import React from "react";
import { Redirect } from "react-router-dom";

import { MAIN_PATH, SETTINGS_PATH } from "../Routes/var_PATHS";

const PageNotFound: React.FC = () => {
  return (
    <Redirect to={SETTINGS_PATH} />
    // <>
    // <div>
    //   <span>
    //     ERROR!!!!
    //   </span>
    // </div>
    // </>
  );
};

export default PageNotFound;
