import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  makeStyles,
  Theme,
  Typography,
  useTheme,
  IconButton,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Grow from "@material-ui/core/Grow/Grow";
import { objectService } from "../../services/objectService";
import EventItem from "../Main/EventItem";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar, withSnackbar } from "notistack";
import CircularSpinner from "../spinners/circular";
import PersonIcon from "@material-ui/icons/Person";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import BusinessIcon from "@material-ui/icons/Business";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    background: theme.palette.background.paper,
    // height: 300,
    width: "100%",
    margin: "15px auto",
    padding: 0,
  },
  btnStylesWithOutline: {
    backgroundColor: theme.palette.background.paper,
    minHeight: 48,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 25,
    boxSizing: "border-box",
    boxShadow: `0px 0px 2px ${theme.palette.primary.main}`,
    transition: "0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0px 0px 10px ${theme.palette.primary.main}`,
    },
  },

  backDrop: {
    backdropFilter: "blur(7px)",
    backgroundColor: `${theme.palette.grey[100]}30`,
  },
  infScroll: {
    width: "100%",
    // position: "absolute",
    // height: "max-content !important",
    overflow: "visible !important",
  },
}));

const HistoryAddress = (props: any) => {
  const theme = useTheme();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const cancelAction = () => {
    return (
      <IconButton onClick={() => closeSnackbar()}>
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  };
  const classes = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    // console.log("open : ", open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [events, setEvents] = useState([
    {
      address: {
        addressStatus: { id: 0, name: "" },
        addressType: { id: 0, name: "" },
        createdAt: 0,
        date: 0,
        description: "",
        document: "",
        hashId: "",
        isDeleted: false,
        modifiedAt: 0,
        name: " ",
        source: "",
        subject: "",
      },
      createdAt: 0,
      eventType: { id: 0, name: "" },
      hashId: "",
      user: {
        authProvider: { id: 1, name: "google" },
        createdAt: 0,
        email: "",
        firstName: "",
        hashId: "",
        id: 3,
        isDeleted: false,
        lastName: "",
        licence: {
          contacts: "",
          createdAt: 0,
          fio: "",
          hashId: "",
          licence: 0,
          modifiedAt: 0,
          name: "",
          region: { id: 0, name: "" },
          service: { id: 0, name: "" },
        },
        modifiedAt: 0,
        pictureUrl: "",
        role: { id: 2, name: "" },
        service: { id: 0, name: "" },
        status: { id: 1, name: "" },
        theme: { id: 1, name: "dark" },
      },
    },
  ]);

  useEffect(() => {
    const filter = {
      pageNumber: paginationData.pageNumber || 1,
      pageSize: paginationData.pageSize || 30,
      addressHashId: props.id,
      // licence: 0,

      // searchTerm: "ба",
    };

    objectService
      .searchObjectEvent(filter)
      .then((data: any) => {
        setEvents(data.data);
        // console.log("searchObjectEventHistoryAddress", data);
        return data;
      })
      .then((data: any) =>
        setPaginationData((oldState: any) => ({
          ...oldState,
          pageNumber: filter.pageNumber + 1,
          totalCount: data.totalCount,
          pageSize: data.pageSize,
        }))
      );
  }, []);

  const [isShowLoader, setIsShowLoader] = useState(false);

  const fetchMoreData = () => {
    // if (paginationData.totalCount > events.length) {
    try {
      const filterData = {
        pageNumber: paginationData.pageNumber,
        pageSize: paginationData.pageSize,
        addressHashId: props.id,
      };

      setIsShowLoader(true);

      objectService
        .searchObjectEvent(filterData)
        .then((data: any) => {
          setEvents((oldEvents: any) => [...oldEvents].concat(data.data));

          return data;
        })
        .then((data: any) =>
          setPaginationData((oldState: any) => ({
            ...oldState,
            pageNumber: filterData.pageNumber + 1,
            totalCount: data.totalCount,
            pageSize: data.pageSize,
          }))
        )
        .then(() => setIsShowLoader(false));
    } catch (err) {
      enqueueSnackbar(err.toString(), {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        variant: "error",
        action: cancelAction,
      });
    }
    // }
  };

  const [paginationData, setPaginationData] = useState({
    pageNumber: Number(),
    pageSize: Number(),
    totalCount: Number(),
  });

  return (
    <div>
      <Grow in>
        <Grid>
          <Button
            className={classes.btnStylesWithOutline}
            onClick={handleClickOpen}
            style={{ minWidth: 220, margin: "10px 0px" }}
            startIcon={
              props.name === "address" ? (
                <HomeWorkIcon />
              ) : props.name === "pe" ? (
                <PersonIcon />
              ) : props.name === "le" ? (
                <BusinessIcon />
              ) : (
                ""
              )
            }
          >
            <Typography color="textPrimary">
              {props.name === "address"
                ? t("btns.addressHistory")
                : props.name === "pe"
                ? t("btns.peHistory")
                : props.name === "le"
                ? t("btns.leHistory")
                : ""}
            </Typography>
          </Button>
        </Grid>
      </Grow>

      <Grid container item xs={12} justify="center" style={{ width: "100%" }}>
        <Dialog
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
            timeout: 400,
          }}
          maxWidth="lg"
          // fullScreen
          style={{ width: "100%" }}
          open={open}
          scroll={"paper"}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ backgroundColor: theme.palette.grey[900] }}
          >
            <Grid container item xs={12} justify="space-between" wrap="nowrap">
              <Grid item style={{ width: "100%" }}>
                <Typography variant="h5" style={{ marginRight: 50 }}>
                  {props.name === "address"
                    ? t("btns.addressHistory")
                    : props.name === "pe"
                    ? t("btns.peHistory")
                    : props.name === "le"
                    ? t("btns.leHistory")
                    : ""}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                >
                  <Typography>{t("btns.close")}</Typography>
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent
            dividers={true}
            style={{ backgroundColor: theme.palette.grey[900], width: "100%" }}
            tabIndex={999}
            id="scroll-dialog-description"
          >
            {/* <DialogContentText
              style={{ backgroundColor: theme.palette.grey[900] }}
              id="scroll-dialog-description"
              tabIndex={-1}
            > */}
            {events.length < 1 ? (
              <>
                <p style={{ textAlign: "center" }}>
                  {/* <b> ви переглянули всі адреси! </b> */}
                  <span style={{ fontSize: 40 }}> 🤷‍♂️ </span>
                </p>
              </>
            ) : (
              <Grid container item xs={12} style={{ width: "100%" }}>
                {events.map((item: any) => (
                  <Grid key={item.hashId} container item xs={12}>
                    <EventItem eventItem={item} />
                  </Grid>
                ))}

                {events.length < paginationData.totalCount ? (
                  <Grid container item xs={12} justify="center">
                    {isShowLoader ? (
                      <Grid container item xs={12} style={{ height: 55 }}>
                        <CircularSpinner forFetch={true} />
                      </Grid>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={fetchMoreData}
                        style={{ minWidth: 200 }}
                      >
                        <Typography
                          variant="button"
                          color="textPrimary"
                          style={{ fontWeight: 700, fontSize: 20 }}
                        >
                          Показати ще{" "}
                          <span style={{ fontSize: 25 }}>
                            {`${
                              paginationData.pageSize >
                              paginationData.totalCount - events.length
                                ? paginationData.totalCount - events.length
                                : paginationData.pageSize
                            }`}{" "}
                          </span>
                          з{" "}
                          <span style={{ fontSize: 25 }}>{`${
                            paginationData.totalCount - events.length
                          }`}</span>
                        </Typography>
                      </Button>
                    )}
                  </Grid>
                ) : (
                  <Grid container item xs={12} justify="center">
                    <p style={{ textAlign: "center" }}>
                      {/* <b> ви переглянули всі адреси! </b> */}
                      <span style={{ fontSize: 40 }}> 🤷‍♂️ </span>
                    </p>
                  </Grid>
                )}
              </Grid>
            )}
            {/* </DialogContentText> */}
          </DialogContent>
        </Dialog>
      </Grid>
    </div>
  );
};

export default withSnackbar(HistoryAddress);
