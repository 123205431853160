import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Grid,
  IconButton,
  makeStyles,
  Theme,
  useMediaQuery,
} from "@material-ui/core";

import NavigationTabs from "../NavigationTabs/NavigationTabs";

import SearchField from "../Search/SearchField";
import User from "./User";
import AddUser from "./AddUser";
import { userService } from "../../services/userService";
import CircularSpinner from "../spinners/circular";
import SearchBtn from "../Search/SearchBtn";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar, withSnackbar } from "notistack";
import { pubSubService } from "../../services/pubSubService";
import { UserUpdatedEvent } from "../../models/event.model";
import InfiniteScroll from "react-infinite-scroll-component";

import { dataService } from "../../services/dataService";
import ToTopBtn from "../ButtonsWithConfirmation/ToTopBtn";
import SearchInfo from "../Search/SearchInfo";
import { debounce } from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  spinner: {
    paddingTop: 110,
  },
  user: {
    margin: "45px auto",
    // width: "90%",
    minWidth: 375,
  },
  admin: {},
}));

const UsersPage = () => {
  // const theme = useTheme();
  const matches890 = useMediaQuery("(min-width:890px)");

  const classes = useStyles();
  // const { t } = useTranslation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const cancelAction = () => {
    return (
      <IconButton onClick={() => closeSnackbar()}>
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  };

  const [searchVal, setSearch] = useState({ field: "" });

  const getSearch = (e: any) => {
    const searchText = e.trim();

    setSearch((searchVal: any) => ({
      ...searchVal,
      field: searchText,
    }));
    delayedQuery(searchText);
  };

  const delayedQuery = useCallback(
    debounce((text: any) => {
      onSearch(text);
    }, 300),
    []
  );

  const onSearch = (text: any) => {
    try {
      setIsLoaded(true);
      const filterData = {
        pageNumber: 1,
        searchTerm: text,
        // pageSize: paginationData.pageSize,
        // licence: 0,
      };

      userService
        .searchUsers(filterData)
        .then((data: any) => {
          setUsers(data.data);
          // console.log("searchUsers", data);
          return data;
        })
        .then((data: any) =>
          setPaginationData((oldState: any) => ({
            ...oldState,
            pageNumber: filterData.pageNumber + 1,
            totalCount: data.totalCount,
            pageSize: data.pageSize,
          }))
        )
        .then(() => setIsLoaded(false));
    } catch (err) {
      enqueueSnackbar(err.toString(), {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        variant: "error",
        action: cancelAction,
      });
    }
  };

  const [users, setUsers] = useState([
    {
      authProvider: { id: 0, name: "" },
      createdAt: 0,
      email: "",
      firstName: "",
      hashId: "",
      id: 0,
      isDeleted: false,
      lastName: "",
      licence: {
        contacts: "",
        createdAt: 0,
        fio: "",
        hashId: "",
        licence: 0,
        modifiedAt: 0,
        name: "",
        region: { id: 0, name: "м.Київ" },
        service: { id: 0, name: "Address" },
      },
      modifiedAt: 0,
      pictureUrl: "",
      role: { id: 1, name: "admin" },
      service: { id: 0, name: "Address" },
      status: { id: 1, name: "Registered" },
      theme: { id: 1, name: "dark" },
    },
  ]);

  const [isLoaded, setIsLoaded] = useState(false);

  const eventHandler = function (msg: any, data: any) {
    if (data instanceof UserUpdatedEvent) {
      setIsLoaded(true);
      const filter = {
        pageNumber: 1,
        pageSize: paginationData.pageSize || 20,
        searchTerm: searchVal.field,
      };

      userService
        .searchUsers(filter)
        .then((data: any) => {
          setUsers(data.data);
          return data;
          // console.log("userService.searchUsers", data);
        })
        .then((data: any) =>
          setPaginationData((oldState: any) => ({
            ...oldState,
            pageNumber: filter.pageNumber + 1,
            totalCount: data.totalCount,
            pageSize: data.pageSize,
          }))
        )
        .then(() => setIsLoaded(false));
    }
  };

  useEffect(() => {
    pubSubService.userSubscribe(eventHandler);
    setIsLoaded(true);
    const filter = {
      pageNumber: paginationData.pageNumber || 1,
      pageSize: paginationData.pageSize || 20,
      searchTerm: searchVal.field,
    };

    userService
      .searchUsers(filter)
      .then((data: any) => {
        setUsers(data.data);
        return data;
        // console.log("userService.searchUsers", data);
      })
      .then((data: any) =>
        setPaginationData((oldState: any) => ({
          ...oldState,
          pageNumber: filter.pageNumber + 1,
          totalCount: data.totalCount,
          pageSize: data.pageSize,
        }))
      )
      .then(() => setIsLoaded(false));

    return () => {
      pubSubService.unsubsribe(eventHandler, "Event-handler");
    };
  }, []);

  const fetchMoreData = () => {
    if (paginationData.totalCount > users.length) {
      try {
        const filter = {
          pageNumber: paginationData.pageNumber || 1,
          pageSize: paginationData.pageSize || 20,
          searchTerm: searchVal.field,
        };

        userService
          .searchUsers(filter)
          .then((data: any) => {
            setUsers((oldUsers: any) => [...oldUsers].concat(data.data));

            return data;
            // console.log("userService.searchUsers", data);
          })
          .then((data: any) =>
            setPaginationData((oldState: any) => ({
              ...oldState,
              pageNumber: filter.pageNumber + 1,
              totalCount: data.totalCount,
              pageSize: data.pageSize,
            }))
          )
          .then(() => setIsLoaded(false));
      } catch (err) {
        enqueueSnackbar(err.toString(), {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          variant: "error",
          action: cancelAction,
        });
      }
    }
  };

  const [paginationData, setPaginationData] = useState({
    pageNumber: Number(),
    pageSize: Number(),
    totalCount: Number(),
  });

  return (
    <Grid
      container
      item
      xs={12}
      justify={"space-between"}
      // justify={isAdmin ? "space-between" : "flex-start"}
      direction={"row"}
      wrap="nowrap"
    >
      <Grid item style={{ display: matches890 ? "" : "none" }}>
        <NavigationTabs />
      </Grid>

      <Grid item xs={12} className={classes.user}>
        <Grid container item xs={12} wrap="nowrap">
          <Grid item style={{ width: "100%" }}>
            <SearchField
              name={"user"}
              getSearch={getSearch}
              // onSearch={onSearch}
              // resetData={String("")}
              // reset={reset}
            />
          </Grid>
          {/* <Grid item>
              <SearchBtn onSearch={onSearch} />
            </Grid> */}

          <Grid item>
            <AddUser />
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <SearchInfo paginationData={paginationData} isLoading={!isLoaded} />
        </Grid>

        <Grid container item xs={12} style={{ marginBottom: 50 }}>
          <div style={{ width: "100%" }}>
            <InfiniteScroll
              dataLength={users.length}
              next={fetchMoreData}
              hasMore={users.length < paginationData.totalCount ? true : false}
              loader={
                <Grid
                  container
                  item
                  xs={12}
                  justify="center"
                  style={{ marginTop: 50 }}
                >
                  <CircularSpinner forFetch={true} />
                </Grid>
              }
              // height={"100%"}
              scrollThreshold="300px"
              className={classes.infScroll}
              endMessage={
                users.length === paginationData.totalCount && (
                  <p style={{ textAlign: "center" }}>
                    {/* <b> ви переглянули всі події! </b> */}
                    <span style={{ fontSize: 40 }}> 🤷‍♂️ </span>
                  </p>
                )
              }
            >
              {users.map((item: any) => (
                <Grid key={item.hashId} container item xs={12}>
                  {item.hashId ? (
                    <User user={item} />
                  ) : (
                    <Grid
                      container
                      item
                      xs={12}
                      justify="center"
                      style={{ marginTop: 50 }}
                    >
                      <CircularSpinner forFetch={true} />
                    </Grid>
                  )}
                </Grid>
              ))}
            </InfiniteScroll>
          </div>
        </Grid>

        <ToTopBtn paginationData={paginationData} arrLength={users.length} />
      </Grid>
    </Grid>
  );
};

export default withSnackbar(UsersPage);
