import React, { useEffect, useState, useMemo } from "react";
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import Grow from "@material-ui/core/Grow/Grow";
import { Role } from "../../models/static.model";
import { dataService } from "../../services/dataService";
import { licenceService } from "../../services/licenceService";
import DeleteBtn from "../ButtonsWithConfirmation/DeleteBtn";
import CloseIcon from "@material-ui/icons/Close";
import { withSnackbar, useSnackbar } from "notistack";
import CancelBtn from "../ButtonsWithConfirmation/CancelBtn";
import AcceptBtn from "../ButtonsWithConfirmation/AcceptBtn";
import EditBtn from "../ButtonsWithConfirmation/EditBtn";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    background: theme.palette.background.paper,
    // height: 300,
    width: "100%",
    // margin: "30px auto 0px",
    margin: "30px auto 0px",
    cursor: "pointer",
  },

  btnStylesWithOutline: {
    minHeight: 48,
    border: `2px solid ${ theme.palette.primary.main }`,
    backgroundColor: theme.palette.background.paper,

    borderRadius: 25,
    boxSizing: "border-box",
    boxShadow: `0px 0px 2px ${ theme.palette.primary.main }`,
    transition: "0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0px 0px 10px ${ theme.palette.primary.main }`,
    },
  },
}));

const LightTextInDisabledTextField = withStyles({
  root: {
    // marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#fefefe", // (default alpha is 0.38)
    },
    "& .MuiInputLabel-outlined.Mui-disabled": {
      color: "#fefefe90", // (default alpha is 0.38)
    },
  },
})(TextField);

const LicenseItem = (props: any) => {
  const { fio, licence, hashId } = props.license;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const cancelAction = () => {
    return (
      <IconButton onClick={ () => closeSnackbar() }>
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  };

  const [licenceData, setLicenceData] = useState({
    hashId: "",
    name: "",
    fio: "",
    contacts: "",
  });

  useEffect(() => {
    setLicenceData(props.license);
    setEditedData(props.license);
    // defaultDate();
  }, []);

  const myRole = useMemo(() => dataService.getRoleSync(), []);

  const isAdmin = myRole === Role.Admin;

  const [editedData, setEditedData] = useState({
    hashId: "",
    name: "",
    fio: "",
    contacts: "",
  });

  const [isEdited, setIsEdited] = useState(false);

  const handleEditLicence = () => {
    setIsEdited(!isEdited);
    // const editedObject = {
    //   hashId: editedData.hashId,
    //   name: editedData.name,
    //   subject: editedData.subject,
    //   document: editedData.document,
    //   date: Date.now(),
    //   description: editedData.description,
    //   source: editedData.source,
    // };
    // objectService.editAddress(editedObject);
  };

  // console.log("props.license :", props.license);

  const [openObject, setOpenObject] = useState({ hashId: "", isOpen: false });

  const handleOpenObject = (idItem: any) => {
    props.getOpen(idItem);
    // setTimeout(() => {
    setOpenObject({ hashId: idItem, isOpen: !openObject.isOpen });
    // }, 150);
  };

  // {
  //   contacts: "",
  //   createdAt: 0,
  //   fio: "",
  //   hashId: "",
  //   licence: 0,
  //   modifiedAt: 0,
  //   name: "",
  //   region: { id: 0, name: "" },
  //   service: { id: 0, name: "" },
  //   user: {
  //     authProvider: { id: 1, name: "google" },
  //     createdAt: 0,
  //     email: "",
  //     firstName: "",
  //     hashId: "",
  //     id: 0,
  //     isDeleted: false,
  //     lastName: "",
  //     modifiedAt: 0,
  //     pictureUrl: "",
  //     role: { id: 1, name: "" },
  //     service: { id: 0, name: "" },
  //     status: { id: 1, name: "" },
  //     theme: { id: 1, name: "dark" },
  //   },
  // },

  const theme = useTheme();

  const classes = useStyles();
  const { t } = useTranslation();

  const confirmCanceledLicence = () => {
    setLicenceData(props.license);
    setIsEdited(false);
  };
  const confirmEditLicence = () => {
    const editedLicence = {
      hashId: editedData.hashId,
      name: editedData.name,
      fio: editedData.fio,
      contacts: editedData.contacts,

      // hashId: editedData.hashId,
      // name: editedData.name,
      // subject: editedData.subject,
      // document: editedData.document,
      // date: editedData.date,
      // description: editedData.description,
      // source: editedData.source,
    };
    // objectService.editAddress(editedObject).then(() => handleEditLicence());
    licenceService
      .editLicence(editedLicence)
      .then(() => handleEditLicence())
      .then(() => props.getUpdateLicences());
  };

  const handleDeleteLicence = (hash: any) => {
    licenceService
      .deleteLicenceById(hash)
      .then((data: any) => {
        setLicenceData((oldVals: any) => ({
          ...oldVals,
          isDeleted: data.isDeleted,
        }));
        setEditedData((oldVals: any) => ({
          ...oldVals,
          isDeleted: data.isDeleted,
        }));
      })
      .then(() => {
        props.getUpdateLicences();
      })
      .catch((e: any) =>
        enqueueSnackbar(e.toString(), {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          variant: "error",
          action: cancelAction,
        })
      );
  };

  const handleChange = (name: any, value: any) => {
    if (name === "pib") {
      setLicenceData((oldVals: any) => ({
        ...oldVals,
        fio: value,
      }));
      setEditedData((oldVals: any) => ({
        ...oldVals,
        fio: value,
      }));
    }
    if (name === "position") {
      setLicenceData((oldVals: any) => ({
        ...oldVals,
        name: value,
      }));
      setEditedData((oldVals: any) => ({
        ...oldVals,
        name: value,
      }));
    }
    if (name === "contacts") {
      setLicenceData((oldVals: any) => ({
        ...oldVals,
        contacts: value,
      }));
      setEditedData((oldVals: any) => ({
        ...oldVals,
        contacts: value,
      }));
    }
  };

  return (
    <Grow in>
      <>
        <Card className={ classes.card } style={ { border: props.license.isInGovUaDb ? '' : '1px solid red' } }>
          {/* <Grid container item xs={12} justify="space-between" style={{}}> */ }
          <CardActionArea
            style={ {
              padding: "10px 10px ",
            } }
            onClick={ () => handleOpenObject(props.license.hashId) }
            aria-expanded={ openObject.isOpen }
            disableTouchRipple={ isEdited }
            disableRipple={ isEdited }
          >
            <Grid
              container
              item
              xs={ 12 }
              // justify="space-between"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
            >
              <Grid item>
                <LightTextInDisabledTextField
                  disabled
                  rows="1"
                  rowsMax="5"
                  multiline
                  // label={t("referral.input")}
                  label={ t("licences.licenceN") }
                  variant="outlined"
                  style={ { width: 130 } }
                  value={ licence }
                  inputProps={ {
                    style: { textAlign: "center", fontSize: 24 },
                    disabled: true,
                    // textAlign: "center",
                  } }
                />
              </Grid>
              <Grid
                item
                style={ {
                  width: "100%",
                  marginLeft: 30,
                } }
              >
                <Grid container item xs={ 12 }></Grid>
                { isEdited ? (
                  <LightTextInDisabledTextField
                    fullWidth
                    disabled={ !isEdited }
                    name="pib"
                    rows="1"
                    rowsMax="5"
                    multiline
                    onChange={
                      isEdited
                        ? (e: any) => handleChange(e.target.name, e.target.value)
                        : () => {
                          // console.log("FFFAAAALLLSSS");
                        }
                    }
                    value={ licenceData.fio }
                    label={ t("licences.pib") }
                    variant="outlined"
                    style={ { width: "100%" } }
                    inputProps={
                      {
                        // style: { textAlign: "center", fontSize: 24 },
                        // disabled: true,
                        // textAlign: "center",
                      }
                    }
                  />
                ) : (
                  <Typography
                    variant="h6"
                    style={ {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "pre-wrap",
                    } }
                  >
                    { licenceData.fio }
                  </Typography>
                ) }
                <Grid
                  container
                  item
                  xs={ 12 }
                  style={ {
                    marginTop: isEdited ? 10 : 0,

                    width: "100%",
                  } }
                >
                  { isEdited ? (
                    <Grid container item xs={ 12 } style={ { width: "100%" } }>
                      <LightTextInDisabledTextField
                        fullWidth
                        rows="1"
                        rowsMax="5"
                        multiline
                        disabled={ !isEdited }
                        name="position"
                        onChange={
                          isEdited
                            ? (e: any) =>
                              handleChange(e.target.name, e.target.value)
                            : () => {
                              // console.log("FFFAAAALLLSSS");
                            }
                        }
                        value={ licenceData.name }
                        label={ t("licences.name") }
                        variant="outlined"
                        style={ { width: "100%" } }
                        inputProps={
                          {
                            // style: { textAlign: "center", fontSize: 24 },
                            // disabled: true,
                            // textAlign: "center",
                          }
                        }
                      />
                    </Grid>
                  ) : (
                    <Typography
                      style={ {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "pre-wrap",
                      } }
                    >
                      { licenceData.name }
                    </Typography>
                    // <Typography>{props.license.name}</Typography>
                  ) }
                </Grid>
                <Grid
                  container
                  item
                  xs={ 12 }
                  style={ { marginTop: isEdited ? 10 : 0 } }
                >
                  { isEdited ? (
                    <LightTextInDisabledTextField
                      fullWidth
                      rows="1"
                      rowsMax="5"
                      multiline
                      disabled={ !isEdited }
                      name="contacts"
                      onChange={
                        isEdited
                          ? (e: any) =>
                            handleChange(e.target.name, e.target.value)
                          : () => {
                            // console.log("FFFAAAALLLSSS");
                          }
                      }
                      value={ licenceData.contacts }
                      label={ t("licences.contacts") }
                      variant="outlined"
                      style={ { width: "100%" } }
                      inputProps={
                        {
                          // style: { textAlign: "center", fontSize: 24 },
                          // disabled: true,
                          // textAlign: "center",
                        }
                      }
                    />
                  ) : (
                    // <Typography>{props.license.contacts}</Typography>
                    <Typography
                      style={ {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "pre-line",
                      } }
                    >
                      { licenceData.contacts }
                    </Typography>
                  ) }
                </Grid>
              </Grid>
            </Grid>

            { "user" in props.license && (
              <>
                <Grid
                  container
                  item
                  xs={ 12 }
                  // justify="space-between"
                  style={ { marginTop: 10 } }
                  alignItems="center"
                  alignContent="center"
                  wrap="nowrap"
                >
                  <Grid item>
                    <Grid
                      container
                      item
                      xs={ 12 }
                      justify="flex-start"
                      alignContent="center"
                      alignItems="center"
                    >
                      <Avatar
                        src={ props.license.user.pictureUrl }
                        style={ {
                          width: "100px",
                          height: "100px",
                        } }
                      />
                    </Grid>
                  </Grid>
                  <Grid item style={ { marginLeft: 60 } }>

                    <Typography
                      variant="h6"
                      style={ {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "pre-line",
                      } }
                    >
                      { props.license.user.firstName !== undefined
                        ? `${ props.license.user.firstName } ${ props.license.user.lastName }`
                        : "запрошення відправлено на EMAIL:" }


                    </Typography>
                    <Typography
                      style={ {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "pre-line",
                      } }
                    >
                      { props.license.user.email }
                    </Typography>
                    { "lastSeenAt" in props.license.user && (
                      <Typography
                        style={ {
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "pre-line",
                        } }
                      >
                        { `Був онлайн: ${ new Date(
                          props.license.user.lastSeenAt
                        ).toLocaleTimeString([], {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour12: false,
                          hour: "2-digit",
                          minute: "2-digit",
                        }) }` }
                      </Typography>
                    ) }
                    { props.license.user.isDeleted && (
                      <Typography
                        color="error"
                        style={ {
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "pre-line",
                        } }
                      >
                        { t("chips.deleted") }
                      </Typography>
                    ) }
                  </Grid>
                </Grid>
              </>
            ) }
          </CardActionArea>


          <Collapse
            in={
              props.isOpen.id === licenceData.hashId ? props.isOpen.isOpen : false
            }
            // in={openObject.hashId === props.license.hashId && openObject.isOpen}
            timeout="auto"
            unmountOnExit
            onExit={ confirmCanceledLicence }
          >
            <CardContent>
              <Grid container item xs={ 12 } justify="space-evenly">
                { isAdmin &&
                  (isEdited ? (
                    <Grid container item xs={ 12 } justify="space-evenly">
                      <Grid>
                        <CancelBtn confirmCanceled={ confirmCanceledLicence } />
                      </Grid>
                      <Grid>
                        <AcceptBtn confirmEdit={ confirmEditLicence } />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container item xs={ 12 } justify="space-evenly">
                      <Grid>
                        <EditBtn handleEdit={ handleEditLicence } />
                      </Grid>

                      <Grid>
                        <DeleteBtn
                          handleDelete={ handleDeleteLicence }
                          hashId={ licenceData.hashId }
                        />
                      </Grid>
                    </Grid>
                  )) }
              </Grid>
            </CardContent>
          </Collapse>
        </Card>
        <Grid container item xs={ 12 }>
          <Typography
            color='error'
            style={ {
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "pre-line",

            } }
          >
            { !props.license.isInGovUaDb && "Свідоцтво видалено з Єдиного Реєстру Нотаріусів" }


          </Typography>
        </Grid>
      </>
    </Grow>
  );
};

export default withSnackbar(LicenseItem);
