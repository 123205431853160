import React, { useEffect, useMemo, useState } from "react";
import {
  Grid,
  IconButton,
  makeStyles,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import NavigationTabs from "../NavigationTabs/NavigationTabs";
import { objectService } from "../../services/objectService";
import EventItem from "./EventItem";
import { Role } from "../../models/static.model";

import CircularSpinner from "../spinners/circular";
import InfiniteScroll from "react-infinite-scroll-component";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar, withSnackbar } from "notistack";
import { ObjectEventAddedEvent } from "../../models/event.model";
import { pubSubService } from "../../services/pubSubService";
import { dataService } from "../../services/dataService";
import ToTopBtn from "../ButtonsWithConfirmation/ToTopBtn";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: "100%",
    padding: 10,
  },
  infScroll: {
    width: "100%",
    // position: "absolute",
    // height: "max-content !important",
    overflow: "visible !important",
  },
  spinner: {
    paddingTop: 110,
  },
  user: {
    margin: "45px auto",

    // width: "90%",
    minWidth: 375,
  },
  admin: {},
}));

const MainPage = () => {
  // const theme = useTheme();
  const matches890 = useMediaQuery("(min-width:890px)");

  const classes = useStyles();
  // const { t } = useTranslation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const cancelAction = () => {
    return (
      <IconButton onClick={() => closeSnackbar()}>
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  };
  const myRole = useMemo(() => dataService.getRoleSync(), []);

  const [events, setEvents] = useState([
    {
      address: {
        addressStatus: { id: 0, name: "" },
        addressType: { id: 0, name: "" },
        createdAt: 0,
        date: 0,
        description: "",
        document: "",
        hashId: "",
        isDeleted: false,
        modifiedAt: 0,
        name: " ",
        source: "",
        subject: "",
      },
      createdAt: 0,
      eventType: { id: 0, name: "" },
      hashId: "",
      user: {
        authProvider: { id: 1, name: "google" },
        createdAt: 0,
        email: "",
        firstName: "",
        hashId: "",
        id: 3,
        isDeleted: false,
        lastName: "",
        licence: {
          contacts: "",
          createdAt: 0,
          fio: "",
          hashId: "",
          licence: 0,
          modifiedAt: 0,
          name: "",
          region: { id: 0, name: "" },
          service: { id: 0, name: "" },
        },
        modifiedAt: 0,
        pictureUrl: "",
        role: { id: 2, name: "" },
        service: { id: 0, name: "" },
        status: { id: 1, name: "" },
        theme: { id: 1, name: "dark" },
      },
    },
  ]);
  const [isLoaded, setIsLoaded] = useState(false);

  const eventHandler = function (msg: any, data: any) {
    if (data instanceof ObjectEventAddedEvent) {
      const filter = {
        pageNumber: paginationData.pageNumber || 1,
        pageSize: paginationData.pageSize || 20,
        // licence: 0,

        // searchTerm: "ба",
      };

      objectService
        .searchObjectEvent(filter)
        .then((data: any) => {
          setEvents(data.data);
          return data;
        })
        .then((data: any) =>
          setPaginationData((oldState: any) => ({
            ...oldState,
            pageNumber: filter.pageNumber + 1,
            totalCount: data.totalCount,
            pageSize: data.pageSize,
          }))
        )
        .then(() => setIsLoaded(true));
    }
  };

  useEffect(() => {
    pubSubService.objectEventSubscribe(eventHandler);

    const filter = {
      pageNumber: paginationData.pageNumber || 1,
      pageSize: paginationData.pageSize || 20,
      // licence: 0,

      // searchTerm: "ба",
    };

    objectService
      .searchObjectEvent(filter)
      .then((data: any) => {
        setEvents(data.data);
        return data;
      })
      .then((data: any) =>
        setPaginationData((oldState: any) => ({
          ...oldState,
          pageNumber: filter.pageNumber + 1,
          totalCount: data.totalCount,
          pageSize: data.pageSize,
        }))
      )
      .then(() => setIsLoaded(true));

    return () => {
      pubSubService.unsubsribe(eventHandler, "Event-handler");
    };
  }, []);

  const fetchMoreData = () => {
    if (paginationData.totalCount > events.length) {
      try {
        const filterData = {
          pageNumber: paginationData.pageNumber,
          pageSize: paginationData.pageSize,
        };

        objectService
          .searchObjectEvent(filterData)
          .then((data: any) => {
            setEvents((oldEvents: any) => [...oldEvents].concat(data.data));

            return data;
          })
          .then((data: any) =>
            setPaginationData((oldState: any) => ({
              ...oldState,
              pageNumber: filterData.pageNumber + 1,
              totalCount: data.totalCount,
              pageSize: data.pageSize,
            }))
          );
      } catch (err) {
        enqueueSnackbar(err.toString(), {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          variant: "error",
          action: cancelAction,
        });
      }
    }
  };

  const [paginationData, setPaginationData] = useState({
    pageNumber: Number(),
    pageSize: Number(),
    totalCount: Number(),
  });

  const isAdmin = myRole === Role.Admin;

  return (
    <Grid
      container
      item
      xs={12}
      justify={"space-between"}
      // justify={isAdmin ? "space-between" : "flex-start"}
      direction={"row"}
      wrap="nowrap"
    >
      <Grid item style={{ display: matches890 ? "" : "none" }}>
        <NavigationTabs />
      </Grid>

      {/* {isLoaded ? ( */}
      <Grid item xs={12} sm={12} md={12} className={classes.user}>
        <div style={{ width: "100%" }}>
          <InfiniteScroll
            dataLength={events.length}
            next={fetchMoreData}
            hasMore={events.length < paginationData.totalCount ? true : false}
            loader={
              <Grid
                container
                item
                xs={12}
                justify="center"
                style={{ marginTop: 50 }}
              >
                <CircularSpinner forFetch={true} />
              </Grid>
            }
            // height={"100%"}
            scrollThreshold="300px"
            className={classes.infScroll}
            endMessage={
              events.length === paginationData.totalCount && (
                <p style={{ textAlign: "center" }}>
                  {/* <b> ви переглянули всі події! </b> */}
                  <span style={{ fontSize: 40 }}> 🤷‍♂️ </span>
                </p>
              )
            }
          >
            {events.map((item: any) => (
              <Grid key={item.hashId} container item xs={12}>
                {item.hashId ? (
                  <EventItem eventItem={item} />
                ) : (
                  <Grid
                    container
                    item
                    xs={12}
                    justify="center"
                    className={classes.spinner}
                  >
                    <CircularSpinner />
                  </Grid>
                )}
              </Grid>
            ))}
          </InfiniteScroll>
        </div>

        <ToTopBtn paginationData={paginationData} arrLength={events.length} />
      </Grid>
      {/* ) : (
        <Grid
          container
          item
          xs={12}
          justify="center"
          className={classes.spinner}
        >
          <CircularSpinner />
        </Grid>
      )} */}
    </Grid>
  );
};

export default withSnackbar(MainPage);
