import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import NavigationTabsforUser from "../NavigationTabs/NavigationTabsforUser";
import { Role } from "../../models/static.model";
import { dataService } from "../../services/dataService";
import ToTopBtn from "../ButtonsWithConfirmation/ToTopBtn";
import { userService } from "../../services/userService";
import CircularSpinner from "../spinners/circular";
import { linkToRules, linkToSearch } from "../Routes/var_PATHS";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: "#fff",
    "&$checked": {
      color: "#000",
    },
  },

  spinner: {
    paddingTop: 110,
  },
  // user: {
  //   margin: "30px auto",
  //   width: "70%",
  //   minWidth: 375,
  // },
  // admin: {
  //   width: "100%",
  // },
  btnStylesWithOutline: {
    minHeight: 48,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 25,
    boxSizing: "border-box",
    boxShadow: `0px 0px 2px ${theme.palette.primary.main}`,
    transition: "0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0px 0px 10px ${theme.palette.primary.main}`,
    },
  },
  subTitles: {
    paddingLeft: 30,
  },
  text: {
    fontSize: 16,
  },
  infScroll: {
    width: "100%",

    // position: "absolute",
    // height: "max-content !important",
    overflow: "visible !important",
  },

  user: {
    transitionDuration: "0.8s",
    transitionProperty: "transform",

    margin: "45px auto",
    width: "75%",
    minWidth: 375,
  },

  admin: {
    width: "75%",

    // margin: "20px auto",
  },
}));

const TermsAndConditions = () => {
  const matches890 = useMediaQuery("(min-width:890px)");
  const classes = useStyles();

  const [user, setUser] = useState({
    authProvider: { id: 0, name: "" },
    createdAt: 0,
    email: "",
    firstName: "",
    hashId: "",
    id: 0,
    isDeleted: false,
    lastName: "",
    licence: {
      contacts: "",
      createdAt: 0,
      fio: "",
      hashId: "",
      licence: 0,
      modifiedAt: 0,
      name: "",
      region: { id: 0, name: "" },
      service: { id: 0, name: "" },
    },
    modifiedAt: 0,
    pictureUrl: "",
    termsAccepted: false,
    role: { id: 0, name: "" },
    service: { id: 0, name: "" },
    status: { id: 0, name: "" },
    theme: { id: 0, name: "" },
  });

  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    userService
      .getMe()
      .then((dataUser: any) => {
        // console.log("dataUser", dataUser);
        setUser(dataUser);
        return dataUser;
      })
      .then((dataUser: any) => {
        !dataUser.termsAccepted ? handleOpen() : handleClose();
      })
      .then(() => setIsLogin(true));
  }, []);

  const [open, setOpen] = React.useState(true);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <Grid item xs={12} className={classes.user}>
      <Grid
        container
        item
        xs={12}
        style={{
          marginTop: 10,
          padding: "10px",
          border: "solid 2px #fafafa",
          borderRadius: 20,
        }}
      >
        <Grid container item xs={12} justify="center">
          <Typography variant="h5" align="center">
            Шановні колеги!
          </Typography>
        </Grid>
        <Typography variant="subtitle1" className={classes.text}>
          У зв’язку з великою кількістю шахрайських дій з нерухомістю та з
          суб’єктами бізнесу, з офіційних (листів управлінь юстиції, МЮУ,
          Національної поліції) та неофіційних (звернення власника, нотаріус
          виявив підробку, клієнт повідомив про викрадення документів тощо)
          джерел зібрано перелік об’єктів, що потребують підвищеної уваги при
          вивченні документів (а також перелік втрачених документів, попереджень
          тощо).
        </Typography>
        <Typography
          variant="subtitle1"
          className={classes.text}
          style={{ marginTop: 10 }}
        >
          <span style={{ color: "#AD0001", fontWeight: "bold" }}>
            Наявність інформації в базі не є підставою для відмови у вчиненні
            нотаріальної чи реєстраційної дії!
            <br />
            Це підстава для більш уважної перевірки документів.
            <br />
            Посилання на базу заборонено.
          </span>
        </Typography>
      </Grid>

      <Grid container item xs={12} justify="center" style={{ marginTop: 50 }}>
        <Typography variant="h4" align="center">
          Правила користування базою проблемних суб’єктів та об’єктів
        </Typography>
      </Grid>

      <br />
      <hr />
      <br />
      <Grid container item xs={12} direction="column">
        <Grid container item xs={12} className={classes.subTitles}>
          <Typography variant="h5">1. Основні положення:</Typography>
        </Grid>

        <br />

        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.text}>
            1.1. База проблемних суб’єктів та об’єктів (надалі База) –
            систематизоване інформаційне джерело:
          </Typography>
        </Grid>

        <Grid
          container
          item
          xs={12}
          // style={{ paddingLeft: 20 }}
          direction="column"
        >
          <ul>
            <li>
              <Typography variant="subtitle1" className={classes.text}>
                проблемних об’єктів рухомого та нерухомого майна,
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" className={classes.text}>
                проблемних питань діяльності юридичних та фізичних осіб –
                підприємців,
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" className={classes.text}>
                громадян, осіб без громадянства, які постраждали від дій
                шахраїв,
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" className={classes.text}>
                документів, які є підробленими, не відповідають та/або
                суперечать вимогам чинного законодавства тощо.
              </Typography>
            </li>
          </ul>
        </Grid>

        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.text}>
            1.2. Наповнення Бази відбувається з використанням офіційних та
            неофіційних джерел.
          </Typography>
        </Grid>

        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.text}>
            1.3. Дані, що містяться в Базі, мають конфіденційний характер та
            призначені винятково для користування особами, які отримають доступ
            до Бази.
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.text}>
            1.4. Адміністратор не несе відповідальності за будь-який прямий або
            непрямий збиток і втрачену вигоду, навіть якщо це стало результатом
            використання Бази
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.text}>
            1.5. Доступ до ресурсів Бази є безоплатною послугою, що надається
            виключно користувачам, які отримали Свідоцтво про право на заняття
            нотаріальною діяльністю та є діючими приватними або державними
            нотаріусами міста Києва.
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.text}>
            1.6. Доступ до ресурсів Бази надається за умови:
          </Typography>
        </Grid>

        <Grid container item xs={12} direction="column">
          <ul>
            <li>
              <Typography variant="subtitle1" className={classes.text}>
                їх реєстрації як користувачів Бази;
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" className={classes.text}>
                ознайомлення з "Правилами користування базою проблемних
                суб’єктів та об’єктів» та отримання згоди користувачів на їх
                дотримання;
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" className={classes.text}>
                володіння основними навичками роботи на персональному комп’ютері
                (ПК), включаючи правила роботи в середовищі Windows, Google та
                самостійної роботи в мережі Інтернет.
              </Typography>
            </li>
          </ul>
        </Grid>

        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.text}>
            1.7. Доступ до Бази не обмежений у часі, надається цілодобово.
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.text}>
            1.8. Перед початком роботи з Базою користувач повинен зареєструвати
            свій візит в облікових формах, тобто здійснити вхід.
          </Typography>
        </Grid>

        <br />

        <Grid container item xs={12} className={classes.subTitles}>
          <Typography variant="h5">2. Права користувачів</Typography>
        </Grid>

        <br />

        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.text}>
            Користувачі мають право на:
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.text}>
            2.1. Безоплатний доступ до Бази.
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.text}>
            2.2. Надання пропозицій щодо покращання роботи Бази.
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.text}>
            2.3. Надання нової чи виправлення наявної інформації в Базі,
            виключно через надсилання відповідного повідомлення Адміністратору
            Бази.
          </Typography>
        </Grid>

        <br />

        <Grid container item xs={12} className={classes.subTitles}>
          <Typography variant="h5">3. Обов'язки користувачів</Typography>
        </Grid>

        <br />

        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.text}>
            3.1. Користувачі повинні знати «Правила користування базою
            проблемних суб’єктів та об’єктів» та дотримуватися їх.
          </Typography>
        </Grid>

        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.text}>
            3.2. Користувачі повинні:
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <ul>
            <li>
              <Typography variant="subtitle1" className={classes.text}>
                поважати право інших користувачів, дотримуватися етики та
                моральних норм поведінки;
              </Typography>
            </li>
          </ul>
        </Grid>

        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.text}>
            3.3. Користувачам не дозволяється:
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <ul>
            <li>
              <Typography variant="subtitle1" className={classes.text}>
                використовувати Базу за не прямим призначенням;
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" className={classes.text}>
                здійснювати несанкціоноване розповсюдження даних Бази чи
                копіювання таких даних на власний електронний носій (комп’ютер,
                флеш-накопичувач, диск тощо).
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" className={classes.text}>
                самостійно вносити правки, зміни, доповнення до існуючої Бази;
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle1" className={classes.text}>
                встановлювати будь-яке програмне забезпечення (в т.ч. запускати
                його з електронних носіїв (дискет, CD та DVD дисків,
                флеш-носіїв, переносних жорстких дисків і т. ін.), змінювати
                існуючу конфігурацію операційної системи, змінювати налаштування
                роботи з Базою.
              </Typography>
            </li>
          </ul>
        </Grid>

        <br />

        <Grid container item xs={12} className={classes.subTitles}>
          <Typography variant="h5">
            4. Адміністратор не несе відповідальність за :
          </Typography>
        </Grid>

        <br />

        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.text}>
            4.1.Будь-які збої технічного характеру, що не залежать від дій
            Адміністратора.
          </Typography>
        </Grid>

        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.text}>
            4.2. Звернення користувачів до ресурсів Бази з метою здійснення
            діяльності, забороненої чинним законодавством України.
          </Typography>
        </Grid>

        <br />

        <Grid container item xs={12} className={classes.subTitles}>
          <Typography variant="h5">5. Заключні положення:</Typography>
        </Grid>

        <br />

        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.text}>
            5.1. Доступ до ресурсів Бази є правом користувача.
          </Typography>
        </Grid>

        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.text}>
            5.2. Порушення будь-якого пункту "Правил користування Бази
            проблемних суб’єктів та об’єктів», зафіксоване Адміністратором, є
            достатньою підставою для припинення сеансу роботи користувача та/або
            позбавлення його права доступу до Бази.
          </Typography>
        </Grid>

        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.text}>
            <span style={{ color: "#AD0001", fontWeight: "bold" }}>
              5.3. Неодноразове копіювання даних Бази (більше 3 разів) є
              підставою для автоматичного відключення доступу користувача.
            </span>
            <br />
            Поновлення доступу лише через подання нової заяви.
          </Typography>
        </Grid>
      </Grid>

      {user.termsAccepted && (
        <Grid container item xs={12} style={{ margin: "30px 0px 60px 0px" }}>
          <FormControlLabel
            color="red"
            // className={classes.root}
            // disabled
            control={
              <Checkbox
                // className={classes.root}
                color="primary"
                checked
                name="approved"
              />
            }
            label={
              <Typography variant="h6">
                Погоджуюсь з правилами користування.
              </Typography>
            }
          />
        </Grid>
      )}
    </Grid>
  );

  const handleApproveTerms = () => {
    userService
      .acceptTerms()
      .then((dataUser: any) => setUser(dataUser))
      .then(() => setOpen(false))
      .then(() => linkToSearch());
  };

  return (
    <Grid
      container
      item
      xs={12}
      justify={"space-between"}
      direction={"row"}
      wrap="nowrap"
      style={{
        transitionDuration: "0.8s",
        transitionProperty: "transform",
      }}
    >
      <Grid item style={{ display: matches890 ? "" : "none" }}>
        <NavigationTabsforUser />
      </Grid>

      {isLogin ? (
        <>
          <ToTopBtn />

          {body}

          <Dialog
            disableEscapeKeyDown
            open={open}
            fullScreen
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            scroll={"paper"}
          >
            {body}
            <br />
            <Grid
              container
              item
              xs={12}
              justify="center"
              style={{ margin: "30px 0" }}
            >
              <Button
                className={classes.btnStylesWithOutline}
                style={{ minWidth: 350 }}
                onClick={handleApproveTerms}
              >
                <Typography style={{ fontWeight: 600 }}>
                  Прийняти умови використання
                </Typography>
              </Button>
            </Grid>
          </Dialog>
        </>
      ) : (
        <Grid container justify="center" className={classes.spinner}>
          <CircularSpinner />
        </Grid>
      )}
    </Grid>
  );
};

export default TermsAndConditions;
