import { Licence, RegionView } from "./licence.model";
import { ServiceView } from "./object.model";
import { RcmdRequest } from "./rcmd-request.model";
import { RcmdResponseBody } from "./rcmd-response.model";
import { User } from "./user.model";
export class LoginRequest implements RcmdRequest {
  successUrl: String;
  cancelUrl: String;
  errorUrl: String;
  serviceId: Number;
  regionId: Number;

  constructor(
    successUrl: string,
    cancelUrl: string,
    errorUrl: string,
    serviceId: number,
    regionId: number
  ) {
    this.successUrl = successUrl;
    this.cancelUrl = cancelUrl;
    this.errorUrl = errorUrl;
    this.serviceId = serviceId;
    this.regionId = regionId;
  }
}

export interface LoginResponse extends RcmdResponseBody {
  url: string;
}

export class SignUpRequest implements RcmdRequest {
  serviceId: number;
  regionId: number;
  name: string;
  email: string;
  licenceNumber: number;
  registrationNumber?: number;
  address: string;
  phoneNumber?: string;

  constructor(
    serviceId: number,
    regionId: number,
    name: string,
    email: string,
    licenceNumber: number,
    registrationNumber: number,
    address: string,
    phoneNumber?: string
  ) {
    this.serviceId = serviceId;
    this.regionId = regionId;
    this.name = name;
    this.email = email;
    this.licenceNumber = licenceNumber;
    this.registrationNumber = registrationNumber;
    this.address = address;
    this.phoneNumber = phoneNumber;
  }
}

export interface SignUpRequestStatusView {
  id: number;
  name: string;
}

export interface SignUp {
  id: number;
  hashId: string;
  service?: ServiceView;
  region?: RegionView;
  name?: string;
  email?: string;
  licenceNumber?: number;
  licence?: Licence;
  registrationNumber?: number;
  address?: string;
  user?: User;
  isDeleted?: Boolean;
  status?: SignUpRequestStatusView;
  phoneNumber?: string;
  createdAt?: number;
}

export class SignUpRequestFilter implements RcmdRequest {
  pageNumber?: number;
  pageSize?: number;
  statusId: number;
  searchTerm: string;

  constructor(
    pageNumber: number,
    pageSize: number,
    statusId: number,
    searchTerm: string
  ) {
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.statusId = statusId;
    this.searchTerm = searchTerm;
  }
}

export class CaptchaRequest implements RcmdRequest {
  response: string;

  constructor(response: string) {
    this.response = response;
  }
}
export class RejectSignUpRequest implements RcmdRequest {
  hashId: string;
  reason?: string;

  constructor(hashId: string, reason: string) {
    this.hashId = hashId;
    this.reason = reason;
  }
}
