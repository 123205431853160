import React, { useEffect, useState } from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const ColorLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    colorPrimary: {
      backgroundColor: "#424242",
    },
    barColorPrimary: {
      backgroundColor: "#00BCD4",
    },
  })
)(LinearProgress);

export default function LinearSpinner() {
  const [view, setView] = useState(false);

  useEffect(() => {
    let timer = setTimeout(() => {}, 0);

    timer = setTimeout(() => {
      setView(true);
    }, 300);

    return () => clearTimeout(timer);
  }, [view]);

  return <div style={{ flexGrow: 1 }}>{view && <ColorLinearProgress />}</div>;
}
