import React, { useState } from "react";
import {
  makeStyles,
  Card,
  CardActionArea,
  Grid,
  Theme,
  Typography,
  withStyles,
  CardContent,
  Collapse,
  useMediaQuery,
  Tooltip,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";

import { useTranslation } from "react-i18next";

import Grow from "@material-ui/core/Grow/Grow";

import EventObjectItem from "./EventObjectItem";

import FileCopyIcon from "@material-ui/icons/FileCopy";
import HistoryUser from "../HistoryModal/HistoryUser";
import HistoryAddress from "../HistoryModal/HistoryAddress";
import OpenUserBtn from "../ButtonsWithConfirmation/OpenUserBtn";
import PersonIcon from "@material-ui/icons/Person";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import BusinessIcon from "@material-ui/icons/Business";

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[4],
    borderRadius: 20,
    fontSize: 15,
    padding: "15px",
  },
}))(Tooltip);

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    background: theme.palette.background.paper,
    // height: 300,
    flexGrow: 1,
    width: "100%",
    margin: "15px 0",
  },
}));

const EventItem = (props: any) => {
  const matches960 = useMediaQuery("(min-width:960px)");
  const matches1280 = useMediaQuery("(min-width:1280px)");

  const classes = useStyles();

  const { t } = useTranslation();

  const [openObject, setOpenObject] = useState({ hashId: "", isOpen: false });

  const handleOpenEvent = (idItem: any) => {
    // setTimeout(() => {
    setOpenObject({ hashId: idItem, isOpen: !openObject.isOpen });
    // }, 150);
  };

  // console.log(" props.eventItem", props.eventItem);

  return (
    <Grow in>
      <Card
        className={classes.card}
        style={{
          border: props.eventItem.eventType.id === 1 ? "2px solid red" : "",
          width: "100%",
        }}
      >
        <CardActionArea onClick={() => handleOpenEvent(props.eventItem.hashId)}>
          <Grid
            container
            item
            sm={12}
            justify="space-between"
            alignContent="center"
            alignItems="center"
            // direction="row"
            // wrap="nowrap"
            style={{ padding: 10, width: "100%" }}
          >
            <Grid item lg={2} sm={6}>
              <Grid container item wrap="nowrap">
                <Grid container item xs={12} wrap="nowrap">
                  <Grid item>
                    {props.eventItem.eventType.id === 0 ? (
                      <LightTooltip title={props.eventItem.eventType.name}>
                        <VisibilityIcon />
                      </LightTooltip>
                    ) : (
                      <LightTooltip title={props.eventItem.eventType.name}>
                        <FileCopyIcon />
                      </LightTooltip>
                    )}
                  </Grid>
                  <Grid item style={{ paddingLeft: 20 }}>
                    <Typography>{props.eventItem.eventType.name}</Typography>
                  </Grid>
                  <Grid item style={{ paddingLeft: 20 }}>
                    {props.eventItem.address.addressType.id === 0 ? (
                      <LightTooltip
                        title={props.eventItem.address.addressType.name}
                      >
                        <HomeWorkIcon />
                      </LightTooltip>
                    ) : props.eventItem.address.addressType.id === 2 ? (
                      <LightTooltip
                        title={props.eventItem.address.addressType.name}
                      >
                        <PersonIcon />
                      </LightTooltip>
                    ) : props.eventItem.address.addressType.id === 3 ? (
                      <LightTooltip
                        title={props.eventItem.address.addressType.name}
                      >
                        <BusinessIcon />
                      </LightTooltip>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={4} sm={6}>
              {"user" in props.eventItem && (
                <Typography align={matches1280 ? "center" : "right"}>
                  {props.eventItem.user.licence.fio}
                </Typography>
              )}
            </Grid>

            <Grid item lg={3} sm={6}>
              {"user" in props.eventItem && (
                <Typography align={matches1280 ? "right" : "left"}>
                  {props.eventItem.user.email}
                </Typography>
              )}
            </Grid>

            <Grid item lg={3} sm={6}>
              {"address" in props.eventItem && (
                <Typography align="right">
                  {new Date(props.eventItem.createdAt).toLocaleTimeString([], {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour12: false,
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid container item xs={12} style={{ padding: "0px 10px 10px" }}>
            {"address" in props.eventItem && (
              <Typography
                variant="h6"
                style={{
                  // whiteSpace: "nowrap" /* Отменяем перенос текста */,
                  textOverflow: "ellipsis",
                  overflow: "hidden" /* Обрезаем содержимое */,
                  // paddingRight: "10px",
                  maxHeight: matches960 ? 65 : 55,
                  whiteSpace: "pre-wrap",
                }}
              >
                <span
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden" /* Обрезаем содержимое */,
                  }}
                >
                  {props.eventItem.address.addressType.id === 0
                    ? props.eventItem.address.subject
                    : props.eventItem.address.addressType.id === 2
                    ? props.eventItem.address.name
                    : props.eventItem.address.addressType.id === 3
                    ? props.eventItem.address.name
                    : ""}
                </span>
              </Typography>
            )}

            <Grid container item xs={12}>
              {"address" in props.eventItem && (
                <Typography>{props.eventItem.address.document}</Typography>
              )}
            </Grid>
            <Grid container item xs={12} justify="flex-end">
              {"user" in props.eventItem && props.eventItem.user.isDeleted && (
                <Typography color="error" align="right">
                  {" "}
                  {t("chips.userDeleted")}
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardActionArea>

        <Collapse in={openObject.isOpen} timeout="auto" unmountOnExit>
          <CardContent>
            {props.eventItem.copiedDate && (
              <Grid container item xs={12}>
                <Typography
                  color={"error"}
                >{`Було скопійовано: ${props.eventItem.copiedDate}`}</Typography>
              </Grid>
            )}
            <Grid container item xs={12}>
              <EventObjectItem
                name={
                  props.eventItem.address.addressType.id === 0
                    ? "address"
                    : props.eventItem.address.addressType.id === 2
                    ? "pe"
                    : props.eventItem.address.addressType.id === 3
                    ? "le"
                    : ""
                }
                objectItem={props.eventItem}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              justify="space-evenly"
              style={{ marginTop: 20 }}
            >
              <Grid item style={{ margin: "10px 0px" }}>
                {"user" in props.eventItem && (
                  <HistoryUser id={props.eventItem.user.hashId} />
                )}
              </Grid>

              <Grid item style={{ margin: "10px 0px" }}>
                {"address" in props.eventItem && (
                  <HistoryAddress
                    name={
                      props.eventItem.address.addressType.id === 0
                        ? "address"
                        : props.eventItem.address.addressType.id === 2
                        ? "pe"
                        : props.eventItem.address.addressType.id === 3
                        ? "le"
                        : ""
                    }
                    id={props.eventItem.address.hashId}
                  />
                )}
              </Grid>

              <Grid item style={{ margin: "10px 0px" }}>
                {"user" in props.eventItem && (
                  <OpenUserBtn id={props.eventItem.user.hashId} />
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </Grow>
  );
};

export default EventItem;
