export type JwtToken = string;

export class AuthToken {
  tokenType: TokenType;
  token?: JwtToken;
  constructor(type: TokenType, token?: JwtToken) {
    this.tokenType = type;
    this.token = token;
  }
}

export interface TokenType {
  name: string;
}

export class SessionToken implements TokenType {
  name: string = "session";
}

export class RefreshToken implements TokenType {
  name: string = "refresh";
}

export class EmptyToken implements TokenType {
  name: string = "empty";
}
