import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Grow from "@material-ui/core/Grow/Grow";

import { userService } from "../../services/userService";
import User from "../UsersPage/User";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    background: theme.palette.background.paper,
    // height: 300,
    width: "100%",
    margin: "15px auto",
    padding: 0,
  },

  btnStylesWithOutline: {
    minHeight: 48,
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.paper,

    borderRadius: 25,
    boxSizing: "border-box",
    boxShadow: `0px 0px 2px ${theme.palette.primary.main}`,
    transition: "0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0px 0px 10px ${theme.palette.primary.main}`,
    },
  },
  backDrop: {
    backdropFilter: "blur(7px)",
    backgroundColor: `${theme.palette.grey[100]}30`,
  },
}));

const OpenUserBtn = (props: any) => {
  const theme = useTheme();

  const classes = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    // console.log("open : ", open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [user, setUser] = useState({
    user: {
      authProvider: { id: 1, name: "google" },
      createdAt: 0,
      email: "",
      firstName: "",
      hashId: "",
      id: 3,
      isDeleted: false,
      lastName: "",
      licence: {
        contacts: "",
        createdAt: 0,
        fio: "",
        hashId: "",
        licence: 0,
        modifiedAt: 0,
        name: "",
        region: { id: 0, name: "" },
        service: { id: 0, name: "" },
      },
      modifiedAt: 0,
      pictureUrl: "",
      role: { id: 2, name: "" },
      service: { id: 0, name: "" },
      status: { id: 1, name: "" },
      theme: { id: 1, name: "dark" },
    },
  });

  useEffect(() => {
    userService.getUserById(props.id).then((data: any) => setUser(data));
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <Grow in>
        <Grid>
          <Button
            className={classes.btnStylesWithOutline}
            onClick={handleClickOpen}
            style={{ minWidth: 220, margin: "10px 0px" }}
          >
            <Typography color="textPrimary">{t("btns.openUser")}</Typography>
          </Button>
          {/* <IconButton
            onClick={handleClickOpen}
            color="primary"
            style={{ width: 80, height: 80 }}
          >
            <AddCircleIcon fontSize="large" style={{ width: 60, height: 60 }} />
          </IconButton> */}
        </Grid>
      </Grow>

      <Grid container item xs={12} justify="center">
        <Dialog
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
            timeout: 400,
          }}
          maxWidth="lg"
          // fullScreen
          open={open}
          scroll={"paper"}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          {/* <DialogTitle id="form-dialog-title">{t("help.label")}</DialogTitle> */}

          <DialogTitle
            id="form-dialog-title"
            style={{ backgroundColor: theme.palette.grey[900] }}
          >
            <Grid container item xs={12} justify="space-between">
              <Grid item>
                <Typography
                  variant="h5"
                  style={{ marginRight: 50 }}
                >{`Профіль користувача`}</Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  color="primary"
                >
                  <Typography>{t("btns.close")}</Typography>
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>

          <DialogContent
            dividers={true}
            style={{ backgroundColor: theme.palette.grey[900] }}
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            <Grid container item xs={12} justify="center">
              <User user={user} openNow={true} />
            </Grid>
            {/* <DialogContentText
              style={{ backgroundColor: theme.palette.grey[900] }}
              
            > */}
            {/* <Grid container item xs={12}>
              {events.map((item: any) => (
                <Grid key={item.hashId} container item xs={12}>
                  <EventItem eventItem={item} />
                </Grid>
              ))}
            </Grid> */}
            {/* </DialogContentText> */}
          </DialogContent>
        </Dialog>
      </Grid>
    </div>
  );
};

export default OpenUserBtn;
