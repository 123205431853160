import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import Logger from "../utils/logger";
import { RcmdEmptyBody } from "../models/rcmd-response.model";
import { authService } from "./authService";
import { responseValidator } from "./responseValidator";

class HttpClient {
  private axiosInstance: AxiosInstance;
  private logger = new Logger(this.constructor.name);

  /**
   * Request Ok Interceptor
   */
  private okRequestInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
    const interceptorLogger = new Logger(">>> OkRequestInterceptor");
    interceptorLogger.debug(
      `request ready to send: ${config.method} : ${config.url}`
    );

    if (config.method !== "get") {
      interceptorLogger.debug("request ready to send: data:", config.data);
    }

    return config;
  }

  /**
   * Request Error Interceptor
   */
  private errorRequestInterceptor(error: any): Promise<any> {
    const interceptorLogger = new Logger(">>> ErrorRequestInterceptor");
    interceptorLogger.error("error occurred", error);
    return Promise.reject(error);
  }

  /**
   * Response Ok Interceptor
   */
  private okResponseInterceptor(response: AxiosResponse): AxiosResponse {
    const interceptorLogger = new Logger("<<< OkResponseInterceptor");
    interceptorLogger.debug(
      `ok response received: for request: ${response.config.method} : ${response.config.url}`
    );
    return response;
  }

  /**
   * Response Error Interceptor
   */
  private errorResponseInterceptor(error: any): Promise<any> {
    const interceptorLogger = new Logger("<<< ErrorResponseInterceptor");
    if (error.response) {
      const errorBareResponse = error.response as AxiosResponse<RcmdEmptyBody>;
      interceptorLogger.error(
        `error response received: ${errorBareResponse.config.method} : ${errorBareResponse.config.url}`
      );
      interceptorLogger.error(
        `error response received: http status code : ${errorBareResponse.status}`
      );
      interceptorLogger.error(
        "error response received: bare response data:",
        errorBareResponse.data
      );
      return responseValidator
        .validateP<RcmdEmptyBody>(errorBareResponse.data, true)
        .then((validatedResp) => {
          return authService.hanldeExpiredToken(
            errorBareResponse,
            validatedResp.header,
            error
          );
        });
    } else {
      // window.location.replace(`${config.feBaseUrl}${MAINTENANCE_PATH}`);

      return Promise.reject(error);
    }
  }

  constructor() {
    this.axiosInstance = axios.create();
    this.logger.debug("new http-client created");

    this.axiosInstance.interceptors.request.use(
      this.okRequestInterceptor,
      this.errorRequestInterceptor
    );

    this.axiosInstance.interceptors.response.use(
      this.okResponseInterceptor,
      this.errorResponseInterceptor
    );

    this.logger.debug("request/response interceptors added");
  }

  getInstance(): AxiosInstance {
    return this.axiosInstance;
  }
}

export const httpClient: AxiosInstance = new HttpClient().getInstance();
