import Logger from "../utils/logger";
import {
  RcmdEvent,
  USER_TOPIC,
  AUTH_TOPIC,
  LICENCE_TOPIC,
  REQUEST_TOPIC,
  OBJECT_TOPIC,
  OBJECT_EVENT_TOPIC,
  UserUpdatedEvent,
  ObjectUpdatedEvent,
  ObjectEventAddedEvent,
  LicenceUpdatedEvent,
  NotAuthorizedAccessAddedEvent,
  SignUpRequestUpdatedEvent,
  LogOutUserEvent,
} from "../models/event.model";
import PubSub from "pubsub-js";

export class PubSubService {
  private logger = new Logger(this.constructor.name);

  authSubscribe(handler: (msg: any, data: any) => any) {
    const token = PubSub.subscribe(AUTH_TOPIC, handler);
    this.logger.debug("auth subscribtion");
    return token;
  }

  userSubscribe(handler: (msg: any, data: any) => any) {
    const token = PubSub.subscribe(USER_TOPIC, handler);
    this.logger.debug("user subscribtion");
    return token;
  }

  licenceSubscribe(handler: (msg: any, data: any) => any) {
    const token = PubSub.subscribe(LICENCE_TOPIC, handler);
    this.logger.debug("licence subscribtion ");
    return token;
  }

  requestSubscribe(handler: (msg: any, data: any) => any) {
    const token = PubSub.subscribe(REQUEST_TOPIC, handler);
    this.logger.debug("request subscribtion ");
    return token;
  }

  objectSubscribe(handler: (msg: any, data: any) => any) {
    const token = PubSub.subscribe(OBJECT_TOPIC, handler);
    this.logger.debug("object subscribtion ");
    return token;
  }

  objectEventSubscribe(handler: (msg: any, data: any) => any) {
    const token = PubSub.subscribe(OBJECT_EVENT_TOPIC, handler);
    this.logger.debug("object event subscribtion ");
    return token;
  }

  unsubsribe(tokenOrFunc: any, from?: string) {
    this.logger.debug(`trying to unsbscribe token from ${from}`);
    if (tokenOrFunc === undefined) {
      this.logger.error(`\nnot able to unsubscirbe from ${from}!!!\n`);
    } else {
      this.logger.debug(`unsubsribing from ${from}`);
      const res = PubSub.unsubscribe(tokenOrFunc);
      this.logger.debug(`unsubsrib result from ${from}, result -  ` + res);
    }
  }

  publishEvent(event: RcmdEvent) {
    if (event instanceof UserUpdatedEvent) {
      this.logger.debug(`publishing ${event.name} to ${USER_TOPIC}`);
      PubSub.publish(USER_TOPIC, event);
    } else if (event instanceof ObjectUpdatedEvent) {
      this.logger.debug(`publishing ${event.name} to ${OBJECT_TOPIC}`);
      PubSub.publish(OBJECT_TOPIC, event);
    } else if (event instanceof ObjectEventAddedEvent) {
      this.logger.debug(`publishing ${event.name} to ${OBJECT_EVENT_TOPIC}`);
      PubSub.publish(OBJECT_EVENT_TOPIC, event);
    } else if (event instanceof LicenceUpdatedEvent) {
      this.logger.debug(`publishing ${event.name} to ${LICENCE_TOPIC}`);
      PubSub.publish(LICENCE_TOPIC, event);
    } else if (event instanceof SignUpRequestUpdatedEvent) {
      this.logger.debug(`publishing ${event.name} to ${REQUEST_TOPIC}`);
      PubSub.publish(REQUEST_TOPIC, event);
    } else if (event instanceof NotAuthorizedAccessAddedEvent) {
      this.logger.debug(`publishing ${event.name} to ${USER_TOPIC}`);
      PubSub.publish(USER_TOPIC, event);
    } else if (event instanceof LogOutUserEvent) {
      this.logger.debug(`publishing ${event.name} to ${AUTH_TOPIC}`);
      PubSub.publish(AUTH_TOPIC, event);
    } else {
      this.logger.warn(`unknown event: ${event.name}, skipping to publish.. `);
    }
  }
}

export const pubSubService = new PubSubService();
