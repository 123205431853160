import React, { useEffect, useState } from "react";

import { Grid, Grow } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import LinearSpinner from "../spinners/Linear";

function SearchInfo(props: any) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(props.isLoading);
  }, [props.isLoading]);

  return (
    <Grow in>
      <Grid
        container
        item
        xs={12}
        wrap="nowrap"
        justify="flex-start"
        style={{ maxWidth: 300, position: "relative" }}
      >
        <Grid item style={{ marginRight: 10 }}>
          <Typography
            color="textPrimary"
            style={{ fontWeight: 500, fontSize: 16 }}
          >
            Знайдено{" "}
            {isLoading ? (
              <span style={{ fontSize: 18 }}>
                {`${props.paginationData.totalCount}`}
              </span>
            ) : (
              <span style={{ fontSize: 18 }}>...</span>
            )}
          </Typography>
        </Grid>
        {/* <Grid item> */}
        {!isLoading && (
          <Grid
            container
            item
            xs={12}
            style={{
              position: "absolute",
              bottom: 10,
              left: 130,
              // maxWidth: 300,
              width: "90%",
            }}
          >
            <LinearSpinner />
          </Grid>
        )}
      </Grid>
      {/* </Grid> */}
    </Grow>
  );
}

export default SearchInfo;
