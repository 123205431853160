class LocalStorageAsync {
  setItem(key: any, value: any): Promise<any> {
    return Promise.resolve().then(() => localStorage.setItem(key, value));
  }
  getItem(key: any): Promise<any> {
    return Promise.resolve().then(() => localStorage.getItem(key));
  }

  getItemSync(key: any): any {
    return localStorage.getItem(key);
  }

  clear(): Promise<void> {
    return Promise.resolve().then(() => localStorage.clear());
  }
}

export const localStorageAsync = new LocalStorageAsync();
