import React, { Component } from "react";

import { authService } from "../../services/authService";
import { userService } from "../../services/userService";
import { NpuError } from "../../models/error.model";
import { User } from "../../models/user.model";

import HeaderNew from "./HeaderAppBar";
import { linkToLogin } from "../Routes/var_PATHS";

type HeaderState = {
  user: User;
  isLoggedIn: boolean;
};

class Header extends Component<{}, HeaderState> {
  state: HeaderState = {
    user: {
      id: "",
      authProvider: {
        id: Number(),
        name: "",
      },
      role: {
        id: Number(),
        name: "",
      },
      firstName: "",
      lastName: "",
      email: "",
      pictureUrl: "",
      isDeleted: false,
    },
    isLoggedIn: false,
  };

  componentDidMount() {
    authService.isLoggedIn().then((isLoggedin) => {
      if (isLoggedin) {
        userService
          .getMe()
          .then((data: any) => {
            // console.log("state.user:", data);
            if (data.isDeleted) {
              authService.logout().then(() => linkToLogin());
            }
            return data;
          })
          .then((user: User) => {
            this.setState({ isLoggedIn: true, user });
          })
          .catch((error) => {
            if (error instanceof NpuError) {
              // const npuError = error as NpuError;
            } else {
              // logger.error(error);
              throw error;
            }
          });
      } else {
        this.setState({ isLoggedIn: false });
      }
    });
  }

  render() {
    const {
      firstName,
      lastName,
      pictureUrl,
      email,
      authProvider,
      role,
    } = this.state.user;

    return (
      <header
        style={{
          // marginBottom: "5vh",
          minWidth: 375,
          // width: "100%",
          top: 0,
          position: "fixed",
          // backgroundColor: theme.palette.background.default,
          zIndex: 1000,
          height: 70,
        }}
      >
        <HeaderNew
          firstName={firstName}
          lastName={lastName}
          pictureUrl={pictureUrl}
          email={email}
          authProvider={authProvider}
          role={role.name}
        />
      </header>
    );
  }
}

export default Header;
