import React from "react";
import { useTranslation } from "react-i18next";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Typography, Button, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withSnackbar, useSnackbar } from "notistack";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnStyles: {
      minHeight: 48,
      border: `2px solid ${theme.palette.error.main}`,
      boxSizing: "border-box",
      boxShadow: `0px 0px 4px ${theme.palette.error.main}`,
      // backgroundColor: `${theme.palette.error.main}20`,
      borderRadius: 25,
      transition: "0.2s ease-in-out",
      "&:hover": {
        boxShadow: `0px 0px 10px ${theme.palette.error.main}`,
      },
    },

    btnStylesWithOutline: {
      minHeight: 48,
      border: `2px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.background.paper,

      borderRadius: 25,
      boxSizing: "border-box",
      boxShadow: `0px 0px 2px ${theme.palette.primary.main}`,
      transition: "0.2s ease-in-out",
      "&:hover": {
        backgroundColor: theme.palette.background.paper,
        boxShadow: `0px 0px 10px ${theme.palette.primary.main}`,
      },
    },
  })
);

function CancelBtn(props: any) {
  const classes = useStyles();

  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const cancelAction = () => {
    return (
      <IconButton onClick={() => closeSnackbar()}>
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  };

  const handleCancel = () => {
    try {
      props.confirmCanceled();
    } catch (err) {
      enqueueSnackbar(err.toString(), {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        variant: "error",
        action: cancelAction,
      });
    }
  };

  return (
    <Button
      className={classes.btnStylesWithOutline}
      style={{ minWidth: 220, margin: "10px 0px" }}
      startIcon={<CloseIcon />}
      onClick={handleCancel}
    >
      <Typography color="textPrimary">{t("btns.cancel")}</Typography>
    </Button>
  );
}
export default withSnackbar(CancelBtn);
