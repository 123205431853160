import React, { useEffect } from "react";
import { Typography, Grid } from "@material-ui/core";
import { config } from "../../utils/config";
import history from "../../utils/history";
import { LOGIN_PATH } from "../Routes/var_PATHS";

const Maintenance = () => {
  useEffect(() => {
    let timerId = setInterval(
      () =>
        fetch(`${config.beBaseUrl}/health`).then(() =>
          history.push(LOGIN_PATH)
        ),
      1000
    );

    return () => {
      clearInterval(timerId);
    };
  });

  return (
    <Grid
      container
      item
      xs={12}
      justify="center"
      alignContent="center"
      alignItems="center"
      style={{ marginTop: 300 }}
      direction="column"
    >
      <Grid container item xs={12} justify="center">
        <Typography variant="h3"> Технічне обслуговування</Typography>
      </Grid>
      <Grid
        container
        item
        xs={7}
        justify="center"
        style={{ marginTop: 30 }}
        direction="column"
      >
        <Typography align="center" variant="h5">
          На жаль, веб-сайт зараз не працює.
        </Typography>
        <Typography align="center" variant="h5">
          Будь ласка, перевірте ще раз через деякий час.
        </Typography>
        <br />
        <Typography align="center" variant="h5" style={{ marginTop: 30 }}>
          Дякую!
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Maintenance;
