import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// import Backend from "i18next-xhr-backend";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const languages = ["ua", "en", "ru"];

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(HttpApi)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      lng: "ua",
      fallbackLng: ["ua"],
      debug: true,
      whitelist: languages,

      interpolation: {
        escapeValue: false,
      },
      load: languages,
      preload: languages,
      ns: "translation",
      backend: {
        loadPath: "/locales/{{lng}}/{{ns}}.json",
      },
    }

    // (r) => console.log("lang calback:", r)
  );

export default i18n;
