import React, { useState, useCallback } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Button,
  makeStyles,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SendIcon from "@material-ui/icons/Send";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Grow from "@material-ui/core/Grow/Grow";
import { userService } from "../../services/userService";
import { debounce } from "lodash";
import { licenceService } from "../../services/licenceService";
import CloseIcon from "@material-ui/icons/Close";
import { withSnackbar, useSnackbar } from "notistack";
import { NpuError } from "../../models/error.model";
import { createStyles } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {},
    searchInput: {
      width: "100%",
      margin: "0px auto ",
    },
    backDrop: {
      backdropFilter: "blur(7px)",
      backgroundColor: `${theme.palette.grey[100]}30`,
    },
  })
);

const AddUser = (props: any) => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const cancelAction = () => {
    return (
      <IconButton onClick={() => closeSnackbar()}>
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  };

  const [open, setOpen] = React.useState(false);

  const [newUser, setnewUser] = useState({
    email: "",
    licenceId: "",
  });

  const [errorMessage, setErrorMessage] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
    // console.log("open : ", open);
  };

  const handleClose = () => {
    setLicence({
      contacts: "",
      createdAt: 0,
      fio: "",
      hashId: "",
      licence: 0,
      modifiedAt: 0,
      name: "",
      region: { id: 0, name: "" },
      service: { id: 0, name: "" },
    });

    setOpen(false);
  };

  const delayedQuery = useCallback(
    debounce((licence: any) => {
      if (licence > 100) {
        licenceService
          .getLicenceByNumber(licence)
          .then((data: any) => {
            setLicence(data);
            setErrorMessage("");
            return data;
          })
          .then((data: any) =>
            setnewUser((oldValue: any) => ({
              ...oldValue,
              licenceId: data.hashId,
            }))
          )
          .catch((e: NpuError) => {
            setErrorMessage(e.message);
          });
      }
    }, 600),
    []
  );

  const [licence, setLicence] = useState({
    contacts: "",
    createdAt: 0,
    fio: "",
    hashId: "",
    licence: 0,
    modifiedAt: 0,
    name: "",
    region: { id: 0, name: "" },
    service: { id: 0, name: "" },
  });

  const handleChange = (e: any, name: any, value: any) => {
    e.preventDefault();

    if (name === "email") {
      setnewUser((oldValue: any) => ({ ...oldValue, email: value }));
    }
    if (name === "licence") {
      const licenceNumber = Number(value.trim().replace(/\D/gim, ""));

      setLicence((oldValue: any) => ({
        ...oldValue,
        licence: licenceNumber,
      }));
      delayedQuery(licenceNumber);
    }
    // console.log(name, value);
  };

  const sendNewUser = () => {
    userService
      .addUserToWhiteList(newUser)
      .then(() => handleClose())
      .catch((e: any) =>
        enqueueSnackbar(e.toString(), {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          variant: "error",
          action: cancelAction,
        })
      );
  };

  return (
    <>
      <Grow in>
        <Grid>
          <IconButton
            onClick={handleClickOpen}
            color="primary"
            style={{ width: 80, height: 80 }}
          >
            <AddCircleIcon
              color="primary"
              fontSize="large"
              style={{ width: 60, height: 60 }}
            />
          </IconButton>
        </Grid>
      </Grow>

      <Grid container item xs={12} justify="center">
        <Dialog
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
            timeout: 400,
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          {/* <DialogTitle id="form-dialog-title">{t("help.label")}</DialogTitle> */}
          <DialogTitle
            id="form-dialog-title"
            style={{ backgroundColor: theme.palette.grey[900] }}
          >
            {t("users.modalTitle")}
          </DialogTitle>
          <DialogContent
            dividers={true}
            style={{ backgroundColor: theme.palette.grey[900] }}
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            {/* <DialogContentText>{t("betaTest.titleFeedback")}</DialogContentText> */}
            <Grid container item xs={12} style={{ width: "850px" }}>
              <TextField
                label="email"
                fullWidth
                margin="dense"
                variant="outlined"
                multiline
                rows={1}
                rowsMax="1"
                name="email"
                onChange={(e: any) =>
                  handleChange(e, e.target.name, e.target.value)
                }

                // inputProps={{ disabled: true }}
              />
              <TextField
                label={t("users.licence")}
                fullWidth
                margin="dense"
                variant="outlined"
                multiline
                rows={1}
                rowsMax="1"
                name="licence"
                value={licence.licence}
                onChange={(e: any) =>
                  handleChange(e, e.target.name, e.target.value)
                }

                // inputProps={{ disabled: true }}
              />
            </Grid>
            <Grid style={{ height: 100 }}>
              {licence.hashId && (
                <Grid container item xs={12} direction="column">
                  <Typography>{licence.fio}</Typography>
                  <Typography> {licence.name}</Typography>
                  <Typography>{licence.contacts}</Typography>
                </Grid>
              )}
              {errorMessage && (
                <Typography color="error">{errorMessage}</Typography>
              )}
            </Grid>
          </DialogContent>
          <DialogActions style={{ backgroundColor: theme.palette.grey[900] }}>
            <Grid container item xs={12} justify="space-between">
              <Grid>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  color="primary"
                  startIcon={<CancelIcon />}
                  style={{ minWidth: 180, margin: "5px 0px" }}
                >
                  <Typography>{t("btns.cancel")}</Typography>
                </Button>
              </Grid>
              <Grid>
                <Button
                  variant="contained"
                  onClick={sendNewUser}
                  color="primary"
                  startIcon={<SendIcon />}
                  style={{ minWidth: 180, margin: "5px 0px" }}
                >
                  <Typography>{t("btns.send")}</Typography>
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

export default withSnackbar(AddUser);
