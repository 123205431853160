import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import SearchField from "../Search/SearchField";
import StampItem from "./StampItem";
import AddStamps from "./AddStamps";
import { dataService } from "../../services/dataService";
import { objectService } from "../../services/objectService";
import CircularSpinner from "../spinners/circular";
import SearchBtn from "../Search/SearchBtn";
import { pubSubService } from "../../services/pubSubService";
import { ObjectUpdatedEvent } from "../../models/event.model";
import InfiniteScroll from "react-infinite-scroll-component";
import ToTopBtn from "../ButtonsWithConfirmation/ToTopBtn";
import SearchInfo from "../Search/SearchInfo";
import { debounce } from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  spinner: {
    paddingTop: 110,
  },
  infScroll: {
    width: "100%",

    // position: "absolute",
    // height: "max-content !important",
    overflow: "visible !important",
  },

  user: {
    margin: "15px auto 45px",
    width: "75%",
    minWidth: 375,
  },
}));

const StampsPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [searchVal, setSearch] = useState({ field: "" });

  const getSearch = (e: any) => {
    const searchText = e.trim();

    setSearch((searchVal: any) => ({
      ...searchVal,
      field: searchText,
    }));
    delayedQuery(searchText);
  };

  const delayedQuery = useCallback(
    debounce((text: any) => {
      onSearch(text);
    }, 300),
    []
  );

  const onSearch = (text: any) => {
    setIsLoaded(true);
    const searchTerm = text;
    const filter = {
      pageNumber: 1,
      // pageSize: paginationData.pageSize || 30,
      // licence: 0,

      // searchTerm: "ба",
    };

    objectService
      .searchObject({
        typeId: 1,
        statusId: 0,
        searchTerm,
      })
      .then((data: any) => setStampsNew(data.data))
      .then(() =>
        objectService
          .searchObject({
            pageNumber: filter.pageNumber,
            // pageSize: filter.pageSize,
            typeId: 1,
            statusId: 1,
            searchTerm,
          })
          .then((data: any) => {
            setStamps(data.data);
            return data;
          })
          .then((data: any) =>
            setPaginationData((oldState: any) => ({
              ...oldState,
              pageNumber: filter.pageNumber + 1,
              totalCount: data.totalCount,
              pageSize: data.pageSize,
            }))
          )
      )
      .then(() => setIsLoaded(false));
  };
  const myRole = useMemo(() => dataService.getRoleSync(), []);

  const [isLoaded, setIsLoaded] = useState(false);

  const [stamps, setStamps] = useState([
    {
      addressStatus: { id: 1, name: "" },
      approvedBy: {
        authProvider: { id: 1, name: "" },
        createdAt: 0,
        email: "@gmail.com",
        firstName: "",
        hashId: "",
        id: 0,
        isDeleted: false,
        lastName: "",
        licence: {
          contacts: "",
          createdAt: 0,
          fio: "",
          hashId: "",
          licence: 0,
          modifiedAt: 0,
          name: "",
          region: { id: 0, name: "" },
          service: { id: 0, name: "" },
        },
        modifiedAt: 1608030207043,
        pictureUrl: "",
        role: { id: 1, name: "" },
        service: { id: 0, name: "" },
        status: { id: 1, name: "" },
        theme: { id: 1, name: "" },
      },
      createdAt: 0,
      createdBy: {
        authProvider: { id: 1, name: "" },
        createdAt: 0,
        email: "@gmail.com",
        firstName: "",
        hashId: "jw",
        id: 2,
        isDeleted: false,
        lastName: "",
        licence: {
          contacts: "",
          createdAt: 0,
          fio: "f",
          hashId: "",
          licence: 0,
          modifiedAt: 0,
          name: "",
          region: { id: 0, name: "м." },
          service: { id: 0, name: "" },
        },
        modifiedAt: 0,
        pictureUrl: "",
        role: { id: 1, name: "" },
        service: { id: 0, name: "" },
        status: { id: 1, name: "" },
        theme: { id: 1, name: "" },
      },
      hashId: "",
      isDeleted: false,
      modifiedAt: 0,
      id: 0,
      name: "",
      subject: "",
    },
  ]);

  useEffect(() => {
    setIsLoaded(true);
    const filter = {
      pageNumber: paginationData.pageNumber || 1,
      pageSize: paginationData.pageSize || 30,
      // licence: 0,

      // searchTerm: "ба",
    };

    objectService
      .searchObject({
        pageNumber: filter.pageNumber,
        pageSize: filter.pageSize,
        typeId: 1,
        statusId: 1,
        searchTerm: searchVal.field,
      })
      .then((data: any) => {
        setStamps(data.data);

        return data;
      })
      .then((data: any) =>
        setPaginationData((oldState: any) => ({
          ...oldState,
          pageNumber: filter.pageNumber + 1,
          totalCount: data.totalCount,
          pageSize: data.pageSize,
        }))
      )
      .then(() => setIsLoaded(false));
  }, []);

  const [isLoadedNew, setIsLoadedNew] = useState(false);

  const [stampsNew, setStampsNew] = useState([
    {
      addressStatus: { id: 0, name: "" },
      approvedBy: {
        authProvider: { id: 0, name: "" },
        createdAt: 0,
        email: "",
        firstName: "",
        hashId: "",
        id: 0,
        isDeleted: false,
        lastName: "",
        licence: {
          contacts: "",
          createdAt: 0,
          fio: "",
          hashId: "",
          licence: 0,
          modifiedAt: 0,
          name: "",
          region: { id: 0, name: "м.Київ" },
          service: { id: 0, name: "Address" },
        },
        modifiedAt: 0,
        pictureUrl: "",
        role: { id: 0, name: "" },
        service: { id: 0, name: "" },
        status: { id: 1, name: "Registered" },
        theme: { id: 1, name: "dark" },
      },
      createdAt: 0,
      createdBy: {
        authProvider: { id: 0, name: "" },
        createdAt: 0,
        email: "",
        firstName: "",
        hashId: "",
        id: 0,
        isDeleted: false,
        lastName: "",
        licence: {
          contacts: "",
          createdAt: 0,
          fio: "",
          hashId: "",
          licence: 0,
          modifiedAt: 0,
          name: "",
          region: { id: 0, name: "м.Київ" },
          service: { id: 0, name: "Address" },
        },
        modifiedAt: 0,
        pictureUrl: "",
        role: { id: 1, name: "admin" },
        service: { id: 0, name: "Address" },
        status: { id: 0, name: "Registered" },
        theme: { id: 0, name: "dark" },
      },
      hashId: "",
      id: 0,
      isDeleted: true,
      modifiedAt: 0,
      name: "",
      subject: "",
    },
  ]);

  // console.log("stampsNew", stampsNew);
  // console.log("stamps", stamps);

  const [paginationData, setPaginationData] = useState({
    pageNumber: Number(),
    pageSize: Number(),
    totalCount: Number(),
  });

  const eventHandler = function (msg: any, data: any) {
    if (data instanceof ObjectUpdatedEvent) {
      setIsLoaded(true);
      const filter = {
        pageNumber: paginationData.pageNumber || 1,
        pageSize: paginationData.pageSize || 30,
        // licence: 0,

        // searchTerm: "ба",
      };

      objectService
        .searchObject({
          typeId: 1,
          statusId: 0,
          searchTerm: searchVal.field,
        })
        .then((data: any) => setStampsNew(data.data))
        .then(() =>
          objectService
            .searchObject({
              pageNumber: filter.pageNumber,
              pageSize: filter.pageSize,
              typeId: 1,
              statusId: 1,
              searchTerm: searchVal.field,
            })
            .then((data: any) => {
              setStamps(data.data);
              return data;
            })
            .then((data: any) =>
              setPaginationData((oldState: any) => ({
                ...oldState,
                pageNumber: filter.pageNumber + 1,
                totalCount: data.totalCount,
                pageSize: data.pageSize,
              }))
            )
        )
        .then(() => setIsLoaded(false));
    }
  };

  useEffect(() => {
    pubSubService.objectSubscribe(eventHandler);

    objectService
      .searchObject({
        typeId: 1,
        statusId: 0,
        searchTerm: searchVal.field,
      })
      .then((data: any) => setStampsNew(data.data))
      .then(() => setIsLoadedNew(true));

    return () => {
      pubSubService.unsubsribe(eventHandler, "Event-handler");
    };
  }, []);

  const getUpdateStamps = () => {
    setIsLoaded(true);
    const filter = {
      pageNumber: 1,
      pageSize: paginationData.pageSize || 30,
      // licence: 0,

      // searchTerm: "ба",
    };

    objectService
      .searchObject({
        typeId: 1,
        statusId: 0,
        searchTerm: searchVal.field,
      })
      .then((data: any) => setStampsNew(data.data))
      .then(() =>
        objectService
          .searchObject({
            pageNumber: filter.pageNumber,
            pageSize: filter.pageSize,
            typeId: 1,
            statusId: 1,
            searchTerm: searchVal.field,
          })
          .then((data: any) => {
            setStamps(data.data);
            return data;
          })
          .then((data: any) =>
            setPaginationData((oldState: any) => ({
              ...oldState,
              pageNumber: filter.pageNumber + 1,
              totalCount: data.totalCount,
              pageSize: data.pageSize,
            }))
          )
      )
      .then(() => setIsLoaded(false));
  };

  const [isOpenStamp, setIsOpenStamp] = useState({ id: "", isOpen: false });

  const getOpenStamp = (hashId: string) => {
    if (hashId === isOpenStamp.id) {
      setIsOpenStamp({ id: hashId, isOpen: !isOpenStamp.isOpen });
    } else {
      setIsOpenStamp({ id: hashId, isOpen: true });
    }
  };

  const fetchMoreData = () => {
    if (paginationData.totalCount > stamps.length) {
      const filter = {
        pageNumber: paginationData.pageNumber || 1,
        pageSize: paginationData.pageSize || 30,
        // licence: 0,

        // searchTerm: "ба",
      };

      objectService
        .searchObject({
          pageNumber: filter.pageNumber,
          pageSize: filter.pageSize,
          typeId: 1,
          statusId: 1,
          searchTerm: searchVal.field,
        })
        .then((data: any) => {
          setStamps((oldStamps: any) => [...oldStamps].concat(data.data));
          return data;
        })
        .then((data: any) =>
          setPaginationData((oldState: any) => ({
            ...oldState,
            pageNumber: filter.pageNumber + 1,
            totalCount: data.totalCount,
            pageSize: data.pageSize,
          }))
        );
    }
  };

  return (
    <Grid
      container
      item
      xs={12}
      justify={"space-between"}
      direction={"row"}
      wrap="nowrap"
    >
      <Grid item xs={12} className={classes.user}>
        {/* {isLoaded ? ( */}
        <Grid container item xs={12} style={{ width: "100%" }}>
          <Grid container item xs={12} wrap="nowrap" style={{ width: "100%" }}>
            <Grid item style={{ width: "100%" }}>
              <SearchField
                name={"stamp"}
                getSearch={getSearch}
                // onSearch={onSearch}
                // resetData={String("")}
                // reset={reset}
              />
            </Grid>
            {/* <Grid item> 
                <SearchBtn onSearch={onSearch} />
              </Grid> */}

            <Grid item>
              <AddStamps getUpdateStamps={getUpdateStamps} />
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <SearchInfo paginationData={paginationData} isLoading={!isLoaded} />
          </Grid>

          {stampsNew.filter((item: any) => item.id !== 0).length >= 1 && (
            <Grid container item xs={12}>
              {stampsNew.map((item: any) => (
                <Grid key={item.hashId} container item xs={12}>
                  <StampItem
                    stampItem={item}
                    isOpenStamp={isOpenStamp}
                    getOpenStamp={getOpenStamp}
                  />
                </Grid>
              ))}
            </Grid>
          )}

          <Grid container item xs={12} style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
              <InfiniteScroll
                style={{ minWidth: "100%" }}
                dataLength={stamps.length}
                next={fetchMoreData}
                hasMore={
                  stamps.length < paginationData.totalCount ? true : false
                }
                loader={
                  <Grid
                    container
                    item
                    xs={12}
                    justify="center"
                    style={{ marginTop: 50 }}
                  >
                    <CircularSpinner forFetch={true} />
                  </Grid>
                }
                // height={"100%"}
                scrollThreshold="300px"
                className={classes.infScroll}
                endMessage={
                  stamps.length === paginationData.totalCount && (
                    <p style={{ textAlign: "center" }}>
                      {/* <b> ви переглянули всі печатки! </b> */}
                      <span style={{ fontSize: 40 }}> 🤷‍♂️ </span>
                    </p>
                  )
                }
              >
                {stamps.map((item: any) => (
                  <Grid
                    key={item.hashId}
                    container
                    item
                    xs={12}
                    style={{
                      display: "inline-bblock",
                      width: "100%",
                    }}
                  >
                    {item.hashId ? (
                      <StampItem
                        stampItem={item}
                        isOpenStamp={isOpenStamp}
                        getOpenStamp={getOpenStamp}
                      />
                    ) : (
                      <Grid
                        container
                        item
                        xs={12}
                        justify="center"
                        className={classes.spinner}
                      >
                        <CircularSpinner />
                      </Grid>
                    )}
                  </Grid>
                ))}
              </InfiniteScroll>
            </div>
          </Grid>

          <ToTopBtn paginationData={paginationData} arrLength={stamps.length} />
        </Grid>
        {/* ) : (
          <Grid
            container
            item
            xs={12}
            justify="center"
            className={classes.spinner}
          >
            <CircularSpinner />
          </Grid>
        )} */}
      </Grid>
    </Grid>
  );
};

export default StampsPage;
