import React from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  Grid,
  Typography,
  Theme,
  makeStyles,
  useMediaQuery,
  Slide,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PageviewIcon from "@material-ui/icons/Pageview";
import BusinessIcon from "@material-ui/icons/Business";
import PersonIcon from "@material-ui/icons/Person";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

// const useStyles = makeStyles((theme: Theme) => ({
//   root: {
//     flexGrow: 1,
//     // marginTop: theme.spacing.unit * 3,
//     backgroundColor: theme.palette.background.paper,
//   },
//   tabRoot: {
//     maxWidth: 450,
//   },
// }));

export default function SearchTabs(props: any) {
  const { t } = useTranslation();
  const matches890 = useMediaQuery("(min-width:90px)");

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    props.handleChangeLevels(newValue);
  };

  return (
    <Slide direction="left" timeout={450} in mountOnEnter unmountOnExit>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="on"
        style={{ width: "100%" }}
        // ScrollButtonComponent={"object"}

        // classes={{ root: classes.tabRoot }}
      >
        <Tab
          icon={<PageviewIcon />}
          fullWidth
          label={
            matches890 && (
              <Typography variant="subtitle1">
                {t("global.globalBtn")}
              </Typography>
            )
          }
          // style={{ minWidth: matches1200 ? 550 : matches600 ? 280 : 170 }}
          // classes={{ root: classes.tabRoot }}
        />

        <Tab
          icon={<BusinessIcon />}
          fullWidth
          label={
            matches890 && (
              <Typography variant="subtitle1">{t("le.btnName")}</Typography>
            )
          }
          // style={{ minWidth: matches1200 ? 550 : matches600 ? 280 : 170 }}
          // classes={{ root: classes.tabRoot }}
        />

        <Tab
          icon={<PersonIcon />}
          fullWidth
          label={
            matches890 && (
              <Typography variant="subtitle1">{t("pe.btnName")}</Typography>
            )
          }
          // style={{ minWidth: matches1200 ? 550 : matches600 ? 280 : 170 }}
          // classes={{ root: classes.tabRoot }}
        />
        <Tab
          icon={<HomeWorkIcon />}
          fullWidth
          label={
            matches890 && (
              <Typography variant="subtitle1">
                {t("addresses.btnName")}
              </Typography>
            )
          }
          // style={{ minWidth: matches1200 ? 550 : matches600 ? 280 : 170 }}
          // classes={{ root: classes.tabRoot }}
        />

        <Tab
          icon={<CheckCircleIcon />}
          fullWidth
          label={
            matches890 && (
              <Typography variant="subtitle1">{t("stamps.btnName")}</Typography>
            )
          }
          // style={{ minWidth: matches1200 ? 550 : matches600 ? 280 : 170 }}
          // classes={{ root: classes.tabRoot }}
        />
      </Tabs>
    </Slide>
  );
}
