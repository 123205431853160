import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Typography, Slide } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import FiberNewIcon from "@material-ui/icons/FiberNew";

import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import BlockIcon from "@material-ui/icons/Block";

export default function RequestedTabs(props: any) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    props.handleChangeLevels(newValue);
  };

  return (
    <Slide direction="left" timeout={450} in mountOnEnter unmountOnExit>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        variant="fullWidth"
        style={{ width: "100%" }}
      >
        <Tab
          icon={<FiberNewIcon />}
          fullWidth
          label={<Typography variant="subtitle1">Нові</Typography>}
        />

        <Tab
          icon={<ThumbUpAltIcon />}
          fullWidth
          label={<Typography variant="subtitle1">Додані</Typography>}
        />

        <Tab
          icon={<BlockIcon />}
          fullWidth
          label={<Typography variant="subtitle1">Відхилені</Typography>}
          // style={{ minWidth: matches1200 ? 550 : matches600 ? 280 : 170 }}
          // classes={{ root: classes.tabRoot }}
        />
      </Tabs>
    </Slide>
  );
}
