import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Grid,
  IconButton,
  Slide,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";

import history from "../../utils/history";
import {
  SEARCH_PATH,
  linkToSearch,
  linkToSettings,
  SETTINGS_PATH,
  RULES_PATH,
  linkToRules,
  INSTRUCTION_PATH,
  linkToInstructions,
} from "../Routes/var_PATHS";
import SearchIcon from "@material-ui/icons/Search";
import SubjectIcon from "@material-ui/icons/Subject";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import { localStorageAsync } from "../../utils/localStorageAsync";

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    // backgroundColor: theme.palette.common.white,
    // color: "rgba(0, 0, 0, 0.87)",
    // boxShadow: theme.shadows[4],
    // borderRadius: 20,
    // fontSize: 15,
    padding: "5px",
  },
}))(Tooltip);

const useStyles = makeStyles((theme: Theme) => ({
  btnStyles: {
    textTransform: "uppercase",
    cursor: "pointer",
    // padding: "20px 0px",
    fontSize: "16px",
    lineHeight: "40px",
    border: `0px solid `,
    // boxSizing: "border-box",
    // borderWidth: "0px 0px 2px 0px",
    // boxShadow: `0px 0px 4px ${theme.palette.primary.main}`,
    borderRadius: 0,
    transition: "0.2s ease-in-out",
    maxWidth: 'max-content',
    "&:hover": {
      color: theme.palette.primary.main,
      // backgroundColor: theme.palette.background.default,
    },
    "&:active": {
      color: theme.palette.primary.main,
      // backgroundColor: theme.palette.background.default,
    },
  },
  arrowsBack: {
    transitionDuration: "0.8s",
    transitionProperty: "transform",
    transform: "rotate(0deg)",
  },
  arrows: {
    transitionDuration: "0.8s",
    transitionProperty: "transform",
    transform: "rotate(180deg)",
  },
  menuFull: {
    transitionDuration: "0.3s",

    // transitionProperty: "transform",
    width: 200,
    marginRight: 50,
    transition: "width 0.2s",
  },
  menuMini: {
    transitionDuration: "0.3s",
    // transitionProperty: "transform",
    width: 80,
    marginRight: 30,
    transition: "width 0.2s",
  },
}));

export default function NavigationTabsforUser() {
  const classes = useStyles();
  const matches890 = useMediaQuery("(min-width:890px)");

  const { t } = useTranslation();

  const getMenuStyle = localStorageAsync.getItemSync("ms") === "true";

  const [open, setOpen] = useState(getMenuStyle);

  const handleClick = () => {
    localStorageAsync.setItem("ms", !open);
    setOpen(!open);
  };

  return (
    <Grid container item className={open ? classes.menuFull : classes.menuMini}>
      <div style={{ position: "relative" }}>
        <Grid
          container
          item
          justify="flex-start"
          direction={"column"}
          style={{ position: "fixed", top: 100, width: 'max-content' }}
        >
          <IconButton
            className={open ? classes.arrows : classes.arrowsBack}
            edge="start"
            size="medium"
            style={{
              cursor: "pointer",

              width: 60,
              height: 60,
              marginBottom: 20,
            }}
            onClick={handleClick}
          >
            <DoubleArrowIcon />
          </IconButton>

          <Grid
            container
            item
            direction="row"
            className={classes.btnStyles}
            onClick={linkToRules}
          >
            <span
              style={{
                position: "relative",
                top: 4,
                paddingRight: 10,
              }}
            >
              <LightTooltip
                placement="right"
                enterDelay={200}
                leaveDelay={200}
                title={!open ? t("dashboard.btnNameConditions") : ""}
              >
                <SubjectIcon
                  color={
                    history.location.pathname === RULES_PATH
                      ? "primary"
                      : "inherit"
                  }
                />
              </LightTooltip>
            </span>

            <Slide
              direction={"right"}
              timeout={350}
              in={open}
              mountOnEnter
              unmountOnExit
            >
              <Grid>
                <Typography
                  className={classes.btnStyles}
                  // variant="text"
                  color={
                    history.location.pathname === RULES_PATH
                      ? "primary"
                      : "inherit"
                  }
                >
                  {t("dashboard.btnNameConditions")}
                </Typography>
              </Grid>
            </Slide>
          </Grid>

          <Grid
            container
            item
            direction="row"
            className={classes.btnStyles}
            onClick={linkToInstructions}
          >
            <span
              style={{
                position: "relative",
                top: 4,
                paddingRight: 10,
              }}
            >
              <LightTooltip
                placement="right"
                enterDelay={200}
                leaveDelay={200}
                title={!open ? t("btns.instruction") : ""}
              >
                <LocalLibraryIcon
                  color={
                    history.location.pathname === INSTRUCTION_PATH
                      ? "primary"
                      : "inherit"
                  }
                />
              </LightTooltip>
            </span>

            <Slide
              direction={"right"}
              timeout={450}
              in={open}
              mountOnEnter
              unmountOnExit
            >
              <Grid>
                <Typography
                  className={classes.btnStyles}
                  // variant="text"
                  color={
                    history.location.pathname === INSTRUCTION_PATH
                      ? "primary"
                      : "inherit"
                  }
                >
                  {t("btns.instruction")}
                </Typography>
              </Grid>
            </Slide>
          </Grid>

          <Grid
            container
            item
            direction="row"
            className={classes.btnStyles}
            onClick={linkToSearch}
          >
            <span
              style={{
                position: "relative",
                top: 4,
                paddingRight: 10,
              }}
            >
              <LightTooltip
                placement="right"
                enterDelay={200}
                leaveDelay={200}
                title={!open ? t("btns.search") : ""}
              >
                <SearchIcon
                  color={
                    history.location.pathname === SEARCH_PATH
                      ? "primary"
                      : "inherit"
                  }
                />
              </LightTooltip>
            </span>
            <Slide
              direction={"right"}
              timeout={550}
              in={open}
              mountOnEnter
              unmountOnExit
            >
              <Grid>
                <Typography
                  className={classes.btnStyles}
                  // variant="text"
                  color={
                    history.location.pathname === SEARCH_PATH
                      ? "primary"
                      : "inherit"
                  }
                >
                  {t("btns.search")}
                </Typography>
              </Grid>
            </Slide>
          </Grid>

          <Grid
            container
            item
            direction="row"
            className={classes.btnStyles}
            onClick={linkToSettings}
          >
            <span
              style={{
                position: "relative",
                top: 4,
                paddingRight: 10,
              }}
            >
              <LightTooltip
                placement="right"
                enterDelay={200}
                leaveDelay={200}
                title={!open ? t("profile.btnName") : ""}
              >
                <ContactMailIcon
                  color={
                    history.location.pathname === SETTINGS_PATH
                      ? "primary"
                      : "inherit"
                  }
                />
              </LightTooltip>
            </span>
            <Slide
              direction={"right"}
              timeout={650}
              in={open}
              mountOnEnter
              unmountOnExit
            >
              <Grid>
                <Typography
                  className={classes.btnStyles}
                  // variant="text"
                  color={
                    history.location.pathname === SETTINGS_PATH
                      ? "primary"
                      : "inherit"
                  }
                >
                  {t("profile.btnName")}
                </Typography>
              </Grid>
            </Slide>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}
