import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Grid,
  IconButton,
  Slide,
  Tooltip,
  Typography,
} from "@material-ui/core";

import history from "../../utils/history";
import {
  MAIN_PATH,
  LICENSES_PATH,
  USERS_PATH,
  SETTINGS_PATH,
  UNAUTHORIZED_PATH,
  linkToMain,
  linkToLicenses,
  linkToUsers,
  linkToUnauthorized,
  linkToSettings,
  REQUESTS_PATH,
  linkToRequests,
  linkToSearch,
  SEARCH_PATH,
} from "../Routes/var_PATHS";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import SubjectIcon from "@material-ui/icons/Subject";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import { localStorageAsync } from "../../utils/localStorageAsync";
import LockIcon from "@material-ui/icons/Lock";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import ReceiptIcon from "@material-ui/icons/Receipt";

import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    // backgroundColor: theme.palette.common.white,
    // color: "rgba(0, 0, 0, 0.87)",
    // boxShadow: theme.shadows[4],
    // borderRadius: 20,
    // fontSize: 15,
    position: "relative",
    left: -10,
    padding: "5px",
  },
}))(Tooltip);

const useStyles = makeStyles((theme: Theme) => ({
  btnStyles: {
    textTransform: "uppercase",
    cursor: "pointer",
    // padding: "20px 0px",
    fontSize: "16px",
    lineHeight: "40px",
    border: `0px solid `,
    // boxSizing: "border-box",
    // borderWidth: "0px 0px 2px 0px",
    // boxShadow: `0px 0px 4px ${theme.palette.primary.main}`,
    borderRadius: 0,
    transition: "0.2s ease-in-out",
    maxWidth: 'max-content',
    "&:hover": {
      color: theme.palette.primary.main,
      // backgroundColor: theme.palette.background.default,
    },
    "&:active": {
      color: theme.palette.primary.main,
      // backgroundColor: theme.palette.background.default,
    },
  },
  arrowsBack: {
    transitionDuration: "0.8s",
    transitionProperty: "transform",
    transform: "rotate(0deg)",
  },
  arrows: {
    transitionDuration: "0.8s",
    transitionProperty: "transform",
    transform: "rotate(180deg)",
  },
  menuFull: {
    transitionDuration: "0.3s",
    // transitionProperty: "transform",
    width: 200,
    marginRight: 50,
    transition: "width 0.2s",
  },
  menuMini: {
    transitionDuration: "0.3s",
    // transitionProperty: "transform",
    width: 80,
    marginRight: 30,
    transition: "width 0.2s",
  },
}));

export default function NavigationTabs() {
  const classes = useStyles();

  const { t } = useTranslation();

  const getMenuStyle = localStorageAsync.getItemSync("ms") === "true";

  const [open, setOpen] = useState(getMenuStyle);

  const handleClick = () => {
    localStorageAsync.setItem("ms", !open);
    setOpen(!open);
  };

  return (
    <Grid container item className={open ? classes.menuFull : classes.menuMini}>
      <div style={{ position: "relative" }}>
        <Grid
          container
          item
          justify="flex-start"
          direction={"column"}
          style={{ position: "fixed", top: 100, width: 'max-content' }}
        >
          <IconButton
            className={open ? classes.arrows : classes.arrowsBack}
            edge="start"
            size="medium"
            // color="primary"
            style={{
              cursor: "pointer",
              // position: "fixed",
              // bottom: 30,
              // left: 30,
              // width: 50,
              // height: 50,
              // backgroundColor: "red",
              // zIndex: 9999,
              width: 60,
              height: 60,
              marginBottom: 20,
            }}
            onClick={handleClick}
          >
            <DoubleArrowIcon />
          </IconButton>

          <Grid
            container
            item
            direction="row"
            className={classes.btnStyles}
            onClick={linkToMain}
          >
            <span
              style={{
                position: "relative",
                top: 4,
                paddingRight: 10,
              }}
            >
              <LightTooltip
                placement="right"
                enterDelay={200}
                leaveDelay={200}
                title={!open ? t("dashboard.btnName") : ""}
              >
                <SubjectIcon
                  color={
                    history.location.pathname === MAIN_PATH
                      ? "primary"
                      : "inherit"
                  }
                />
              </LightTooltip>
            </span>

            <Slide
              direction={"right"}
              timeout={450}
              in={open}
              mountOnEnter
              unmountOnExit
            >
              <Grid>
                <Typography
                  className={classes.btnStyles}
                  // variant="text"
                  color={
                    history.location.pathname === MAIN_PATH
                      ? "primary"
                      : "inherit"
                  }
                >
                  {t("dashboard.btnName")}
                </Typography>
              </Grid>
            </Slide>
          </Grid>

          <Grid
            container
            item
            direction="row"
            className={classes.btnStyles}
            onClick={linkToLicenses}
          >
            <span
              style={{
                position: "relative",
                top: 4,
                paddingRight: 10,
              }}
            >
              <LightTooltip
                placement="right"
                enterDelay={200}
                leaveDelay={200}
                title={!open ? t("licences.btnName") : ""}
              >
                <ReceiptIcon
                  color={
                    history.location.pathname === LICENSES_PATH
                      ? "primary"
                      : "inherit"
                  }
                />
              </LightTooltip>
            </span>

            <Slide
              direction={"right"}
              timeout={500}
              in={open}
              mountOnEnter
              unmountOnExit
            >
              <Grid>
                <Typography
                  className={classes.btnStyles}
                  // variant="text"
                  color={
                    history.location.pathname === LICENSES_PATH
                      ? "primary"
                      : "inherit"
                  }
                >
                  {t("licences.btnName")}
                </Typography>
              </Grid>
            </Slide>
          </Grid>

          <Grid
            container
            item
            direction="row"
            className={classes.btnStyles}
            onClick={linkToUsers}
          >
            <span
              style={{
                position: "relative",
                top: 4,
                paddingRight: 10,
              }}
            >
              <LightTooltip
                placement="right"
                enterDelay={200}
                leaveDelay={200}
                title={!open ? t("users.btnName") : ""}
              >
                <PeopleAltIcon
                  color={
                    history.location.pathname === USERS_PATH
                      ? "primary"
                      : "inherit"
                  }
                />
              </LightTooltip>
            </span>

            <Slide
              direction={"right"}
              timeout={550}
              in={open}
              mountOnEnter
              unmountOnExit
            >
              <Grid>
                <Typography
                  className={classes.btnStyles}
                  // variant="text"
                  color={
                    history.location.pathname === USERS_PATH
                      ? "primary"
                      : "inherit"
                  }
                >
                  {t("users.btnName")}
                </Typography>
              </Grid>
            </Slide>
          </Grid>

          <Grid
            container
            item
            direction="row"
            className={classes.btnStyles}
            onClick={linkToSearch}
          >
            <span
              style={{
                position: "relative",
                top: 4,
                paddingRight: 10,
              }}
            >
              <LightTooltip
                placement="right"
                enterDelay={200}
                leaveDelay={200}
                title={!open ? t("btns.search") : ""}
              >
                <SearchIcon
                  color={
                    history.location.pathname === SEARCH_PATH
                      ? "primary"
                      : "inherit"
                  }
                />
              </LightTooltip>
            </span>

            <Slide
              direction={"right"}
              timeout={600}
              in={open}
              mountOnEnter
              unmountOnExit
            >
              <Grid>
                <Typography
                  className={classes.btnStyles}
                  // variant="text"
                  color={
                    history.location.pathname === SEARCH_PATH
                      ? "primary"
                      : "inherit"
                  }
                >
                  {t("btns.search")}
                </Typography>
              </Grid>
            </Slide>
          </Grid>

          {/* <Typography
            className={classes.btnStyles}
            // variant="text"
            color={
              history.location.pathname === ENTITYS_PATH ? "primary" : "inherit"
            }
            onClick={linkToEntitys}
          >
            {t("le.btnName")}
          </Typography>

          <Typography
            className={classes.btnStyles}
            // variant="text"
            color={
              history.location.pathname === PRIVATE_ENTERPRISE_PATH
                ? "primary"
                : "inherit"
            }
            onClick={linkToEnterprises}
          >
            {t("pe.btnName")}
          </Typography>

          <Typography
            className={classes.btnStyles}
            // variant="text"
            color={
              history.location.pathname === OBJECTS_PATH ? "primary" : "inherit"
            }
            onClick={linkToObjects}
          >
            {t("addresses.btnName")}
          </Typography>

          <Typography
            className={classes.btnStyles}
            // variant="text"
            color={
              history.location.pathname === STAMPS_PATH ? "primary" : "inherit"
            }
            onClick={linkToStamps}
          >
            {t("stamps.btnName")}
          </Typography> */}

          <Grid
            container
            item
            direction="row"
            className={classes.btnStyles}
            onClick={linkToRequests}
          >
            <span
              style={{
                position: "relative",
                top: 4,
                paddingRight: 10,
              }}
            >
              <LightTooltip
                placement="right"
                enterDelay={200}
                leaveDelay={200}
                title={!open ? t("requests.btnName") : ""}
              >
                <PlaylistAddIcon
                  color={
                    history.location.pathname === REQUESTS_PATH
                      ? "primary"
                      : "inherit"
                  }
                />
              </LightTooltip>
            </span>

            <Slide
              direction={"right"}
              timeout={650}
              in={open}
              mountOnEnter
              unmountOnExit
            >
              <Grid>
                <Typography
                  className={classes.btnStyles}
                  // variant="text"
                  color={
                    history.location.pathname === REQUESTS_PATH
                      ? "primary"
                      : "inherit"
                  }
                >
                  {t("requests.btnName")}
                </Typography>
              </Grid>
            </Slide>
          </Grid>

          <Grid
            container
            item
            direction="row"
            className={classes.btnStyles}
            onClick={linkToUnauthorized}
          >
            <span
              style={{
                position: "relative",
                top: 4,
                paddingRight: 10,
              }}
            >
              <LightTooltip
                placement="right"
                enterDelay={200}
                leaveDelay={200}
                title={!open ? t("unauthorized.btnName") : ""}
              >
                <LockIcon
                  color={
                    history.location.pathname === UNAUTHORIZED_PATH
                      ? "primary"
                      : "inherit"
                  }
                />
              </LightTooltip>
            </span>

            <Slide
              direction={"right"}
              timeout={700}
              in={open}
              mountOnEnter
              unmountOnExit
            >
              <Grid>
                <Typography
                  className={classes.btnStyles}
                  // variant="text"
                  color={
                    history.location.pathname === UNAUTHORIZED_PATH
                      ? "primary"
                      : "inherit"
                  }
                >
                  {t("unauthorized.btnName")}
                </Typography>
              </Grid>
            </Slide>
          </Grid>

          <Grid
            container
            item
            direction="row"
            className={classes.btnStyles}
            onClick={linkToSettings}
          >
            <span
              style={{
                position: "relative",
                top: 4,
                paddingRight: 10,
              }}
            >
              <LightTooltip
                placement="right"
                enterDelay={200}
                leaveDelay={200}
                title={!open ? t("profile.btnName") : ""}
              >
                <ContactMailIcon
                  color={
                    history.location.pathname === SETTINGS_PATH
                      ? "primary"
                      : "inherit"
                  }
                />
              </LightTooltip>
            </span>

            <Slide
              direction={"right"}
              timeout={750}
              in={open}
              mountOnEnter
              unmountOnExit
            >
              <Grid>
                <Typography
                  className={classes.btnStyles}
                  // variant="text"
                  color={
                    history.location.pathname === SETTINGS_PATH
                      ? "primary"
                      : "inherit"
                  }
                >
                  {t("profile.btnName")}
                </Typography>
              </Grid>
            </Slide>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}
