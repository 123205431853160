import loglevel from "loglevel";
import { config } from "./config";

export default class Logger {
  loggerName: string;
  constructor(logerName: string) {
    this.loggerName = logerName;
    loglevel.setLevel(config.logLevel, false);
    if (config.disableAllLogs) {
      loglevel.disableAll(false);
    }
  }

  trace(...msg: any[]) {
    loglevel.trace(`${this.loggerName}:`, ...msg);
  }

  debug(...msg: any[]) {
    loglevel.debug(`${this.loggerName}:`, ...msg);
  }

  info(...msg: any[]) {
    loglevel.info(`${this.loggerName}:`, ...msg);
  }

  //info alias
  log(...msg: any[]) {
    loglevel.info(`${this.loggerName}:`, ...msg);
  }

  warn(...msg: any[]) {
    loglevel.warn(`${this.loggerName}:`, ...msg);
  }

  error(...msg: any[]) {
    loglevel.error(`${this.loggerName}:`, ...msg);
  }
}
