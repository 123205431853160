export class ResponseValidationError extends Error {}

export class HttpTimeoutError extends Error {}

export class UnknownEvent extends Error {
  id: number;
  constructor(id: number) {
    super();
    this.id = id;
  }
}

export class NpuError extends Error {
  httpStatusCode: number;
  errorCode: number;

  constructor(httpStatusCode: number, errorCode: number, errorMessage: string) {
    super(errorMessage);
    this.httpStatusCode = httpStatusCode;
    this.errorCode = errorCode;
  }
}

export class InvalidRequestModelError extends Error {
  httpStatusCode: number;
  errorCode: number;

  constructor(httpStatusCode: number, errorCode: number, errorMessage: string) {
    super(errorMessage);
    this.httpStatusCode = httpStatusCode;
    this.errorCode = errorCode;
  }
}
