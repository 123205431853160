import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Popover,
  Typography,
  Button,
  ListItem,
  List,
  Grid,
  IconButton,
} from "@material-ui/core";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import { userService } from "../../services/userService";
import { withSnackbar, useSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnStyles: {
      minHeight: 48,
      border: `2px solid ${theme.palette.error.main}`,
      boxSizing: "border-box",
      boxShadow: `0px 0px 4px ${theme.palette.error.main}`,
      // backgroundColor: `${theme.palette.error.main}20`,
      borderRadius: 25,
      transition: "0.2s ease-in-out",
      "&:hover": {
        boxShadow: `0px 0px 10px ${theme.palette.error.main}`,
      },
    },

    btnStylesWithOutline: {
      minHeight: 48,
      border: `2px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.background.paper,

      borderRadius: 25,
      boxSizing: "border-box",
      boxShadow: `0px 0px 2px ${theme.palette.primary.main}`,
      transition: "0.2s ease-in-out",
      "&:hover": {
        backgroundColor: theme.palette.background.paper,
        boxShadow: `0px 0px 10px ${theme.palette.primary.main}`,
      },
    },
  })
);

function UserBtn(props: any) {
  const classes = useStyles();

  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const cancelAction = () => {
    return (
      <IconButton onClick={() => closeSnackbar()}>
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleConfirm = () => {
    try {
      userService.makeUserAsUser(props.hashId);
      handleClose();
    } catch (err) {
      enqueueSnackbar(err.toString(), {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        variant: "error",
        action: cancelAction,
      });
    }
  };

  return (
    <div>
      <Button
        className={classes.btnStylesWithOutline}
        // className={classes.btnStylesWithOutline}
        style={{ minWidth: 220, margin: "10px 0px" }}
        // variant="contained"
        // color="secondary"
        onClick={handleClick}
        startIcon={<PersonOutlineIcon />}

        // onClick={() => props.handleConfirmLicence(props.hashId)}
      >
        <Typography color="textPrimary"> {t("users.user")}</Typography>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List>
          <ListItem>
            <Grid container justify="center">
              <Typography variant="caption" align="center" color="error">
                {t("btns.asUserQuestion")}
              </Typography>
            </Grid>
          </ListItem>
          <Grid container direction="row" justify="space-around">
            <Button
              className={classes.btnStyles}
              size="small"
              variant="text"
              onClick={handleConfirm}
            >
              {t("btns.yes")}
            </Button>

            <Button
              className={classes.btnStyles}
              size="small"
              variant="text"
              onClick={handleClose}
            >
              {t("btns.no")}
            </Button>
          </Grid>
        </List>
      </Popover>
    </div>
  );
}
export default withSnackbar(UserBtn);
