import { Slide, IconButton, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { dataService } from "../../services/dataService";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

const ToTopBtn = (props: any) => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setShowScroll(false);
  };

  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 2000) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 2000) {
      setShowScroll(false);
    }
  };

  const scrollBtnOn = () => window.addEventListener("scroll", checkScrollTop);
  const scrollBtnOff = () =>
    window.removeEventListener("scroll", checkScrollTop);
  useEffect(() => {
    scrollBtnOn();

    return () => {
      scrollBtnOff();
    };
  });

  const precentegCounter = () =>
    props.arrLength
      ? Math.ceil((props.arrLength * 100) / props.paginationData.totalCount)
      : 0;

  return (
    <Slide
      direction="up"
      timeout={450}
      in={showScroll}
      mountOnEnter
      unmountOnExit
    >
      <IconButton
        edge="start"
        size="medium"
        color="primary"
        style={{
          cursor: "pointer",
          position: "fixed",
          bottom: 30,
          left: 30,
          // width: 50,
          // height: 50,
          // backgroundColor: "red",
          zIndex: 9999,
          width: "arrLength" in props && "paginationData" in props ? 91 : 75,
          height: "arrLength" in props && "paginationData" in props ? 91 : 75,
        }}
        onClick={handleClick}
      >
        <Grid
          container
          item
          direction="column"
          alignContent="center"
          alignItems="center"
        >
          <ArrowUpwardIcon fontSize="large" color="primary" />
          <Typography color="primary" variant="h6">
            {precentegCounter()}%
          </Typography>
        </Grid>
      </IconButton>
    </Slide>
  );
};

export default ToTopBtn;
