import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  makeStyles,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
// import { useTranslation } from "react-i18next";
import NavigationTabs from "../NavigationTabs/NavigationTabs";
import SearchField from "../Search/SearchField";
import LicenseItem from "./LicenseItem";
import { licenceService } from "../../services/licenceService";
import CircularSpinner from "../spinners/circular";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar, withSnackbar } from "notistack";
import InfiniteScroll from "react-infinite-scroll-component";
import AddLicense from "./AddLicense";
import SearchBtn from "../Search/SearchBtn";
import ToTopBtn from "../ButtonsWithConfirmation/ToTopBtn";
import SearchInfo from "../Search/SearchInfo";
import { debounce } from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  spinner: {
    paddingTop: 110,
  },
  infScroll: {
    width: "100%",
    // position: "absolute",
    // height: "max-content !important",
    overflow: "visible !important",
  },
  user: {
    margin: "45px auto",
    // width: "90%",
    minWidth: 375,
  },
  admin: {},
}));

const LicensesPage = () => {
  // const theme = useTheme();
  const matches890 = useMediaQuery("(min-width:890px)");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const cancelAction = () => {
    return (
      <IconButton onClick={ () => closeSnackbar() }>
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  };

  const classes = useStyles();
  // const { t } = useTranslation();

  const [searchVal, setSearch] = useState({ field: "" });

  const getSearch = (e: any) => {
    const searchText = e.trim();

    setSearch((searchVal: any) => ({
      ...searchVal,
      field: searchText,
    }));
    delayedQuery(searchText);
  };

  const [isLoaded, setIsLoaded] = useState(false);

  const [licenses, setLicences] = useState([
    {
      contacts: "",
      createdAt: 0,
      fio: "",
      hashId: "",
      licence: 0,
      modifiedAt: 0,
      isInGovUaDb: false,
      name: "",
      region: { id: 0, name: "" },
      service: { id: 0, name: "" },
      user: {
        authProvider: { id: 1, name: "google" },
        createdAt: 0,
        email: "",
        firstName: "",
        hashId: "",
        id: 0,
        isDeleted: false,
        lastName: "",
        modifiedAt: 0,
        pictureUrl: "",
        role: { id: 1, name: "" },
        service: { id: 0, name: "" },
        status: { id: 1, name: "" },
        theme: { id: 1, name: "dark" },
      },
    },
  ]);

  const [paginationData, setPaginationData] = useState({
    pageNumber: Number(),
    pageSize: Number(),
    totalCount: Number(),
  });

  const delayedQuery = useCallback(
    debounce((text: any) => {
      onSearch(text);
    }, 300),
    []
  );

  const onSearch = (text: any) => {
    try {
      setIsLoaded(true);
      const filterData = {
        pageNumber: 1,
        searchTerm: text,
        // pageSize: paginationData.pageSize || 20,
        // licence: 0,
      };

      licenceService
        .searchLicence(filterData)
        .then((data: any) => {
          setLicences(data.data);
          return data;
        })
        .then((data: any) =>
          setPaginationData((oldState: any) => ({
            ...oldState,
            pageNumber: filterData.pageNumber + 1,
            totalCount: data.totalCount,
            pageSize: data.pageSize,
          }))
        )
        .then(() => setIsLoaded(false));
    } catch (err) {
      enqueueSnackbar(err.toString(), {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        variant: "error",
        action: cancelAction,
      });
    }
  };

  useEffect(() => {
    setIsLoaded(true);
    const filter = {
      pageNumber: paginationData.pageNumber || 1,
      pageSize: paginationData.pageSize || 20,
      // licence: 0,
      searchTerm: searchVal.field,
      // searchTerm: "ба",
    };

    licenceService
      .searchLicence(filter)
      .then((data: any) => {
        setLicences(data.data);
        // console.log("searchLicence", data);
        return data;
      })
      .then((data: any) =>
        setPaginationData((oldState: any) => ({
          ...oldState,
          pageNumber: filter.pageNumber + 1,
          totalCount: data.totalCount,
          pageSize: data.pageSize,
        }))
      )
      .then(() => setIsLoaded(false));
  }, []);

  const getUpdateLicences = () => {
    setIsLoaded(true);
    const filter = {
      pageNumber: 1,
      pageSize: paginationData.pageSize || 20,
      // licence: 0,
      searchTerm: searchVal.field,
      // searchTerm: "ба",
    };

    licenceService
      .searchLicence(filter)
      .then((data: any) => {
        setLicences(data.data);
        // console.log("searchLicence", data);
        return data;
      })
      .then((data: any) =>
        setPaginationData((oldState: any) => ({
          ...oldState,
          pageNumber: filter.pageNumber + 1,
          totalCount: data.totalCount,
          pageSize: data.pageSize,
        }))
      )
      .then(() => setIsLoaded(false));
  };

  const fetchMoreData = () => {
    if (paginationData.totalCount > licenses.length) {
      try {
        const filterData = {
          pageNumber: paginationData.pageNumber,
          pageSize: paginationData.pageSize,
          // licence: 0,
          searchTerm: searchVal.field,
        };

        licenceService
          .searchLicence(filterData)
          .then((data: any) => {
            setLicences((oldLicences: any) =>
              [...oldLicences].concat(data.data)
            );

            return data;
          })
          .then((data: any) =>
            setPaginationData((oldState: any) => ({
              ...oldState,
              pageNumber: filterData.pageNumber + 1,
              totalCount: data.totalCount,
              pageSize: data.pageSize,
            }))
          );
      } catch (err) {
        enqueueSnackbar(err.toString(), {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          variant: "error",
          action: cancelAction,
        });
      }
    }
  };

  const [isOpenObject, setIsOpenObject] = useState({ id: "", isOpen: false });

  const getOpenObject = (hashId: string) =>
    setIsOpenObject({ id: hashId, isOpen: true });

  return (
    <Grid
      container
      item
      xs={ 12 }
      justify={ "space-between" }
      // justify={isAdmin ? "space-between" : "flex-start"}
      direction={ "row" }
      wrap="nowrap"
    >
      <Grid item style={ { display: matches890 ? "" : "none" } }>
        <NavigationTabs />
      </Grid>

      {/* {isLoaded ? ( */ }
      <Grid container item xs={ 12 } className={ classes.user }>
        <Grid container item xs={ 12 } wrap="nowrap">
          <Grid item style={ { width: "100%" } }>
            <SearchField
              name={ "licence" }
              getSearch={ getSearch }
            // onSearch={onSearch}
            />
          </Grid>
          {/* <Grid item>
              <SearchBtn onSearch={onSearch} />
            </Grid> */}

          <Grid item>
            <AddLicense getUpdateLicences={ getUpdateLicences } />
          </Grid>
        </Grid>

        <Grid container item xs={ 12 }>
          <SearchInfo paginationData={ paginationData } isLoading={ !isLoaded } />
        </Grid>

        <Grid item xs={ 12 } sm={ 12 } md={ 12 }>
          <div style={ { width: "100%" } }>
            <InfiniteScroll
              dataLength={ licenses.length }
              next={ fetchMoreData }
              hasMore={
                licenses.length < paginationData.totalCount ? true : false
              }
              loader={
                <Grid
                  container
                  item
                  xs={ 12 }
                  justify="center"
                  style={ { marginTop: 50 } }
                >
                  <CircularSpinner forFetch={ true } />
                </Grid>
              }
              // height={"100%"}
              scrollThreshold="300px"
              className={ classes.infScroll }
              endMessage={
                licenses.length === paginationData.totalCount && (
                  <p style={ { textAlign: "center" } }>
                    {/* <b>ви переглянули всі ліцензії! </b> */ }
                    <span style={ { fontSize: 40 } }> 🤷‍♂️ </span>
                  </p>
                )
              }
            >
              { licenses.map((item: any) => (
                <Grid key={ item.hashId } container item xs={ 12 }>
                  { item.hashId ? (
                    <LicenseItem
                      getUpdateLicences={ getUpdateLicences }
                      license={ item }
                      getOpen={ getOpenObject }
                      isOpen={ isOpenObject }
                    />
                  ) : (
                    <Grid
                      container
                      item
                      xs={ 12 }
                      justify="center"
                      className={ classes.spinner }
                    >
                      <CircularSpinner />
                    </Grid>
                  ) }
                </Grid>
              )) }
            </InfiniteScroll>
          </div>
        </Grid>

        <ToTopBtn paginationData={ paginationData } arrLength={ licenses.length } />
      </Grid>
      {/* ) : (
        <Grid
          container
          item
          xs={12}
          justify="center"
          className={classes.spinner}
        >
          <CircularSpinner />
        </Grid>
      )} */}
    </Grid>
  );
};

export default withSnackbar(LicensesPage);
