import React, { useState, useEffect } from "react";
import { CircularProgress, Grid } from "@material-ui/core";

export default function CircularSpinner(props: any) {
  const [view, setView] = useState(false);

  const spinnerWithTimeOut = (view: any) => {
    if (view) {
      return (
        <Grid container justify="center">
          {props.forFetch ? (
            <CircularProgress
              size={55}
              thickness={4}
              color={"primary"}
              variant="indeterminate"
              disableShrink={true}
              // value={99}
            />
          ) : (
            <CircularProgress size={55} thickness={4} color={"primary"} />
          )}
        </Grid>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    let timer = setTimeout(() => {}, 0);

    if (props.forFetch) {
      setView(true);
    } else {
      timer = setTimeout(() => {
        setView(true);
      }, 300);
    }

    return () => clearTimeout(timer);
  }, [view]);

  return <>{spinnerWithTimeOut(view)}</>;
}
