import React, { useState } from "react";
// import { Redirect } from "react-router-dom";
// import queryString, { ParsedQuery } from "query-string";
import {
  makeStyles,
  Theme,
  Slide,
  ListItem,
  List,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  TextField,
  useTheme,
} from "@material-ui/core";

import ReCAPTCHA from "react-google-recaptcha";
import { NpuError, HttpTimeoutError } from "../../models/error.model";
import { LoginResponse } from "../../models/login.model";
import { authService } from "../../services/authService";
import Logger from "../../utils/logger";
import history from "../../utils/history";
import { withSnackbar } from "notistack";

import { LOGIN_PATH, MAINTENANCE_PATH } from "../Routes/var_PATHS";

import LoginButton from "./LoginButtons/LoginBtn";

import CircularSpinner from "../spinners/circular";
import npuImage from "../../img/logo.png";
import { useTranslation } from "react-i18next";
import CancelIcon from "@material-ui/icons/Cancel";
import SendIcon from "@material-ui/icons/Send";

const useStyles = makeStyles((theme: Theme) => ({
  spinner: {
    paddingTop: 320,
  },
  logoBlock: {
    marginTop: 150,
    textDecorationColor: theme.palette.text.primary,
  },
  logoBlockMatchMedia: {
    marginTop: 60,
    textDecorationColor: theme.palette.text.primary,
  },
  btnBlock: {
    marginTop: 100,
  },
  btnBlockMatchMedia: {
    marginTop: 30,
  },
  positionBlock: {
    marginBottom: 30,
    fontWeight: 700,

    letterSpacing: 5,
    textDecorationColor: theme.palette.primary.main,
    textShadow: `0px 0px 3px ${theme.palette.primary.dark}`,
  },
  btnStylesWithOutline: {
    marginBottom: 20,
    height: 90,
    border: `1px solid ${theme.palette.grey[500]}`,
    // backgroundColor: `${theme.palette.grey[400]}05`,

    boxSizing: "border-box",
    boxShadow: `0px 0px 2px ${theme.palette.grey[400]}80`,
    borderRadius: 50,
    transition: "0.2s ease-in-out",
    "&:hover": {
      boxShadow: `0px 0px 6px ${theme.palette.primary.main}`,
      border: `1px solid ${theme.palette.primary.light}40`,
      backgroundColor: `${theme.palette.grey[400]}08`,

      // backgroundColor: `${theme.palette.primary.main}35`,
      // backgroundColor: `${theme.palette.background.default}`
    },
  },
  backDrop: {
    backdropFilter: "blur(7px)",
    backgroundColor: `${theme.palette.grey[100]}30`,
  },
}));

const Login = () => {
  const theme = useTheme();

  const initialState = {
    url: LOGIN_PATH,
    redirect: true,
    isClick: false,
  };

  const classes = useStyles();

  const [state, setState] = useState(initialState);

  const ERROR_CODE_PARAM = "errorCode";
  const logger = new Logger("LOGIN Component");

  const redirectToAuthProvider = () => {
    authService
      .getLoginUrl()
      .then((resp: LoginResponse) => {
        // history.push(resp.url);
        // console.log("resp:", resp);
        window.location.replace(resp.url);
        setState({
          ...state,
          isClick: true,
        });
      })
      // .then(() => localStorageAsync.setItem("gbR", true))
      .catch((error) => {
        // console.log("error:", error);

        if (error instanceof HttpTimeoutError) {
          history.push(MAINTENANCE_PATH);
        } else if (error instanceof NpuError) {
          const npuError = error as NpuError;
          // console.log("errorMessage", npuError);
          setState({
            ...state,
            redirect: true,
            url: `${LOGIN_PATH}?${ERROR_CODE_PARAM}=${npuError.errorCode}`,
          });
        } else {
          logger.error(error);
          throw error;
        }
      });
  };

  // const renderError = () => {
  //   const queryErrorCode: ParsedQuery = queryString.parse(
  //     history.location.search
  //   );
  //   if (queryErrorCode[ERROR_CODE_PARAM]) {
  //     //todo: timeout 3sec
  //     return <span style={{ color: "red" }}> ERROR!!!</span>;
  //   }
  // };

  // const renderRedirect = () => {
  //   if (state.redirect) {
  //     logger.log("redirectToLogin");
  //     return <Redirect to={state.url} />;
  //   }
  // };

  //// MODAL ////
  //// MODAL ////
  //// MODAL ////

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const requestState = {
    serviceId: 0,
    regionId: 0,
    name: "",
    email: "",
    licenceNumber: 0,
    registrationNumber: "",
    address: "",
    phoneNumber: "",
  };

  const [newObject, setNewObject] = useState(requestState);
  const [errorEmail, setErrorEmail] = useState("");

  const handleChange = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "pib") {
      const pib = value;
      setNewObject((oldVals: any) => ({ ...oldVals, name: pib }));
    }
    if (name === "licence") {
      const licenceNumber = Number(value.trim().replace(/\D/gim, ""));
      setNewObject((oldVals: any) => ({
        ...oldVals,
        licenceNumber: licenceNumber,
      }));
    }
    if (name === "regNumber") {
      const regNum = Number(value.trim().replace(/\D/gim, ""));

      setNewObject((oldVals: any) => ({
        ...oldVals,
        registrationNumber: regNum,
      }));
    }
    if (name === "address") {
      const addressValue = value;
      setNewObject((oldVals: any) => ({ ...oldVals, address: addressValue }));
    }
    if (name === "phone") {
      const phoneNumber = value.trim().replace(/\D/gim, "");
      setNewObject((oldVals: any) => ({
        ...oldVals,
        phoneNumber: phoneNumber,
      }));
    }
    if (name === "email") {
      const email = value.toLowerCase();

      const prohibitedEmails_UkrNet = "ukr.net";
      const prohibitedEmails_iUa = "i.ua";
      const prohibitedEmails_metaUa = "meta.ua";
      const prohibitedEmails_bigmirNet = "bigmir.net";

      if (value.indexOf(prohibitedEmails_UkrNet) !== -1) {
        setErrorEmail("Cистема працює лише з обліковими записами Google");
      } else if (value.indexOf(prohibitedEmails_iUa) !== -1) {
        setErrorEmail("Cистема працює лише з обліковими записами Google");
      } else if (value.indexOf(prohibitedEmails_metaUa) !== -1) {
        setErrorEmail("Cистема працює лише з обліковими записами Google");
      } else if (value.indexOf(prohibitedEmails_bigmirNet) !== -1) {
        setErrorEmail("Cистема працює лише з обліковими записами Google");
      } else {
        setErrorEmail("");
      }

      setNewObject((oldVals: any) => ({
        ...oldVals,
        email: email,
      }));
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    // console.log("open : ", open);
  };

  const handleClose = () => {
    setIsReCaptcha(false);
    setOpen(false);
  };

  const sendNewObject = (newObject: any) => {
    const reqVals = Object.assign({}, newObject);

    if (reqVals.registrationNumber) {
      authService.signUp(newObject).then(() => handleClose());
      setNewObject(requestState);
    } else {
      delete reqVals.registrationNumber;

      authService.signUp(reqVals).then(() => handleClose());
      setNewObject(requestState);
    }
  };

  //// MODAL ////
  //// MODAL ////
  //// MODAL ////

  const [isReCaptcha, setIsReCaptcha] = useState(false);

  const handleReCaptcha = (captcha: any) => {
    const req = {
      response: captcha,
    };
    authService.reCaptchaCheck(req).then(() => {
      captcha ? setIsReCaptcha(true) : setIsReCaptcha(false);
    });
  };

  return (
    <div>
      {state.isClick ? (
        <Grid container justify="center" className={classes.spinner}>
          <CircularSpinner />
        </Grid>
      ) : (
        <>
          {/* {renderRedirect()} */}
          {/* <HeaderUnlogged /> */}
          {/* {renderError()} */}

          <Slide direction="down" timeout={650} in mountOnEnter unmountOnExit>
            <Grid container justify="center" style={{ marginTop: 90 }}>
              <Grid
                container
                item
                xs={12}
                className={classes.positionBlock}
                justify="center"
              >
                <Typography
                  variant="h4"
                  gutterBottom
                  color="primary"
                  align="center"
                >
                  {/* {t("login.hr").toUpperCase()} */}
                  Відділення Нотаріальної Палати України
                </Typography>
                <Grid container item justify="center" style={{ marginTop: 20 }}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="primary"
                    align="center"
                  >
                    {/* {t("login.hr").toUpperCase()} */}
                    Київ
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} justify="center">
                <Typography variant="h6" gutterBottom color="textSecondary">
                  {/* {t("login.title").toUpperCase()} */}

                  <img src={npuImage} title="НПУ" alt="НПУ" />
                </Typography>
              </Grid>
            </Grid>
          </Slide>

          <>
            <Slide direction="up" timeout={450} in mountOnEnter unmountOnExit>
              <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
                item
                xs={12}
                sm={12}
              >
                <List>
                  <ListItem>
                    <LoginButton loginFunc={redirectToAuthProvider} />
                  </ListItem>
                </List>
              </Grid>
            </Slide>

            <Slide direction="up" timeout={650} in mountOnEnter unmountOnExit>
              <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
                item
                xs={12}
                sm={12}
              >
                <List>
                  <ListItem>
                    <div>
                      <Grow in>
                        <div>
                          <Button
                            // disabled={!isReCaptcha}
                            onClick={handleClickOpen}
                            variant="outlined"
                            className={classes.btnStylesWithOutline}
                            style={{
                              width: 320,
                            }}
                          >
                            <Typography
                              variant="h6"
                              color="textSecondary"
                              className={classes.btnText}
                            >
                              {t("login.requestLogin")}
                            </Typography>
                          </Button>
                        </div>
                      </Grow>

                      <Grid container item xs={12} justify="center">
                        <Dialog
                          BackdropProps={{
                            classes: {
                              root: classes.backDrop,
                            },
                            timeout: 400,
                          }}
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="form-dialog-title"
                        >
                          {/* <DialogTitle id="form-dialog-title">{t("help.label")}</DialogTitle> */}
                          <DialogTitle
                            id="form-dialog-title"
                            style={{
                              backgroundColor: theme.palette.grey[900],
                            }}
                          >
                            {t("login.requestTitle")}
                          </DialogTitle>
                          <DialogContent
                            dividers={true}
                            style={{
                              backgroundColor: theme.palette.grey[900],
                            }}
                            id="scroll-dialog-description"
                            tabIndex={-1}
                          >
                            {/* <DialogContentText>{t("betaTest.titleFeedback")}</DialogContentText> */}
                            <Grid
                              container
                              item
                              xs={12}
                              style={{ width: "850px" }}
                            >
                              <TextField
                                required
                                error={newObject.name.length < 2}
                                label={t("addresses.pib")}
                                name="pib"
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                // multiline
                                rows={1}
                                // rowsMax="3"
                                value={newObject.name}
                                onChange={(e: any) => handleChange(e)}

                                // inputProps={{ disabled: true }}
                              />
                              <TextField
                                required
                                error={newObject.licenceNumber < 1}
                                label={t("licences.licenceN")}
                                name="licence"
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                // multiline
                                rows={1}
                                // rowsMax="5"
                                value={newObject.licenceNumber}
                                onChange={(e: any) => handleChange(e)}

                                // inputProps={{ disabled: true }}
                              />

                              <TextField
                                required
                                error={
                                  newObject.email.length < 2 || !!errorEmail
                                }
                                label="email"
                                name="email"
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                // multiline
                                rows={1}
                                // rowsMax="10"
                                placeholder="працює лиише з обліковими записами Google"
                                value={newObject.email}
                                onChange={(e: any) => handleChange(e)}

                                // inputProps={{ disabled: true }}
                              />
                              {!!errorEmail && (
                                <Grid container item xs justify="flex-end">
                                  <Typography
                                    color="error"
                                    align="right"
                                    variant="caption"
                                  >
                                    {errorEmail}
                                  </Typography>
                                </Grid>
                              )}
                              <TextField
                                required
                                error={newObject.address.length < 2}
                                label={t("addresses.address")}
                                name="address"
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                multiline
                                rows={3}
                                rowsMax="10"
                                value={newObject.address}
                                onChange={(e: any) => handleChange(e)}

                                // inputProps={{ disabled: true }}
                              />
                              <TextField
                                label={t("login.phoneNumber")}
                                name="phone"
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                // multiline
                                rows={1}
                                // rowsMax="10"
                                value={newObject.phoneNumber}
                                onChange={(e: any) => handleChange(e)}

                                // inputProps={{ disabled: true }}
                              />

                              <TextField
                                // required
                                // error={newObject.registrationNumber < 1}
                                label={t("login.regNumber")}
                                name="regNumber"
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                // multiline
                                rows={1}
                                // rowsMax="10"
                                value={newObject.registrationNumber}
                                onChange={(e: any) => handleChange(e)}

                                // inputProps={{ disabled: true }}
                              />

                              <Grid
                                container
                                item
                                xs={12}
                                justify="flex-end"
                                style={{ marginTop: 10 }}
                              >
                                <ReCAPTCHA
                                  sitekey="6LcQiUUaAAAAAGXDcrTEssTt10sH3TRIotH5EK2K"
                                  hl="uk"
                                  theme="dark"
                                  // size="invisible"
                                  onChange={(captcha: any) => {
                                    handleReCaptcha(captcha);
                                  }}
                                  onExpired={() => {
                                    setIsReCaptcha(false);
                                    console.log("onExpired");
                                  }}
                                  onErrored={() => {
                                    setIsReCaptcha(false);
                                    console.log("onErrored");
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </DialogContent>
                          <DialogActions
                            style={{
                              backgroundColor: theme.palette.grey[900],
                            }}
                          >
                            <Grid
                              container
                              item
                              xs={12}
                              justify="space-between"
                            >
                              <Grid>
                                <Button
                                  variant="contained"
                                  onClick={handleClose}
                                  color="primary"
                                  startIcon={<CancelIcon />}
                                  style={{ minWidth: 180, margin: "5px 0px" }}
                                >
                                  <Typography>{t("btns.cancel")}</Typography>
                                </Button>
                              </Grid>
                              <Grid>
                                <Button
                                  disabled={
                                    !isReCaptcha ||
                                    !(newObject.name.length > 2) ||
                                    !(newObject.address.length > 2) ||
                                    !(newObject.email.length > 2) ||
                                    !(newObject.licenceNumber > 100) ||
                                    // !(newObject.registrationNumber > 100) ||
                                    !!errorEmail
                                  }
                                  variant="contained"
                                  onClick={() => sendNewObject(newObject)}
                                  color="primary"
                                  startIcon={<SendIcon />}
                                  style={{ minWidth: 180, margin: "5px 0px" }}
                                >
                                  <Typography>{t("btns.send")}</Typography>
                                </Button>
                              </Grid>
                            </Grid>
                          </DialogActions>
                        </Dialog>
                      </Grid>
                    </div>
                  </ListItem>
                </List>
              </Grid>
            </Slide>
          </>
        </>
      )}
    </div>
  );
};

export default withSnackbar(Login);
