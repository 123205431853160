import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { SnackbarProvider } from "notistack";

import { CssBaseline } from "@material-ui/core";
import history from "./utils/history";
import LinearSpinner from "./components/spinners/Linear";
import App from "./App";

import "./i18n";

ReactDOM.render(
  <Router history={history}>
    <SnackbarProvider
      maxSnack={3}
      // preventDuplicate
    >
      <CssBaseline />
      <Suspense
        fallback={
          <>
            <LinearSpinner />
            <div
              style={{
                height: "100vh",
                backgroundColor: "#212121",
              }}
            ></div>
          </>
        }
      >
        <App />
      </Suspense>
    </SnackbarProvider>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// CHANGED // CHANGED // CHANGED // CHANGED

// serviceWorker.unregister();
serviceWorker.register();

// CHANGED // CHANGED // CHANGED // CHANGED
