import React from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { Grow, Typography, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
      width: 270,
      height: 50,
    },
    iconSize: {
      alignItems: "center",
    },
    logoSize: {
      height: 40,
      width: 40,
    },
    btnStylesWithOutline: {
      marginBottom: 20,
      height: 90,
      border: `1px solid ${theme.palette.grey[500]}`,
      // backgroundColor: `${theme.palette.grey[400]}05`,

      boxSizing: "border-box",
      boxShadow: `0px 0px 2px ${theme.palette.grey[400]}80`,
      borderRadius: 50,
      transition: "0.2s ease-in-out",
      "&:hover": {
        boxShadow: `0px 0px 6px ${theme.palette.primary.main}`,
        border: `1px solid ${theme.palette.primary.light}40`,
        backgroundColor: `${theme.palette.grey[400]}08`,

        // backgroundColor: `${theme.palette.primary.main}35`,
        // backgroundColor: `${theme.palette.background.default}`
      },
    },
    btnText: {
      transition: "0.2s ease-in-out",
      "&:hover": {
        // marginLeft: 10,
      },
    },
  })
);

export default function LoginBtn(props: any) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grow in>
      <div>
        <Button
          variant="outlined"
          className={classes.btnStylesWithOutline}
          style={{ width: 320 }}
          onClick={props.loginFunc}
        >
          <Typography
            variant="h6"
            color="textSecondary"
            className={classes.btnText}
          >
            {t("login.login")}
          </Typography>
        </Button>
      </div>
    </Grow>
  );
}
