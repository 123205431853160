import React, { useState, useEffect } from "react";
import { withSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import SearchIcon from "@material-ui/icons/Search";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { TextField, InputAdornment, IconButton, Grow } from "@material-ui/core";

import { localStorageAsync } from "../../utils/localStorageAsync";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {},
    searchInput: {
      width: "100%",
      margin: "0px auto ",
    },
  })
);

function Search(props: any) {
  const classes = useStyles();
  const { t } = useTranslation();

  const startInputValue =
    localStorageAsync.getItemSync("searchTerm") === undefined
      ? ""
      : localStorageAsync.getItemSync("searchTerm");

  const [value, setValue] = useState({ search: startInputValue || "" });

  useEffect(() => {}, [value.search]);

  const newGetSearch = (query: any) => {
    props.getSearch(query);
  };

  // const delayedSearch = useRef(
  //   debounce((query: any) => newGetSearch(query), 100)

  // ).current;

  // const keyPressed = (event: any) => {
  //   if (event.key === "Enter" || event.keyCode === 13) {
  //     setTimeout(() => {
  //       props.onSearch(value.search);
  //     }, 100);
  //   }
  // };

  // const delayedQuery = useCallback(
  //   debounce(() => {
  //     props.onSearch();
  //   }, 500),
  //   [value.search]
  // );

  const handleChange = (event: any) => {
    try {
      const field = event.target.value;
      newGetSearch(field);

      setValue({ search: field });

      // delayedQuery();
    } catch (err) {
      // enqueueSnackbar(err.toString(), {
      //   anchorOrigin: {
      //     vertical: "bottom",
      //     horizontal: "center",
      //   },
      //   variant: "error",
      //   action: cancelAction,
      // });
    }
    event.preventDefault();
  };

  const adaptiveLabelName = (name: any) => {
    if (name === "user") {
      return t("users.searchLabel");
    }
    if (name === "licence") {
      return t("licences.searchLabel");
    }
    if (name === "unathorized") {
      return t("unathorized.searchLabel");
    }
    if (name === "address") {
      return t("addresses.searchLabel");
    }
    if (name === "stamp") {
      return t("stamps.searchLabel");
    }
    if (name === "le") {
      return t("le.searchLabel");
    }
    if (name === "pe") {
      return t("pe.searchLabel");
    }
    if (name === "global") {
      return t("global.searchLabel");
    }
    return "Пошук";
  };

  const adaptiveInputName = (name: any) => {
    if (name === "user") {
      return t("users.searchInput");
    }
    if (name === "licence") {
      return t("licences.searchInput");
    }
    if (name === "unathorized") {
      return t("unathorized.searchInput");
    }
    if (name === "address") {
      return t("addresses.searchInput");
    }
    if (name === "stamp") {
      return t("stamps.searchInput");
    }
    return "Почніть пошук ...";
  };

  return (
    <div className={classes.searchInput}>
      <Grow in>
        <TextField
          fullWidth
          name={props.name}
          id="filled-search"
          label={adaptiveLabelName(props.name)}
          placeholder={adaptiveInputName(props.name)}
          type="search"
          value={value.search}
          className={classes.textField}
          onChange={handleChange}
          // onKeyDown={keyPressed}
          margin="normal"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grow>
    </div>
  );
}

export default withSnackbar(Search);
