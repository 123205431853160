import { LogLevelDesc } from "loglevel";

//todo: move it to /service, rename to ConfigService
class Config {
  env: string;
  buildEnv: string;
  beBaseUrl: string;
  feBaseUrl: string;
  logLevel: LogLevelDesc;
  disableAllLogs: boolean;
  printConfig: boolean;
  authHeader: string;
  loginSuccessPath: string;
  loginCancelPath: string;
  loginErrorPath: string;
  secret: string;
  encryptLocalStorage: boolean;
  historyBaseName: string;
  // LANDING_URL='https://uat.rcmd.com.ua'
  constructor() {
    this.env = process.env.NODE_ENV;
    this.buildEnv = process.env.BUILD_ENV || "local";
    this.beBaseUrl =
      process.env.REACT_APP_BE_BASE_URL ||
      "https://prd.back.address.vnpu.kiev.ua";
    this.feBaseUrl =
      process.env.REACT_APP_FE_BASE_URL || "http://localhost:3000";
    this.logLevel = (process.env.REACT_APP_LOG_LEVEL ||
      "DEBUG") as LogLevelDesc;
    this.disableAllLogs =
      process.env.REACT_APP_DISABLE_LOGS === "true" || false;
    this.printConfig = process.env.REACT_APP_PRINT_CONFIG === "true" || false;
    this.authHeader = "X-Access-Token";
    this.loginSuccessPath = process.env.REACT_APP_LOGIN_SUCCESS_PATH || "/auth";
    this.loginCancelPath = process.env.REACT_APP_LOGIN_CANCEL_PATH || "/login";
    this.loginErrorPath = process.env.REACT_APP_LOGIN_ERROR_PATH || "/error";
    this.secret = process.env.REACT_APP_SECRET || "changeme";

    this.encryptLocalStorage =
      process.env.REACT_APP_ENCRYPT_LOCAL_STORAGE === "true" || false;
    this.historyBaseName = process.env.REACT_APP_HISTORY_BASE_NAME || "/";

    if (this.printConfig) {
      console.log("config: file path:", `./config/${this.buildEnv}.env`);
      console.log("config: NODE_ENV:", this.env);
      console.log("config: BE_BASE_URL:", this.beBaseUrl);
      console.log("config: FE_BASE_URL:", this.feBaseUrl);
      console.log("config: LOG_LEVEL:", this.logLevel);
      console.log("config: DISABLE_LOGS:", this.disableAllLogs);
      console.log("config: PRINT_CONFIG:", this.printConfig);
      console.log("config: LOGIN_SUCCESS_PATH:", this.loginSuccessPath);
      console.log("config: LOGIN_CANCEL_PATH:", this.loginCancelPath);
      console.log("config: LOGIN_ERROR_PATH:", this.loginErrorPath);
      console.log("config: SECRET:", this.secret);
      console.log("config: ENCRYPT_LOCAL_STORAGE:", this.encryptLocalStorage);
      console.log("config: HISTORY_BASE_NAME:", this.historyBaseName);
    }
  }
}

export const config = new Config();
