import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Button,
  // makeStyles,
  TextField,
  // Theme,
  Typography,
  useTheme,
  Backdrop,
  withStyles,
  makeStyles,
  Theme,
  DialogContentText,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SendIcon from "@material-ui/icons/Send";
import CancelIcon from "@material-ui/icons/Cancel";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import Grow from "@material-ui/core/Grow/Grow";
import { objectService } from "../../services/objectService";

const useStyles = makeStyles((theme: Theme) => ({
  backDrop: {
    backdropFilter: "blur(7px)",
    backgroundColor: `${theme.palette.grey[100]}30`,
  },
}));

const AddStamps = (props: any) => {
  const theme = useTheme();

  const classes = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const handleChangePib = (e: any) => {
    setStamp((oldValue: any) => ({ ...oldValue, name: e }));
  };
  const handleChangeSubj = (e: any) => {
    setStamp((oldValue: any) => ({ ...oldValue, subject: e }));
  };
  const handleChangeRegion = (e: any) => {
    setStamp((oldValue: any) => ({ ...oldValue, region: e }));
  };
  const handleChangeDescription = (e: any) => {
    setStamp((oldValue: any) => ({ ...oldValue, description: e }));
  };

  const handleClickOpen = () => {
    setOpen(true);
    // console.log("open : ", open);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseWithClear = () => {
    setStamp((oldState: any) => ({ ...oldState, ...initialState }));
    setOpen(false);
  };

  const initialState = {
    name: "",
    subject: "",
    region: "",
    description: "",
  };

  const [stamp, setStamp] = useState(initialState);

  const sendNewStamp = () => {
    const newStamp = {
      name: stamp.name,
      subject: stamp.subject,
      region: stamp.region,
      description: stamp.description,
    };
    objectService
      .addNewSignet(newStamp)
      // .then((data: any) => console.log("addNewSignet", data))
      .then(() => props.getUpdateStamps())
      .then(() => handleClose());
  };

  return (
    <>
      <Grow in>
        <Grid>
          <IconButton
            onClick={handleClickOpen}
            color="primary"
            style={{ width: 80, height: 80 }}
          >
            <AddCircleIcon fontSize="large" style={{ width: 60, height: 60 }} />
          </IconButton>
        </Grid>
      </Grow>

      <Grid container item xs={12} justify="center">
        <Dialog
          onBackdropClick={handleClose}
          onEscapeKeyDown={handleCloseWithClear}
          onClose={handleClose}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
            timeout: 400,
          }}
          open={open}
          aria-labelledby="form-dialog-title"
        >
          {/* <DialogTitle id="form-dialog-title">{t("help.label")}</DialogTitle> */}
          <DialogTitle
            id="form-dialog-title"
            style={{ backgroundColor: theme.palette.grey[900] }}
          >
            {t("stamps.modalTitle")}
          </DialogTitle>
          <DialogContent
            dividers={true}
            style={{ backgroundColor: theme.palette.grey[900] }}
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            <DialogContentText>
              {t("addresses.addressHelper")}
            </DialogContentText>
            <Grid container item xs={12} style={{ width: "850px" }}>
              <TextField
                required
                error={stamp.name.length < 2}
                label={t("stamps.pib")}
                fullWidth
                margin="dense"
                variant="outlined"
                multiline
                rows={3}
                rowsMax="10"
                value={stamp.name}
                onChange={(e: any) => handleChangePib(e.target.value)}

                // inputProps={{ disabled: true }}
              />
              <TextField
                required
                error={stamp.subject.length < 2}
                label={t("stamps.position")}
                fullWidth
                margin="dense"
                variant="outlined"
                multiline
                rows={1}
                rowsMax="3"
                value={stamp.subject}
                onChange={(e: any) => handleChangeSubj(e.target.value)}

                // inputProps={{ disabled: true }}
              />
              <TextField
                required
                error={stamp.region.length < 2}
                label={t("stamps.region")}
                fullWidth
                margin="dense"
                variant="outlined"
                multiline
                rows={1}
                rowsMax="5"
                value={stamp.region}
                onChange={(e: any) => handleChangeRegion(e.target.value)}

                // inputProps={{ disabled: true }}
              />
              <TextField
                label={t("stamps.description")}
                fullWidth
                margin="dense"
                variant="outlined"
                multiline
                rows={1}
                rowsMax="5"
                value={stamp.description}
                onChange={(e: any) => handleChangeDescription(e.target.value)}

                // inputProps={{ disabled: true }}
              />
            </Grid>
          </DialogContent>
          <DialogActions style={{ backgroundColor: theme.palette.grey[900] }}>
            <Grid container item xs={12} justify="space-between">
              <Grid>
                <Button
                  variant="contained"
                  onClick={handleCloseWithClear}
                  color="primary"
                  startIcon={<CancelIcon />}
                  style={{ minWidth: 180, margin: "5px 0px" }}
                >
                  <Typography>{t("btns.cancel")}</Typography>
                </Button>
              </Grid>
              <Grid>
                <Button
                  disabled={
                    stamp.subject.length < 2 ||
                    // stamp.description.length < 2 ||
                    stamp.name.length < 2 ||
                    stamp.region.length < 2
                  }
                  variant="contained"
                  onClick={sendNewStamp}
                  color="primary"
                  startIcon={<SendIcon />}
                  style={{ minWidth: 180, margin: "5px 0px" }}
                >
                  <Typography>{t("btns.send")}</Typography>
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

export default AddStamps;
