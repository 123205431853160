import React, { useMemo } from "react";

import { Chip, useMediaQuery } from "@material-ui/core";

import { dataService } from "../../services/dataService";

import { Role } from "../../models/static.model";

import HeaderMenuDropDown from "./HeaderMenuDropDown";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";

function HeaderMenuBtn(props: any) {
  const role = useMemo(() => dataService.getRoleSync(), []);
  const matches700 = useMediaQuery("(min-width:700px)");

  return (
    <div>
      <div>
        {matches700 && (
          <>
            {role === Role.Admin && props.pictureUrl && (
              <Chip
                variant="outlined"
                color="primary"
                icon={<AssignmentIndIcon />}
                label={props.role}
                style={{ marginTop: 2 }}
              />
            )}
          </>
        )}

        <HeaderMenuDropDown
          email={props.email}
          firstName={props.firstName}
          lastName={props.lastName}
          pictureUrl={props.pictureUrl}
        />
      </div>
    </div>
  );
}

export default HeaderMenuBtn;
