
export enum InternalError {
  GeneralError = -1 ,
  NotFoundAuthParamsError = -2
  
}

export const resolveErrorDescription = new Map()
resolveErrorDescription.set(-1, "something went wrong")
resolveErrorDescription.set(-2, "auth params not found in redirected auth url")
