import React from "react";
import queryString from "query-string";
import { Search } from "history";

import { responseValidator } from "../../services/responseValidator";
import { authService } from "../../services/authService";

import { InternalError } from "../../models/internal-error.model";
import { NpuError } from "../../models/error.model";
import { JwtToken } from "../../models/token.model";
import { Role } from "../../models/static.model";

import { config } from "../../utils/config";

import Logger from "../../utils/logger";
import {
  LOGIN_PATH,
  linkToMain,
  linkToRules,
  linkToSettings,
  linkToSearch,
} from "../Routes/var_PATHS";
import CircularSpinner from "../spinners/circular";
import { Grid } from "@material-ui/core";

type AuthProps = {
  location: Search;
};

// http://localhost:3000/auth?role=client&accessToken=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJyY21kIiwic3ViIjoidXNlciIsImF1ZCI6InVpIiwiZXhwIjoxNTY5ODcxODAwLCJuYmYiOjE1Njk4NjgyMDAsImlhdCI6MTU2OTg2ODIwMCwidHlwIjowLCJ1aWQiOjEsInNpZCI6MSwidXJsIjowfQ.jVMJ77loJhx2FR1_VrfOWad4kANz6gDXJYb0nkDhm7KJDvqKMAbiS-A-hDNEHBeTbEO_ew9Xoim7b049jz4frQ&refreshToken=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJyY21kIiwic3ViIjoidXNlciIsImF1ZCI6InVpIiwiZXhwIjoxNTcyNDYwMjAwLCJuYmYiOjE1Njk4NjgyMDAsImlhdCI6MTU2OTg2ODIwMCwidHlwIjoxLCJ1aWQiOjEsInNpZCI6MSwidXJsIjowfQ.BvbKAKGgXqakmQxQFdQFbIQRlrYzFndJWApY5PIP8LK96lGxsSNQRwUHMleM_2-Z6wG-E29ohwziB_N_sEw41w
// ROUTE - "/auth"

const AuthComponent: React.FC<AuthProps> = (props: AuthProps) => {
  const logger = new Logger("AuthComponent");

  const ERROR_CODE_PARAM = "errorCode";

  const queryParams = queryString.parse(props.location);

  ((_) => {
    if (
      !queryParams.accessToken ||
      !queryParams.refreshToken ||
      !queryParams.role
    ) {
      const url = `${LOGIN_PATH}?${ERROR_CODE_PARAM}=${InternalError.NotFoundAuthParamsError}`;
      window.location.replace(url);
    } else {
      const refreshToken: JwtToken = queryParams.refreshToken as JwtToken;
      const sessionToken: JwtToken = queryParams.accessToken as JwtToken;
      const role: Role = responseValidator.parseRole(
        queryParams.role as string
      );

      if (role === Role.Unknown) {
        const url = `${LOGIN_PATH}?${ERROR_CODE_PARAM}=${InternalError.NotFoundAuthParamsError}`;
        return window.location.replace(url);
      }

      logger.debug("role :", role);
      logger.debug("sessionToken :", sessionToken);
      logger.debug("refreshToken :", refreshToken);

      authService
        .login(role, sessionToken, refreshToken)
        .then(() => {
          setTimeout(() => {
            // const url = `${LOGIN_PATH}?${ERROR_CODE_PARAM}=${InternalError.NotFoundAuthParamsError}`;

            role === Role.Admin
              ? // ? linkToMain()
                window.location.replace("/dashboard")
              : role === Role.User
              ? window.location.replace("/search")
              : // ? linkToSearch()
                window.location.replace("/account");
            // : linkToSettings();
          }, 2000);
        })
        .catch((error) => {
          if (error instanceof NpuError) {
            const { errorCode } = error as NpuError;
            const url = `${config.feBaseUrl}${LOGIN_PATH}?${ERROR_CODE_PARAM}=${errorCode}`;
            window.location.replace(url);
          } else {
            const url = `${config.feBaseUrl}${LOGIN_PATH}?${ERROR_CODE_PARAM}=${InternalError.GeneralError}&msg=${error.errorMessage}`;
            logger.error(error);
            window.location.replace(url);
            throw error;
          }
          // localStorageAsync.setItem("gbR", false);
        });
    }
  })();

  return (
    <>
      <Grid container item xs justify="center" style={{ marginTop: 320 }}>
        <CircularSpinner />
      </Grid>
    </>
  );
};

export default AuthComponent;
