import history from "../../utils/history";

export const MAIN_PATH = "/dashboard";
export const RULES_PATH = "/rules";
export const INSTRUCTION_PATH = "/Instruction";
export const LICENSES_PATH = "/licenses";
export const SETTINGS_PATH = "/account";
export const SEARCH_PATH = "/search";
export const USERS_PATH = "/users";
export const OBJECTS_PATH = "/objects";
export const ENTITYS_PATH = "/le";
export const PRIVATE_ENTERPRISE_PATH = "/pe gtb";
export const STAMPS_PATH = "/stamps";
export const UNAUTHORIZED_PATH = "/unauthorized";
export const REQUESTS_PATH = "/requests";

export const LOGIN_PATH = "/login";
export const ERROR_PATH = "/error";
export const AUTH_PATH = "/auth";
export const MAINTENANCE_PATH = "/maintenance";

export const linkToInstructions = () => history.push(INSTRUCTION_PATH);
export const linkToRules = () => history.push(RULES_PATH);
export const linkToSearch = () => history.push(SEARCH_PATH);
export const linkToRequests = () => history.push(REQUESTS_PATH);
export const linkToUnauthorized = () => history.push(UNAUTHORIZED_PATH);
export const linkToStamps = () => history.push(STAMPS_PATH);
export const linkToMain = () => history.push(MAIN_PATH);
export const linkToLicenses = () => history.push(LICENSES_PATH);
export const linkToSettings = () => history.push(SETTINGS_PATH);
export const linkToUsers = () => history.push(USERS_PATH);
export const linkToObjects = () => history.push(OBJECTS_PATH);
export const linkToEntitys = () => history.push(ENTITYS_PATH);
export const linkToEnterprises = () => history.push(PRIVATE_ENTERPRISE_PATH);
export const linkToLogin = () => history.push(LOGIN_PATH);
