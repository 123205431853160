import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import NavigationTabs from "../NavigationTabs/NavigationTabs";
import SearchField from "../Search/SearchField";
import CircularSpinner from "../spinners/circular";
import SearchBtn from "../Search/SearchBtn";
import { SignUpRequestUpdatedEvent } from "../../models/event.model";
import { pubSubService } from "../../services/pubSubService";
import ToTopBtn from "../ButtonsWithConfirmation/ToTopBtn";
import InfiniteScroll from "react-infinite-scroll-component";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar, withSnackbar } from "notistack";
import SearchInfo from "../Search/SearchInfo";
import { authService } from "../../services/authService";
import RequestedTabs from "./RequestedTabs";
import RequestItem from "./RequestItem";
import { debounce } from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  spinner: {
    paddingTop: 110,
  },
  user: {
    margin: "45px auto",
    // width: "90%",
    minWidth: 375,
  },
  admin: {},
}));

const RequestsPage = () => {
  const matches890 = useMediaQuery("(min-width:890px)");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const cancelAction = () => {
    return (
      <IconButton onClick={() => closeSnackbar()}>
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  };

  const classes = useStyles();

  const [searchVal, setSearch] = useState({ field: "" });

  const getSearch = (e: any) => {
    const searchText = e.trim();

    setSearch((searchVal: any) => ({
      ...searchVal,
      field: searchText,
    }));

    delayedQuery(searchText);
  };

  const delayedQuery = useCallback(
    debounce((text: any) => {
      onSearch(text);
    }, 300),
    []
  );

  const onSearch = (text: any) => {
    setIsLoaded(true);
    const filter = {
      statusId: role.levelId,

      pageNumber: 1,
      searchTerm: text,
      // pageSize: paginationData.pageSize,
    };

    authService
      .searchSignUps(filter)
      .then((data: any) => {
        setRequestedUsers(data.data);
        return data;
      })
      .then((data: any) =>
        setPaginationData((oldState: any) => ({
          ...oldState,
          pageNumber: filter.pageNumber + 1,
          totalCount: data.totalCount,
          pageSize: data.pageSize,
        }))
      )
      .then(() => setIsLoaded(false));
  };

  const [isLoaded, setIsLoaded] = useState(false);

  const [requestedUsers, setRequestedUsers] = useState([
    {
      id: 0,
      hashId: "",
      service: {},
      region: {},
      name: "",
      email: "",
      licenceNumber: 0,
      reason: "",
      licence: {
        contacts: "",
        createdAt: 0,
        fio: "",
        hashId: "",
        licence: 0,
        modifiedAt: 0,
        name: "",
        region: { id: 0, name: "" },
        service: { id: 0, name: "" },
      },
      user: {},
    },
  ]);

  const [role, setRole] = useState({
    levelId: 0,
  });

  useEffect(() => {
    setIsLoaded(true);
    pubSubService.requestSubscribe(eventHandler);

    const filter = {
      statusId: role.levelId,
      pageNumber: 1,
      pageSize: paginationData.pageSize || 20,
      searchTerm: searchVal.field,
    };

    authService
      .searchSignUps(filter)
      .then((data: any) => {
        setRequestedUsers(data.data);
        return data;
      })
      .then((data: any) =>
        setPaginationData((oldState: any) => ({
          ...oldState,
          pageNumber: filter.pageNumber + 1,
          totalCount: data.totalCount,
          pageSize: data.pageSize,
        }))
      )
      .then(() => setIsLoaded(false));

    return () => {
      pubSubService.unsubsribe(eventHandler, "Event-handler");
    };
  }, [role.levelId]);

  const eventHandler = function (msg: any, data: any) {
    if (data instanceof SignUpRequestUpdatedEvent) {
      setIsLoaded(true);
      const filter = {
        statusId: role.levelId,

        pageNumber: 1,
        pageSize: paginationData.pageSize || 20,
        searchTerm: searchVal.field,
      };

      authService
        .searchSignUps(filter)
        .then((data: any) => {
          setRequestedUsers(data.data);
          return data;
        })
        .then((data: any) =>
          setPaginationData((oldState: any) => ({
            ...oldState,
            pageNumber: filter.pageNumber + 1,
            totalCount: data.totalCount,
            pageSize: data.pageSize,
          }))
        )
        .then(() => setIsLoaded(false));
    }
  };

  const fetchMoreData = () => {
    if (paginationData.totalCount > requestedUsers.length) {
      try {
        const filter = {
          statusId: role.levelId,

          pageNumber: paginationData.pageNumber || 1,
          pageSize: paginationData.pageSize || 20,
          searchTerm: searchVal.field,
        };
        authService
          .searchSignUps(filter)
          .then((data: any) => {
            setRequestedUsers((oldUsers: any) =>
              [...oldUsers].concat(data.data)
            );

            return data;
            // console.log("userService.searchUsers", data);
          })
          .then((data: any) =>
            setPaginationData((oldState: any) => ({
              ...oldState,
              pageNumber: filter.pageNumber + 1,
              totalCount: data.totalCount,
              pageSize: data.pageSize,
            }))
          );
      } catch (err) {
        enqueueSnackbar(err.toString(), {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          variant: "error",
          action: cancelAction,
        });
      }
    }
  };

  const [paginationData, setPaginationData] = useState({
    pageNumber: Number(),
    pageSize: Number(),
    totalCount: Number(),
  });

  const handleChangeLevels = (level: any) => setRole({ levelId: level });

  return (
    <Grid
      container
      item
      xs={12}
      justify={"space-between"}
      // justify={isAdmin ? "space-between" : "flex-start"}
      direction={"row"}
      wrap="nowrap"
    >
      <Grid item style={{ display: matches890 ? "" : "none" }}>
        <NavigationTabs />
      </Grid>

      {/* {isLoaded ? ( */}
      <Grid item style={{ width: "100%" }} className={classes.user}>
        <Grid container item xs={12} wrap="nowrap" style={{ width: "100%" }}>
          <Grid item style={{ width: "100%" }}>
            <SearchField
              name={"unauthorized"}
              getSearch={getSearch}
              // onSearch={onSearch}
              // resetData={String("")}
              // reset={reset}
            />
          </Grid>
          {/* <Grid item>
              <SearchBtn onSearch={onSearch} />
            </Grid> */}

          {/* <Grid item>
              <AddObject />
            </Grid> */}
        </Grid>

        <Grid container item xs={12}>
          <SearchInfo paginationData={paginationData} isLoading={!isLoaded} />
        </Grid>

        <Grid container item xs={12} style={{ width: "100%" }}>
          <RequestedTabs handleChangeLevels={handleChangeLevels} />

          {/* {role.levelId === 0 && (
              <Grid container item xs={12} justify="center">
                <Typography variant="h1">NEW</Typography>
              </Grid>
            )}
            {role.levelId === 1 && (
              <Grid container item xs={12} justify="center">
                <Typography variant="h1">ADDED</Typography>
              </Grid>
            )}
            {role.levelId === 2 && (
              <Grid container item xs={12} justify="center">
                <Typography variant="h1">REJECTED</Typography>
              </Grid>
            )} */}

          <div style={{ width: "100%" }}>
            <InfiniteScroll
              dataLength={requestedUsers.length}
              next={fetchMoreData}
              hasMore={
                requestedUsers.length < paginationData.totalCount ? true : false
              }
              loader={
                <Grid
                  container
                  item
                  xs={12}
                  justify="center"
                  style={{ marginTop: 50 }}
                >
                  <CircularSpinner forFetch={true} />
                </Grid>
              }
              scrollThreshold="300px"
              className={classes.infScroll}
              endMessage={
                requestedUsers.length === paginationData.totalCount && (
                  <p style={{ textAlign: "center" }}>
                    <span style={{ fontSize: 40 }}> 🤷‍♂️ </span>
                  </p>
                )
              }
            >
              {requestedUsers.map((item: any) => (
                <Grid key={item.hashId} container item xs={12}>
                  {item.hashId ? (
                    <RequestItem requestedUser={item} />
                  ) : (
                    <Grid
                      container
                      item
                      xs={12}
                      justify="center"
                      className={classes.spinner}
                    >
                      <CircularSpinner />
                    </Grid>
                  )}
                </Grid>
              ))}
            </InfiniteScroll>
          </div>

          <ToTopBtn
            paginationData={paginationData}
            arrLength={requestedUsers.length}
          />
        </Grid>
      </Grid>
      {/* ) : (
        <Grid
          container
          item
          xs={12}
          justify="center"
          className={classes.spinner}
        >
          <CircularSpinner />
        </Grid>
      )} */}
    </Grid>
  );
};

export default withSnackbar(RequestsPage);
