import React, { useEffect, useState } from "react";
import { Grid, withStyles } from "@material-ui/core";

import { useTranslation } from "react-i18next";

import TextField from "@material-ui/core/TextField/TextField";

const LightTextInDisabledTextField = withStyles({
  root: {
    // marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#fefefe", // (default alpha is 0.38)
    },
    "& .MuiInputLabel-outlined.Mui-disabled": {
      color: "#fefefe90", // (default alpha is 0.38)
    },
  },
})(TextField);

const EventObjectItem = (props: any) => {
  // const {
  //   description,
  //   hashId,
  //   name,
  //   source,
  //   subject,
  //   modifiedAt,
  // } = props.objectItem;

  // console.log("props.objectItem : ", props.objectItem);

  const [objectData, setObjectData] = useState({
    isDeleted: false,
    description: "",
    hashId: "",
    name: "",
    source: "",
    subject: "",
    modifiedAt: 0,
    document: "",
    date: 0,
  });

  useEffect(() => {
    setObjectData(props.objectItem);
    // defaultDate();
  }, []);

  // const classes = useStyles();

  // const theme = useTheme();

  const { t } = useTranslation();

  return (
    <>
      <Grid
        container
        item
        xs={12}
        justify="space-between"
        style={{
          paddingTop: "15px",
        }}
      >
        {props.objectItem.address.subject && (
          <Grid container item xs={12}>
            <LightTextInDisabledTextField
              disabled
              name="subject"
              label={
                props.name === "address"
                  ? t("addresses.address")
                  : props.name === "pe"
                  ? t("pe.address")
                  : props.name === "le"
                  ? t("le.address")
                  : ""
              }
              variant="outlined"
              rows="1"
              rowsMax="20"
              multiline
              style={{ width: "100%", marginBottom: 15 }}
              value={props.objectItem.address.subject}
            />
          </Grid>
        )}

        <Grid container item xs={12}>
          <LightTextInDisabledTextField
            disabled
            name="fio"
            rows="1"
            rowsMax="20"
            multiline
            label={
              props.name === "address"
                ? t("addresses.pib")
                : props.name === "pe"
                ? t("pe.pib")
                : props.name === "le"
                ? t("le.pib")
                : ""
            }
            variant="outlined"
            style={{ width: "100%", marginBottom: 15 }}
            value={props.objectItem.address.name}
          />
        </Grid>

        <Grid container item xs={12}>
          <LightTextInDisabledTextField
            disabled
            name="subject"
            label={
              props.name === "address"
                ? t("addresses.doc")
                : props.name === "pe"
                ? t("pe.doc")
                : props.name === "le"
                ? t("le.doc")
                : ""
            }
            variant="outlined"
            rows="1"
            rowsMax="20"
            multiline
            style={{ width: "100%", marginBottom: 15 }}
            value={props.objectItem.address.document}
          />
        </Grid>

        <Grid container item xs={12}>
          <LightTextInDisabledTextField
            disabled
            name="descr"
            label={
              props.name === "address"
                ? t("addresses.description")
                : props.name === "pe"
                ? t("pe.description")
                : props.name === "le"
                ? t("le.description")
                : ""
            }
            variant="outlined"
            rows="1"
            rowsMax="20"
            multiline
            style={{ width: "100%", marginBottom: 15 }}
            value={props.objectItem.address.description}
          />
        </Grid>

        <Grid container item xs={12}>
          <LightTextInDisabledTextField
            disabled
            name="source"
            rows="1"
            rowsMax="20"
            multiline
            label={
              props.name === "address"
                ? t("addresses.subject")
                : props.name === "pe"
                ? t("pe.subject")
                : props.name === "le"
                ? t("le.subject")
                : ""
            }
            variant="outlined"
            style={{ width: "100%", marginBottom: 15 }}
            value={props.objectItem.address.source}
          />
        </Grid>

        <Grid container item xs={12}>
          <LightTextInDisabledTextField
            disabled
            id="date"
            name="date"
            label={
              props.name === "address"
                ? t("addresses.date")
                : props.name === "pe"
                ? t("pe.date")
                : props.name === "le"
                ? t("le.date")
                : ""
            }
            // type="date"

            rows="1"
            rowsMax="20"
            multiline
            variant="outlined"
            style={{ width: "100%" }}
            value={new Date(props.objectItem.address.date).toLocaleString([], {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              // hour: "2-digit",
              // minute: "2-digit",
            })}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EventObjectItem;
