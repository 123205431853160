import React, { useState, useMemo } from "react";
import {
  makeStyles,
  Card,
  CardActionArea,
  Grid,
  IconButton,
  Theme,
  Typography,
  Avatar,
  CardContent,
  Collapse,
  TextField,
  withStyles,
  Chip,
} from "@material-ui/core";

import Grow from "@material-ui/core/Grow/Grow";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import { withSnackbar, useSnackbar } from "notistack";

import { useTranslation } from "react-i18next";
import ApproveBtn from "../ButtonsWithConfirmation/ApproveBtn";
import RejectBtnWithReason from "../ButtonsWithConfirmation/RejectBtnWithReason";
import { authService } from "../../services/authService";
import CloseIcon from "@material-ui/icons/Close";
import { RejectSignUpRequest } from "../../models/login.model";

const LightTextInDisabledTextField = withStyles({
  root: {
    // marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#fefefe", // (default alpha is 0.38)
    },
    "& .MuiInputLabel-outlined.Mui-disabled": {
      color: "#fefefe90", // (default alpha is 0.38)
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    background: theme.palette.background.paper,
    // height: 300,
    width: "100%",
    marginTop: "15px",
  },
  btnStylesWithOutline: {
    minHeight: 48,
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.paper,

    borderRadius: 25,
    boxSizing: "border-box",
    boxShadow: `0px 0px 2px ${theme.palette.primary.main}`,
    transition: "0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0px 0px 10px ${theme.palette.primary.main}`,
    },
  },
  btnStylesWithOutlineSecondary: {
    minHeight: 48,
    border: `2px solid ${theme.palette.secondary.main}`,
    boxSizing: "border-box",
    boxShadow: `0px 0px 4px ${theme.palette.secondary.main}`,
    // backgroundColor: `${theme.palette.error.main}20`,
    borderRadius: 25,
    transition: "0.2s ease-in-out",
    "&:hover": {
      boxShadow: `0px 0px 10px ${theme.palette.secondary.main}`,
    },
  },
}));

const RequestItem = (props: any) => {
  const classes = useStyles();

  // const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [openObject, setOpenObject] = useState({ hashId: "", isOpen: false });

  // const myRole = useMemo(() => dataService.getRoleSync(), []);

  // const isAdmin = myRole === Role.Admin;

  const handleOpenObject = (idItem: any) => {
    setTimeout(() => {
      setOpenObject({ hashId: idItem, isOpen: !openObject.isOpen });
    }, 150);
  };

  // id: 0,
  // hashId: "",
  // service: {},
  // region: {},
  // name: "",
  // email: "",
  // licenceNumber: 0,
  // licence:

  const cancelAction = () => {
    return (
      <IconButton onClick={() => closeSnackbar()}>
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  };

  // const [reason, setReason] = useState("");

  const handleApproveRequest = (hash: any) => {
    authService.acceptSignUp(hash).catch((err: any) => {
      enqueueSnackbar(err.toString(), {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        variant: "error",
        action: cancelAction,
      });
    });
  };
  const handleRejectRequest = (req: RejectSignUpRequest) => {
    try {
      // const reason = ''
      authService.rejectSignUp(req);
    } catch (err) {
      enqueueSnackbar(err.toString(), {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        variant: "error",
        action: cancelAction,
      });
    }
  };

  return (
    <Grow in>
      <>
        <Card
          className={classes.card}
          style={{
            border: props.requestedUser.isDeleted
              ? "2px solid red"
              : "user" in props.requestedUser
              ? props.requestedUser.user.isDeleted
                ? "2px solid red"
                : ""
              : "",
          }}
        >
          <CardActionArea
            disableRipple={props.requestedUser.isDeleted}
            disableTouchRipple={props.requestedUser.isDeleted}
            onClick={() => handleOpenObject(openObject.hashId)}
            // onCopy={(e: any) => handlerCopy(openObject.hashId, e)}
            // onBlur={() => {
            //   setOpenObject({ hashId: openObject.hashId, isOpen: false });
            // }}
          >
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              style={{ padding: 10, width: "100%" }}
            >
              <Grid
                item
                style={{
                  // marginLeft: 30,
                  // whiteSpace: "nowrap" /* Отменяем перенос текста */,
                  overflow: "hidden" /* Обрезаем содержимое */,
                  textOverflow: "ellipsis",
                  // paddingRight: "10px",
                  whiteSpace: "pre-wrap",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "pre-line",
                  }}
                >
                  {`ПІБ: ${props.requestedUser.name} `}
                </Typography>
                <Typography
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "pre-line",
                  }}
                >
                  {`Свідоцтво №: ${props.requestedUser.licenceNumber}`}
                </Typography>
                {"registrationNumber" in props.requestedUser && (
                  <Typography
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {`Реєстраційний №: ${props.requestedUser.registrationNumber}`}
                  </Typography>
                )}
                <Typography
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "pre-line",
                  }}
                >
                  {`Адреса: ${props.requestedUser.address}`}
                </Typography>
                <Typography
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "pre-line",
                  }}
                >
                  {`Email: ${props.requestedUser.email}`}
                </Typography>
                {props.requestedUser.phoneNumber && (
                  <Typography
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {`Телефон №: ${props.requestedUser.phoneNumber}`}
                  </Typography>
                )}
                {props.requestedUser.reason && (
                  <Typography
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "pre-line",
                    }}
                    color="error"
                  >
                    {`${props.requestedUser.reason}`}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </CardActionArea>
          <Collapse
            in={openObject.isOpen && !props.requestedUser.isDeleted}
            timeout="auto"
            unmountOnExit
          >
            <CardContent>
              <Grid
                container
                item
                xs={12}
                onClick={() => handleOpenObject(props.requestedUser.hashId)}
                style={{ padding: "10px" }}
                justify="space-between"
                alignItems="center"
                alignContent="center"
              >
                <Grid item>
                  {props.requestedUser.isEmailBusy &&
                    props.requestedUser.status.id === 0 && (
                      <Grid>
                        <Typography color="error">
                          Email вже зайнятий іншим користувачем
                        </Typography>
                      </Grid>
                    )}

                  {"user" in props.requestedUser && (
                    <Grid
                      container
                      item
                      xs={12}
                      // justify="space-between"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                    >
                      <Grid item>
                        {"user" in props.requestedUser && (
                          <Grid
                            container
                            item
                            xs={12}
                            justify="center"
                            alignContent="center"
                            alignItems="center"
                            style={{
                              width: "110px",
                              height: "110px",
                            }}
                          >
                            <Avatar
                              src={
                                props.requestedUser.user.pictureUrl
                                  ? props.requestedUser.user.pictureUrl
                                  : ""
                              }
                              style={{
                                width: "90px",
                                height: "90px",
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                      {"user" in props.requestedUser && (
                        <Grid item style={{ marginLeft: 30 }}>
                          <Typography
                            variant="h6"
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {props.requestedUser.user.status.id === 1
                              ? `${props.requestedUser.user.firstName} ${props.requestedUser.user.lastName}`
                              : "Запрошення відправлено на email:"}
                          </Typography>
                          <Typography
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {props.requestedUser.user.email}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  )}

                  {"licence" in props.requestedUser && (
                    <Grid
                      container
                      item
                      // justify="space-between"
                      xs={12}
                      alignItems="center"
                      alignContent="center"
                      style={{ marginTop: 10 }}
                      wrap="nowrap"
                    >
                      {props.requestedUser.isLicenceBusy && (
                        <Grid>
                          {props.requestedUser.status.id === 0 && (
                            <Typography color="error">
                              Свідоцтво вже зайняте іншим користувачем
                            </Typography>
                          )}
                        </Grid>
                      )}

                      {!props.requestedUser.isLicenceBusy && (
                        <>
                          <Grid item>
                            <LightTextInDisabledTextField
                              disabled
                              // label={t("referral.input")}
                              label={t("licences.licenceN")}
                              variant="outlined"
                              style={{ width: 110 }}
                              value={props.requestedUser.licence.licence}
                              inputProps={{
                                style: { textAlign: "center", fontSize: 24 },
                                disabled: true,
                                // textAlign: "center",
                              }}
                            />
                          </Grid>
                          <Grid item style={{ marginLeft: 30 }}>
                            <Grid container item xs={12}>
                              <Typography
                                variant="h6"
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {props.requestedUser.licence.fio}
                              </Typography>
                            </Grid>
                            <Grid container item xs={12}>
                              <Typography
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {props.requestedUser.licence.name}
                              </Typography>
                            </Grid>
                            <Grid container item xs={12}>
                              <Typography
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {props.requestedUser.licence.contacts}
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      )}

                      {props.requestedUser.status.id !== 0 && (
                        <>
                          {" "}
                          <Grid item>
                            <LightTextInDisabledTextField
                              disabled
                              // label={t("referral.input")}
                              label={t("licences.licenceN")}
                              variant="outlined"
                              style={{ width: 110 }}
                              value={props.requestedUser.licence.licence}
                              inputProps={{
                                style: { textAlign: "center", fontSize: 24 },
                                disabled: true,
                                // textAlign: "center",
                              }}
                            />
                          </Grid>
                          <Grid item style={{ marginLeft: 30 }}>
                            <Grid container item xs={12}>
                              <Typography
                                variant="h6"
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {props.requestedUser.licence.fio}
                              </Typography>
                            </Grid>
                            <Grid container item xs={12}>
                              <Typography
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {props.requestedUser.licence.name}
                              </Typography>
                            </Grid>
                            <Grid container item xs={12}>
                              <Typography
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {props.requestedUser.licence.contacts}
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                justify="space-evenly"
                style={{ marginTop: 10 }}
              >
                {!props.requestedUser.isEmailBusy &&
                  !props.requestedUser.isLicenceBusy && (
                    <Grid item>
                      <ApproveBtn
                        handelApprove={handleApproveRequest}
                        hashId={props.requestedUser.hashId}
                      />
                    </Grid>
                  )}

                {props.requestedUser.status.id === 0 && (
                  <Grid item>
                    <RejectBtnWithReason
                      handleReject={handleRejectRequest}
                      hashId={props.requestedUser.hashId}
                    />
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Collapse>
        </Card>
        <Grid container item xs={12} justify="space-between">
          {"user" in props.requestedUser ? (
            <Grid item>
              {props.requestedUser.user.isDeleted && (
                <Typography color="error">
                  {props.requestedUser.user.copyCounter >= 3
                    ? t("chips.deletedCopy")
                    : t("chips.deleted")}
                </Typography>
              )}
            </Grid>
          ) : (
            <Grid item>
              {props.requestedUser.isDeleted && (
                <Typography color="error">Відхилено</Typography>
              )}
            </Grid>
          )}
          <Grid item>
            {"createdAt" in props.requestedUser && (
              <Typography align="right" variant="caption">
                {`${new Date(props.requestedUser.createdAt).toLocaleTimeString(
                  [],
                  {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour12: false,
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                )}`}
              </Typography>
            )}
          </Grid>
        </Grid>
      </>
    </Grow>
  );
};

export default withSnackbar(RequestItem);
