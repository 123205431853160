import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar, withSnackbar } from "notistack";

import { authService } from "../../services/authService";
import {
  Divider,
  Typography,
  ListItemIcon,
  ListItem,
  List,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CloseIcon from "@material-ui/icons/Close";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import ReceiptIcon from "@material-ui/icons/Receipt";
import SearchIcon from "@material-ui/icons/Search";
import LockIcon from "@material-ui/icons/Lock";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import SubjectIcon from "@material-ui/icons/Subject";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import {
  linkToLogin,
  linkToMain,
  linkToLicenses,
  linkToUsers,
  linkToSettings,
  linkToUnauthorized,
  linkToRequests,
  linkToSearch,
  linkToRules,
  linkToInstructions,
} from "../Routes/var_PATHS";
import ImageAvatars from "./ImageAvatars";
import { dataService } from "../../services/dataService";
import { Role } from "../../models/static.model";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";

const HeaderMenuDropDown = (props: any) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const cancelAction = () => {
    return (
      <IconButton onClick={() => closeSnackbar()}>
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    authService
      .logout()
      .then(() => handleClose())
      .then(() => linkToLogin())
      .catch((err: any) =>
        enqueueSnackbar(err.toString(), {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          variant: "error",
          action: cancelAction,
        })
      );
  };

  const handleMain = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    myRole === Role.Admin ? linkToMain() : linkToRules();
    handleClose();
  };

  const handleLicenses = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    linkToLicenses();
    handleClose();
  };

  const handleInstruction = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    linkToInstructions();
    handleClose();
  };

  const handleUsers = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    linkToUsers();
    handleClose();
  };

  const handleRequests = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    linkToRequests();
    handleClose();
  };

  const handleUnauthorizedUsers = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    linkToUnauthorized();
    handleClose();
  };

  // const handleEntitys = () => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  //   linkToEntitys();
  //   handleClose();
  // };

  // const handleEnterprises = () => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  //   linkToEnterprises();
  //   handleClose();
  // };

  // const handleObjects = () => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  //   linkToObjects();
  //   handleClose();
  // };

  // const handleStamps = () => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  //   linkToStamps();
  //   handleClose();
  // };

  const handleSearch = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    linkToSearch();
    handleClose();
  };

  const handleSettings = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    linkToSettings();
    handleClose();
  };

  const myRole = useMemo(() => dataService.getRoleSync(), []);

  return (
    <>
      <IconButton
        aria-controls="avatar-menu"
        aria-haspopup="true"
        disableRipple
        disableFocusRipple
        color="primary"
        onClick={handleClick}
      >
        <ImageAvatars
          email={props.email}
          firstName={props.firstName}
          lastName={props.lastName}
          pictureUrl={props.pictureUrl}
        />
      </IconButton>

      <Menu
        // className={classes.menu}
        style={{ top: 40 }}
        disableAutoFocusItem
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        variant="menu"
      >
        <MenuItem onClick={handleMain}>
          <List style={{ margin: 0, padding: 0 }}>
            <ListItem>
              <ListItemIcon>
                <SubjectIcon />
              </ListItemIcon>
              {myRole === Role.Admin ? (
                <Typography>{t("dashboard.btnName")}</Typography>
              ) : (
                <Typography>{t("dashboard.btnNameConditions")}</Typography>
              )}
            </ListItem>
          </List>
        </MenuItem>
        <Divider />

        {myRole === Role.User && (
          <div>
            <MenuItem onClick={handleInstruction}>
              <List style={{ margin: 0, padding: 0 }}>
                <ListItem>
                  <ListItemIcon>
                    <LocalLibraryIcon />
                  </ListItemIcon>
                  <Typography>{t("btns.instruction")}</Typography>
                </ListItem>
              </List>
            </MenuItem>

            <Divider />
          </div>
        )}

        {myRole === Role.Admin && (
          <div>
            <MenuItem onClick={handleLicenses}>
              <List style={{ margin: 0, padding: 0 }}>
                <ListItem>
                  <ListItemIcon>
                    <ReceiptIcon />
                  </ListItemIcon>
                  <Typography>{t("licences.btnName")}</Typography>
                </ListItem>
              </List>
            </MenuItem>

            <Divider />
          </div>
        )}

        {myRole === Role.Admin && (
          <div>
            <MenuItem onClick={handleUsers}>
              <List style={{ margin: 0, padding: 0 }}>
                <ListItem>
                  <ListItemIcon>
                    <PeopleAltIcon />
                  </ListItemIcon>
                  <Typography>{t("users.btnName")}</Typography>
                </ListItem>
              </List>
            </MenuItem>

            <Divider />
          </div>
        )}

        {/* <MenuItem onClick={handleEntitys}>
          <List style={{ margin: 0, padding: 0 }}>
            <ListItem>
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <Typography>{t("le.btnName")}</Typography>
            </ListItem>
          </List>
        </MenuItem>

        <Divider />

        <MenuItem onClick={handleEnterprises}>
          <List style={{ margin: 0, padding: 0 }}>
            <ListItem>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <Typography>{t("pe.btnName")}</Typography>
            </ListItem>
          </List>
        </MenuItem>

        <Divider />

        <MenuItem onClick={handleObjects}>
          <List style={{ margin: 0, padding: 0 }}>
            <ListItem>
              <ListItemIcon>
                <HomeWorkIcon />
              </ListItemIcon>
              <Typography>{t("addresses.btnName")}</Typography>
            </ListItem>
          </List>
        </MenuItem>

        <Divider />

        <MenuItem onClick={handleStamps}>
          <List style={{ margin: 0, padding: 0 }}>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon />
              </ListItemIcon>
              <Typography>{t("stamps.btnName")}</Typography>
            </ListItem>
          </List>
        </MenuItem>

        <Divider /> */}

        <MenuItem onClick={handleSearch}>
          <List style={{ margin: 0, padding: 0 }}>
            <ListItem>
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <Typography>{t("btns.search")}</Typography>
            </ListItem>
          </List>
        </MenuItem>

        <Divider />

        {myRole === Role.Admin && (
          <div>
            <MenuItem onClick={handleRequests}>
              <List style={{ margin: 0, padding: 0 }}>
                <ListItem>
                  <ListItemIcon>
                    <PlaylistAddIcon />
                  </ListItemIcon>
                  <Typography>{t("requests.btnName")}</Typography>
                </ListItem>
              </List>
            </MenuItem>

            <Divider />
          </div>
        )}

        {myRole === Role.Admin && (
          <div>
            <MenuItem onClick={handleUnauthorizedUsers}>
              <List style={{ margin: 0, padding: 0 }}>
                <ListItem>
                  <ListItemIcon>
                    <LockIcon />
                  </ListItemIcon>
                  <Typography>{t("unauthorized.btnName")}</Typography>
                </ListItem>
              </List>
            </MenuItem>

            <Divider />
          </div>
        )}

        <MenuItem onClick={handleSettings}>
          <List style={{ margin: 0, padding: 0 }}>
            <ListItem>
              <ListItemIcon>
                <ContactMailIcon />
              </ListItemIcon>
              <Typography>{t("profile.btnName")}</Typography>
            </ListItem>
          </List>
        </MenuItem>
        <Divider />

        <MenuItem onClick={handleLogout}>
          <List style={{ margin: 0, padding: 0 }}>
            <ListItem>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <Typography> {t("profile.logout")}</Typography>
            </ListItem>
          </List>
        </MenuItem>
      </Menu>
    </>
  );
};

export default withSnackbar(HeaderMenuDropDown);
