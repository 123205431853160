import { localStorageAsync } from "./localStorageAsync";
import { config } from "../utils/config";
import { Hash, EncryptedString } from "../models/static.model";
import CryptoJS from "crypto-js";
import Logger from "./logger";

class CryptoStorage {
  private logger: Logger = new Logger(this.constructor.name);
  private createHash(value: string, secret: String): Hash {
    return CryptoJS.HmacSHA256(value, secret).toString(CryptoJS.enc.Base64);
  }

  private encrypt(value: string, secret: string): EncryptedString {
    return CryptoJS.AES.encrypt(value, secret).toString();
  }

  private decrypt(encryptedData: string, secret: string): string {
    this.logger.debug("decrypt encryptedData: ", encryptedData);
    return CryptoJS.AES.decrypt(encryptedData, secret).toString(
      CryptoJS.enc.Utf8
    );
  }

  setItem(key: string, value: string): Promise<string> {
    if (config.encryptLocalStorage) {
      const hashedKey: Hash = this.createHash(key, config.secret);
      const encryptedValue: EncryptedString = this.encrypt(value, hashedKey);
      this.logger.debug(`encrypted key: ${key} -> ${hashedKey}`);
      this.logger.debug(`encrypted value: ${value} -> ${encryptedValue}`);
      return localStorageAsync.setItem(hashedKey, encryptedValue);
    } else {
      return localStorageAsync.setItem(key, value);
    }
  }

  getItemSync(key: string): any {
    if (config.encryptLocalStorage) {
      const hashedKey: Hash = this.createHash(key, config.secret);
      const value = localStorageAsync.getItemSync(hashedKey);
      if (value === null) {
        return value;
      } else {
        this.logger.debug("(sync) getItem: hashedKey - ", hashedKey);
        const fetchedEncryptedValue: EncryptedString = value as string;
        this.logger.debug(
          "(sync) getItem: fetchedEncryptedValue - ",
          fetchedEncryptedValue
        );
        const decryptedValue = this.decrypt(fetchedEncryptedValue, hashedKey);
        this.logger.debug(`(sync) decrypted key: ${hashedKey} -> ${key}`);
        this.logger.debug(
          `(sync) decrypted value: ${fetchedEncryptedValue} -> ${decryptedValue}`
        );
        return decryptedValue;
      }
    } else {
      return localStorageAsync.getItemSync(key);
    }
  }

  getItem(key: string): Promise<string> {
    if (config.encryptLocalStorage) {
      const hashedKey: Hash = this.createHash(key, config.secret);
      return localStorageAsync.getItem(hashedKey).then((value: any) => {
        if (value === null) {
          return value;
        } else {
          this.logger.debug("getItem: hashedKey - ", hashedKey);
          const fetchedEncryptedValue: EncryptedString = value as string;
          this.logger.debug(
            "getItem: fetchedEncryptedValue - ",
            fetchedEncryptedValue
          );
          const decryptedValue = this.decrypt(fetchedEncryptedValue, hashedKey);
          this.logger.debug(`decrypted key: ${hashedKey} -> ${key}`);
          this.logger.debug(
            `decrypted value: ${fetchedEncryptedValue} -> ${decryptedValue}`
          );
          return decryptedValue;
        }
      });
    } else {
      return localStorageAsync.getItem(key);
    }
  }

  clear(): Promise<void> {
    return localStorageAsync.clear();
  }
}

export const cryptoStorage = new CryptoStorage();
